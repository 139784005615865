import React, { memo } from "react";
import userStyles from "../../styles/UI/HelpSelectionStyles";
import Button from "../Customized Libraries/CustomButtons/Button";
import Grid from "@material-ui/core/Grid";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { speakNoChunk } from "../../store/actions/chatActions";

const HelpSelection = ({ options, title, info, itemStyle, ...other }) => {
    const classes = userStyles();
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.currentLanguage);
    const isGapiReady = useSelector((state) => state.chat.isGapiReady);
    const propIsValid = (prop) => (typeof prop != "undefined" ? prop : false);

    const handleClick = (option) => {
        option.onClick();
        if (option.speakOnClick) {
            dispatch(speakNoChunk(option.speakOnClick, language, isGapiReady));
        }
    };

    return (
        <div className={classes.root} {...other}>
            <div className={classes.title}>{propIsValid(title)}</div>
            <div className={classes.subRoot}>
                <Grid
                    container
                    alignItems={"center"}
                    className={classes.grid}
                    id={"chatContent"}
                    spacing={2}
                >
                    {propIsValid(options) &&
                        options.map((option, index) => (
                            <Grid
                                item
                                sm={6}
                                md={6}
                                lg={4}
                                className={classnames(
                                    classes.gridItem,
                                    itemStyle
                                )}
                                key={index}
                            >
                                {propIsValid(option.children) ? (
                                    option.children
                                ) : (
                                    <Button
                                        color={"secondaryButtonTheme"}
                                        size={"lg"}
                                        className={classes.help}
                                        onClick={() => handleClick(option)}
                                    >
                                        {propIsValid(option.img) ? (
                                            <div
                                                className={
                                                    classes.buttonContainer
                                                }
                                            >
                                                <span>{option.text}</span>
                                                <img
                                                    src={option.img}
                                                    alt={`${option.text} icon`}
                                                    className={
                                                        classes.optionsIcon
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            option.text
                                        )}
                                    </Button>
                                )}
                            </Grid>
                        ))}
                </Grid>
            </div>
            <div className={classes.info}>{propIsValid(info)}</div>
        </div>
    );
};

HelpSelection.propTypes = {
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    title: PropTypes.string,
    info: PropTypes.string,
    itemStyle: PropTypes.object,
};

export default memo(HelpSelection);
