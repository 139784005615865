import React, {
    lazy,
    memo,
    Suspense,
    useCallback,
    useEffect,
    useState,
} from "react";
import userStyles from "../../../styles/UI/settings/AppearanceSettingsStyles";

import Divider from "@material-ui/core/Divider";
import StylePopperSettings from "./StylePopperSettings";
import Switch from "@material-ui/core/Switch";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { translate } from "../../../utils/i18n";
import TextField from "@material-ui/core/TextField";
import uuid from "react-uuid";
import Moment from "react-moment";
import LazyLoad from "react-lazy-load";
import { useDispatch, useSelector } from "react-redux";
import "moment/locale/vi";
import "moment/locale/ja";
import { SuspenseImg } from "../SuspenseImg";

import { Spin } from "antd";
import PropTypes from "prop-types";
import ConfirmationModal from "../modals/ConfirmationModal";
import {
    deleteAppearanceSettings,
    updateSettingsFromDB,
} from "../../../store/actions/settingsActions";
import { MapSettings } from "./MapSettings";

const ThemeSettings = lazy(() => import("./ThemeSettings"));
const ImageUploader = lazy(() =>
    import("../../Customized Libraries/ReactImageUpload/ReactImageUpload")
);

const SuspenseImgClone = (props) => {
    return (
        <LazyLoad>
            <Suspense fallback={<Spin />}>
                <SuspenseImg {...props} />
            </Suspense>
        </LazyLoad>
    );
};

export const AppearanceSettingsTabSkeleton = (props) => {
    return (
        <div
            id={"chatContent"}
            style={{
                height: "45em",
                overflowY: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Spin />
        </div>
    );
};

const uploadURL = "/api/v1/uploads/";

const AppearanceSettingsTab = (props) => {
    const classes = userStyles();
    const {
        logoSettings,
        backgroundSettings,
        avatarSettings,
        titleSettings,
        companySettings,
        specialLabelSettings,
        themeSettings,
        mapSettings,
        appearanceDeletionDialogOpen,
        setAppearanceDeletionDialogOpen,
        lastUpdated,
    } = props;

    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.currentLanguage);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [isLoading, setIsLoading] = useState(false);

    const onDropAvatar = (pictures_, imgUrls) =>
        avatarSettings.onDrop(pictures_, imgUrls, forceUpdate);

    const handleAppearanceSettingsDeletion = async () => {
        setIsLoading(true);
        await dispatch(deleteAppearanceSettings());
        await dispatch(updateSettingsFromDB());
        setIsLoading(false);
        setAppearanceDeletionDialogOpen(false);
    };

    const onKeyPressCreateAnimation = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            avatarSettings.handleCreateAnimation();
        }
    };

    return (
        <div id={"chatContent"} className={classes.tabPanel}>
            <div className={classes.lastUpdatedContainer}>
                {translate("Last Updated")}{" "}
                <span className={classes.lastUpdated}>
                    <Moment
                        fromNow
                        locale={language.split("-").shift()}
                        style={{ textTransform: "capitalize" }}
                    >
                        {lastUpdated}
                    </Moment>
                </span>
            </div>

            <div className={classes.container}>
                <div className={classes.title}>{translate("Title")}</div>
                <StylePopperSettings settings={titleSettings} />
            </div>
            <Divider variant="middle" className={classes.divider} />
            <div className={classes.container}>
                <div className={classes.title}>
                    {translate("Company Name")}{" "}
                </div>
                <StylePopperSettings settings={companySettings} />
            </div>
            <Divider variant="middle" className={classes.divider} />
            <div className={classes.container}>
                <div className={classes.title}>
                    {translate("Special Label")}
                </div>
                <StylePopperSettings settings={specialLabelSettings} />
            </div>
            <Divider variant="middle" className={classes.divider} />
            <ThemeSettings themeSettings={themeSettings} />

            <Divider variant="middle" className={classes.divider} />
            <div className={classes.container}>
                <div className={classes.title}>{translate("Logo")}</div>
                <div ref={logoSettings.photoRef} style={{ width: "75%" }}>
                    <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        buttonText={translate("Replace Logo")}
                        label={translate(
                            "Max file size: 50mb. Accepted files: jpg, jpeg, png"
                        )}
                        fileSizeError={translate(
                            "<filename> file size is too big"
                        )}
                        fileTypeError={translate(
                            "<filename> is not a supported file extension"
                        )}
                        fileMaxError={translate(
                            "<filename> cannot be added, max files supported 1"
                        )}
                        onChange={logoSettings.onDrop}
                        defaultImages={logoSettings.defaultImages}
                        imgExtension={[".jpg", ".png", ".jpeg"]}
                        maxFileSize={52428800}
                        removeDeletion={1}
                        singleImage
                    />
                </div>
            </div>
            <Divider variant="middle" className={classes.divider} />
            <div className={classes.container}>
                <div className={classes.title}>{translate("Avatar")} </div>
                <div className={classes.avatarFlex}>
                    <div className={classes.avatarSubContainer}>
                        <div className={classes.subTitle}>
                            {translate("Show Avatar")}:
                        </div>

                        <Switch
                            checked={avatarSettings.isAvatarOn}
                            onChange={(e) =>
                                avatarSettings.setIsAvatarOn(e.target.checked)
                            }
                            color="primary"
                        />
                        <div
                            className={classes.subTitle}
                            style={{ marginLeft: "2em" }}
                        >
                            {translate("Animation")}:
                        </div>
                        <Switch
                            checked={avatarSettings.isAvatarAnimationOn}
                            onChange={(e) =>
                                avatarSettings.setIsAvatarAnimationOn(
                                    e.target.checked
                                )
                            }
                            color="primary"
                        />
                    </div>
                    <div className={classes.buttonContainer}>
                        <Button
                            onClick={() => avatarSettings.setDialogOpen(true)}
                            color="primary"
                            variant={"contained"}
                        >
                            {translate("Add Animation")}
                        </Button>
                        {avatarSettings.receptionistSelectedAnimationsObj !==
                            null && (
                            <Button
                                className={classes.deleteAnimation}
                                onClick={
                                    avatarSettings.handleDeleteCurrentAnimation
                                }
                                disabled={
                                    avatarSettings
                                        .receptionistSelectedAnimationsObj
                                        .name === "defaultAnimation"
                                }
                                color="secondary"
                                variant={"contained"}
                            >
                                {translate("Delete Selected Animation")}
                            </Button>
                        )}
                    </div>
                    <div className={classes.avatarSubContainer}>
                        <InputLabel className={classes.subTitle}>
                            {translate("Quality")}:
                        </InputLabel>
                        <Select
                            className={classes.qualitySelect}
                            id="staff"
                            onChange={(e) =>
                                avatarSettings.setAvatarQuality(e.target.value)
                            }
                            value={avatarSettings.avatarQuality || ""}
                        >
                            <MenuItem value={"high"}>
                                {" "}
                                {translate("High")}
                            </MenuItem>
                            <MenuItem value={"medium"}>
                                {translate("Medium")}{" "}
                            </MenuItem>
                            <MenuItem value={"low"}>
                                {translate("Low")}
                            </MenuItem>
                        </Select>
                        <InputLabel className={classes.subTitle2}>
                            {translate("Selected Animation")}:{" "}
                        </InputLabel>
                        <Select
                            className={classes.qualitySelect}
                            onChange={(e) =>
                                avatarSettings.handleChangeAnimation(
                                    e.target.value
                                )
                            }
                            value={avatarSettings.selectedAnimation || ""}
                        >
                            {avatarSettings.receptionistAnimations.map(
                                (animation) => (
                                    <MenuItem
                                        value={animation.name}
                                        key={uuid()}
                                    >
                                        {animation.name}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </div>

                    {avatarSettings.receptionistSelectedAnimationsObj !==
                        null && (
                        <div
                            className={classes.animationSelectionTypeContainer}
                        >
                            <div
                                className={classes.animationSelectionContainer}
                            >
                                <InputLabel className={classes.subTitle}>
                                    {translate("Selected Bow Animation")}:
                                </InputLabel>
                                <Select
                                    className={classes.qualitySelect2}
                                    onChange={(e) =>
                                        avatarSettings.changeSelectedBowAnimation(
                                            e.target.value,
                                            forceUpdate
                                        )
                                    }
                                    value={
                                        avatarSettings
                                            .receptionistSelectedAnimationsObj
                                            .selectedBowAnimation || ""
                                    }
                                >
                                    {avatarSettings.receptionistSelectedAnimationsObj.animation.map(
                                        (animation) => (
                                            <MenuItem
                                                value={animation.fileName}
                                                key={uuid()}
                                            >
                                                <div
                                                    className={
                                                        classes.backgroundMenuItem
                                                    }
                                                >
                                                    <span>
                                                        {" "}
                                                        {animation.fileName}
                                                    </span>
                                                    <SuspenseImgClone
                                                        src={
                                                            animation.fileId
                                                                ? `${uploadURL}${animation.fileId}`
                                                                : animation.file
                                                        }
                                                        alt={animation.fileName}
                                                        className={
                                                            classes.menuItemImg
                                                        }
                                                    />
                                                </div>
                                            </MenuItem>
                                        )
                                    )}
                                    {/*<img src={animation.animations.find(animation => animation.name === "bow")} alt={photo.name} className={classes.menuItemImg}/>*/}
                                </Select>
                                <StylePopperSettings
                                    settings={avatarSettings.bowAnimationStyles}
                                    header={translate(
                                        "Change Bow Animation Styles"
                                    )}
                                    buttonStyle={classes.stylePopperButton}
                                />
                            </div>

                            <div
                                className={classes.animationSelectionContainer}
                            >
                                <InputLabel className={classes.subTitle}>
                                    {translate("Selected Idle Animation")}:
                                </InputLabel>
                                <Select
                                    className={classes.qualitySelect2}
                                    onChange={(e) =>
                                        avatarSettings.changeSelectedIdleAnimation(
                                            e.target.value,
                                            forceUpdate
                                        )
                                    }
                                    value={
                                        avatarSettings
                                            .receptionistSelectedAnimationsObj
                                            .selectedIdleAnimation || ""
                                    }
                                >
                                    {avatarSettings.receptionistSelectedAnimationsObj.animation.map(
                                        (animation) => (
                                            <MenuItem
                                                value={animation.fileName}
                                                key={uuid()}
                                            >
                                                <div
                                                    className={
                                                        classes.backgroundMenuItem
                                                    }
                                                >
                                                    <span>
                                                        {" "}
                                                        {animation.fileName}
                                                    </span>
                                                    <SuspenseImgClone
                                                        src={
                                                            animation.fileId
                                                                ? `${uploadURL}${animation.fileId}`
                                                                : animation.file
                                                        }
                                                        alt={animation.fileName}
                                                        className={
                                                            classes.menuItemImg
                                                        }
                                                    />
                                                </div>
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                                <StylePopperSettings
                                    settings={
                                        avatarSettings.idleAnimationStyles
                                    }
                                    header={translate(
                                        "Change Idle Animation Styles"
                                    )}
                                    buttonStyle={classes.stylePopperButton}
                                />
                            </div>

                            <div
                                className={classes.animationSelectionContainer}
                            >
                                <InputLabel className={classes.subTitle}>
                                    {translate("Selected Talk Animation")}:
                                </InputLabel>
                                <Select
                                    className={classes.qualitySelect2}
                                    onChange={(e) =>
                                        avatarSettings.changeSelectedTalkAnimation(
                                            e.target.value,
                                            forceUpdate
                                        )
                                    }
                                    value={
                                        avatarSettings
                                            .receptionistSelectedAnimationsObj
                                            .selectedTalkAnimation || ""
                                    }
                                >
                                    {avatarSettings.receptionistSelectedAnimationsObj.animation.map(
                                        (animation) => (
                                            <MenuItem
                                                value={animation.fileName}
                                                key={uuid()}
                                            >
                                                <div
                                                    className={
                                                        classes.backgroundMenuItem
                                                    }
                                                >
                                                    <span>
                                                        {" "}
                                                        {animation.fileName}
                                                    </span>
                                                    <SuspenseImgClone
                                                        src={
                                                            animation.fileId
                                                                ? `${uploadURL}${animation.fileId}`
                                                                : animation.file
                                                        }
                                                        alt={animation.fileName}
                                                        className={
                                                            classes.menuItemImg
                                                        }
                                                    />
                                                </div>
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                                <StylePopperSettings
                                    settings={
                                        avatarSettings.talkAnimationStyles
                                    }
                                    header={translate(
                                        "Change Talk Animation Styles"
                                    )}
                                    buttonStyle={classes.stylePopperButton}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div>
                <ImageUploader
                    withIcon={true}
                    withPreview={true}
                    buttonText={translate("Add Animation")}
                    label={translate(
                        "Max file size: 100mb. Max files: 5. Accepted files: gif"
                    )}
                    onChange={onDropAvatar}
                    defaultImages={
                        avatarSettings.receptionistSelectedAnimationsImg
                    }
                    imgExtension={[".gif"]}
                    fileSizeError={translate("<filename> file size is too big")}
                    fileTypeError={translate(
                        "<filename> is not a supported file extension"
                    )}
                    fileMaxError={translate(
                        "<filename> cannot be added, max files supported 5"
                    )}
                    maxFiles={5}
                    removeDeletion={avatarSettings.removeDeletion}
                    maxFileSize={104857600}
                />
            </div>

            <Divider variant="middle" className={classes.divider} />

            <div className={classes.container}>
                <div className={classes.title}>Background</div>
                <div className={classes.backgroundSelectorContainer}>
                    <InputLabel className={classes.subTitle}>
                        {translate("Selected Background")}:
                    </InputLabel>
                    <Select
                        className={classes.backgroundSelect}
                        id="staff"
                        onChange={(e) =>
                            backgroundSettings.setSelectedBackground(
                                e.target.value
                            )
                        }
                        value={backgroundSettings.selectedBackground || ""}
                    >
                        {backgroundSettings.pictures.map((photo, index) => (
                            <MenuItem value={photo.fileName} key={index}>
                                <div className={classes.backgroundMenuItem}>
                                    <span> {photo.fileName}</span>
                                    <SuspenseImgClone
                                        src={
                                            photo.fileId
                                                ? `${uploadURL}${photo.fileId}`
                                                : photo.file
                                        }
                                        alt={photo.fileName}
                                        className={classes.menuItemImg}
                                    />
                                </div>
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </div>
            <div ref={backgroundSettings.photoRef}>
                <ImageUploader
                    withIcon={true}
                    withPreview={true}
                    buttonText={translate("Add Backgrounds")}
                    label={translate(
                        "Max file size: 50mb. Max files: 5. Accepted files: jpg, jpeg, png"
                    )}
                    fileSizeError={translate("<filename> file size is too big")}
                    fileTypeError={translate(
                        "<filename> is not a supported file extension"
                    )}
                    fileMaxError={translate(
                        "<filename> cannot be added, max files supported 5"
                    )}
                    onChange={backgroundSettings.onDrop}
                    defaultImages={backgroundSettings.defaultImages}
                    imgExtension={[".jpg", ".png", ".jpeg"]}
                    maxFiles={5}
                    maxFileSize={52428800}
                    removeDeletion={1}
                />
            </div>

            <Divider variant="middle" className={classes.divider} />

            <MapSettings mapSettings={mapSettings} classes={classes} />

            <div>
                <Button
                    color="primary"
                    onClick={() => setAppearanceDeletionDialogOpen(true)}
                >
                    {translate("Restore Default Appearance Settings")}
                </Button>
            </div>

            {/*create animation dialog*/}
            <ConfirmationModal
                open={avatarSettings.dialogOpen}
                handleClose={() => avatarSettings.setDialogOpen(false)}
                handleSuccess={avatarSettings.handleCreateAnimation}
                title={translate("Enter name for new animation")}
                abortText={translate("Abort")}
                successText={translate("Create")}
                useComponent
                Component={
                    <TextField
                        label={translate("Animation name")}
                        placeholder={translate("Enter name for animation")}
                        required
                        fullWidth
                        value={avatarSettings.animationName}
                        onChange={(e) =>
                            avatarSettings.setAnimationName(e.target.value)
                        }
                        onKeyPress={onKeyPressCreateAnimation}
                    />
                }
            />

            {/*Delete appearance settings dialog*/}
            <ConfirmationModal
                open={appearanceDeletionDialogOpen}
                handleClose={() => setAppearanceDeletionDialogOpen(false)}
                handleSuccess={handleAppearanceSettingsDeletion}
                title={translate("Restore to default")}
                message={translate(
                    "Are you sure you want delete the current appearance settings"
                )}
                abortText={translate("No")}
                successText={translate("Yes")}
                isLoading={isLoading}
            />
        </div>
    );
};

AppearanceSettingsTab.propTypes = {
    logoSettings: PropTypes.object,
    backgroundSettings: PropTypes.object,
    avatarSettings: PropTypes.object,
    titleSettings: PropTypes.object,
    companySettings: PropTypes.object,
    specialLabelSettings: PropTypes.object,
    themeSettings: PropTypes.object,
    mapSettings: PropTypes.object,
    appearanceDeletionDialogOpen: PropTypes.bool,
    setAppearanceDeletionDialogOpen: PropTypes.func,
    lastUpdated: PropTypes.string,
};

export default memo(AppearanceSettingsTab);
