import { SET_RECEPTIONIST } from "./types";

// set receptionist state
export const setReceptionist = (state) => (dispatch) => {
    dispatch({
        type: SET_RECEPTIONIST,
        receptionist_state: state,
    });
};

const createElement = (type, callback) => {
    let element = document.createElement(type);

    callback(element);

    return element;
};

export const freezeGif = (img) => {
    let width = img.width,
        height = img.height,
        canvas = createElement("canvas", function (clone) {
            clone.width = width;
            clone.height = height;
        }),
        attr,
        i = 0;

    const freeze = function () {
        canvas.getContext("2d").drawImage(img, 0, 0, width, height);

        for (i = 0; i < img.attributes.length; i++) {
            attr = img.attributes[i];

            if (attr.name !== '"') {
                // test for invalid attributes
                canvas.setAttribute(attr.name, attr.value);
            }
        }

        canvas.style.position = "absolute";

        img.parentNode.insertBefore(canvas, img);
        img.style.opacity = 0;
        img.removeEventListener("load", freeze);
    };

    if (img.complete) {
        freeze();
    } else {
        img.addEventListener("load", freeze, true);
    }
};
