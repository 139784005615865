import { makeStyles } from "@material-ui/core/styles";
import background from "../../images/background.jpg";

const useStyles = makeStyles((theme) => ({
    root: {
        // border: '2px solid black',
        backgroundImage: `url(${background})`,
        width: "100%",
        height: "100%",
    },
    subContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
    },
    subContainer2: {
        display: "flex",
        height: "60%",
    },
    helpSelection: {
        width: "70%",
        marginTop: "2em",
    },
    itemStyle: {
        height: "7em",
    },
    "@global html body": {
        overflowY: "hidden !important",
    },
}));

export default useStyles;
