import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import { unFreezeObjOrArray } from "./useAvatarSettings";
import { areObjectsSame } from "../../../store/actions/chatActions";
import { addToNotifications } from "../../../store/actions/notificationActions";
import { translate } from "../../../utils/i18n";
import { useApplicationSettings } from "../useApplicationSettings";
import {
    defaultOnFindMapFailResponse,
    languageCodes,
    languageCodes_,
} from "../../../styles/constants";

export const updateObject = (object, newValue, path) => {
    let stack = path.split(".");
    while (stack.length > 1) {
        object = object[stack.shift()];
    }
    object[stack.shift()] = newValue;
    return object;
};

export const useMapSettings = (
    dbField,
    tabIndex,
    settingsRef,
    tabRef,
    isModalOpen
) => {
    const dispatch = useDispatch();
    const [findMapFail, _setFindMapFail] = useState(
        defaultOnFindMapFailResponse
    );
    const { supportedLanguages } = useApplicationSettings([
        "supportedLanguages",
    ]);
    const [firstLoad, setFirstLoad] = useState(true);
    const photoRef = useRef(null);
    const [currentMap, _setCurrentMap] = useState({
        buttonName: "",
        buttonLabel: {},
        map: { fileId: null, file: null },
        voiceResponse: {},
    });
    const [maps, setMaps] = useState([]);
    const [selectedMapIndex, _setSelectedMapIndex] = useState(0);
    const [selectedLanguage, setSelectedLanguage] = useState(
        languageCodes.english
    );
    const updateSettings = useSelector(
        (state) => state.settings.updateSettings
    );

    const onLoad = () => {
        let timer = null;
        if (settingsRef.current !== null) {
            const mapField = settingsRef.current[dbField];
            // console.log(mapField);
            if (
                mapField.maps?.length > 0 &&
                mapField.onFindMapFail !== undefined
            ) {
                const hasValidSchema = mapField.maps.find(
                    (map) => !!map.voiceResponse.known && map?.maps?.length > 0
                );
                if (!!hasValidSchema) {
                    _setCurrentMap(mapField.maps[0]);
                    setMaps(mapField.maps);
                    _setFindMapFail(mapField.onFindMapFail);
                    setSelectedLanguage(languageCodes.english);
                }
            } else {
                setMaps([]);
                _setSelectedMapIndex(0);
                setSelectedLanguage(languageCodes.english);
                _setCurrentMap({
                    buttonName: "",
                    buttonLabel: {},
                    map: { fileId: null, file: null },
                    voiceResponse: {},
                });
                _setFindMapFail(defaultOnFindMapFailResponse);
            }
        } else {
            timer = setTimeout(onLoad, 1000);
        }
        return timer;
    };

    const setFindMapFail = (path, value) => {
        path = path.join(".");
        const _findMapFail = unFreezeObjOrArray(findMapFail);
        updateObject(_findMapFail, value, path);
        _setFindMapFail(_findMapFail);
    };

    const setCurrentMap = (path, value, map = null) => {
        path = path.join(".");
        const _currentMap =
            unFreezeObjOrArray(map) || unFreezeObjOrArray(currentMap);
        updateObject(_currentMap, value, path);
        _setCurrentMap(_currentMap);
        const _maps = unFreezeObjOrArray(maps);
        _maps[selectedMapIndex] = _currentMap;
        setMaps(_maps);
    };

    const setSelectedMapIndex = (index) => {
        if (validate()) {
            _setCurrentMap(maps[index]);
            _setSelectedMapIndex(index);
        }
    };
    const onDrop = (pictures_, imgUrls) => {
        let cMap = null;
        let mapIndex = currentMap.maps.findIndex(
            (map) => map.language === selectedLanguage
        );
        if (mapIndex === -1) {
            cMap = {
                ...currentMap,
                maps: [...currentMap.maps, { language: selectedLanguage }],
            };
            mapIndex = cMap.maps.findIndex(
                (map) => map.language === selectedLanguage
            );
        }
        if (imgUrls.length === 0)
            return setCurrentMap(["maps", mapIndex], {}, cMap);
        const file = imgUrls[imgUrls.length - 1];
        if (imgUrls[imgUrls.length - 1] !== undefined) {
            const newMapPicture = {
                file,
                fileName:
                    new RegExp("(?<=name=)(.*)(?=;)", "g").exec(file)?.[0] ||
                    uuid(),
                language: selectedLanguage,
            };
            setCurrentMap(["maps", mapIndex], newMapPicture, cMap);
        } else setCurrentMap(["maps", mapIndex], {}, cMap);
    };
    const addNewMap = () => {
        const newMap = {
            buttonName: "",
            buttonLabel: {},
            voiceResponse: {
                known: {},
                unknown: {},
            },
            maps: supportedLanguages.map((langCode) => ({
                language: languageCodes[langCode],
            })),
        };
        supportedLanguages?.length > 0 &&
            supportedLanguages.forEach((langCode) => {
                newMap.buttonLabel[langCode] = "Office Map 1";
                newMap.voiceResponse.known[languageCodes[langCode]] =
                    "Here is our office map";
                newMap.voiceResponse.unknown[languageCodes[langCode]] =
                    "Here is our office map";
            });
        _setCurrentMap(newMap);
        _setSelectedMapIndex(maps.length);
        setMaps([...maps, newMap]);
    };

    const deleteCurrentMap = () => {
        setSelectedMapIndex(0);
        let _maps = unFreezeObjOrArray(maps);
        _maps.splice(selectedMapIndex, 1);
        setMaps(_maps);
    };

    const hasMapSettingsChanged = () => {
        return !areObjectsSame(settingsRef.current[dbField], maps);
    };

    const getRefinedMaps = () => {
        return maps.map((map) => {
            const _maps = map.maps.map((map) => {
                if (map.file) {
                    return {
                        ...map,
                        file: map.file
                            .replace(/(?<=name=)(.*)(?=;)/g, "")
                            .replace("name=;", ""),
                    };
                } else return map;
            });
            return {
                ...map,
                maps: _maps.filter((map) => Object.keys(map).length > 1),
            };
        });
    };
    const validate = () => {
        let isValid = true;
        if (maps.length > 0) {
            const isButtonLabelValid =
                !!currentMap.voiceResponse &&
                !!currentMap.buttonLabel &&
                typeof currentMap.buttonLabel === "object" &&
                Object.values(languageCodes_)
                    .map((val) => !!currentMap.buttonLabel[val])
                    .every(Boolean) &&
                Object.values(languageCodes)
                    .map((val) => !!currentMap.voiceResponse.known[val])
                    .every(Boolean) &&
                Object.values(languageCodes)
                    .map((val) => !!currentMap.voiceResponse.unknown[val])
                    .every(Boolean);
            isValid =
                !!currentMap.buttonName &&
                isButtonLabelValid &&
                !!currentMap.maps;
        }

        if (!isValid) {
            dispatch(
                addToNotifications({
                    message: translate("Map is missing label or name or image"),
                    type: "ERROR",
                    size: "md",
                    duration: 1,
                })
            );
        }
        return isValid;
    };
    useEffect(() => {
        if (firstLoad && isModalOpen) {
            const timer = onLoad();
            setFirstLoad(false);
            return () => clearTimeout(timer);
        }
        if (tabRef.current === tabIndex && isModalOpen) {
            const timer = onLoad();
            return () => clearTimeout(timer);
        }
    }, [tabRef.current, isModalOpen]);

    useEffect(() => {
        if (updateSettings) {
            const timer = onLoad();
            return () => clearTimeout(timer);
        }
    }, [updateSettings]);
    return {
        currentMap,
        setCurrentMap,
        onDrop,
        setSelectedMapIndex,
        selectedMapIndex,
        maps,
        photoRef,
        supportedLanguages,
        addNewMap,
        deleteCurrentMap,
        hasMapSettingsChanged,
        getRefinedMaps,
        validate,
        setFindMapFail,
        findMapFail,
        selectedLanguage,
        setSelectedLanguage,
    };
};
