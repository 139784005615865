import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToNotifications } from "../../../store/actions/notificationActions";
import { translate } from "../../../utils/i18n";
import uuid from "react-uuid";

const uploadURL = "/api/v1/uploads/";

export const unFreezeObjOrArray = (obj) => JSON.parse(JSON.stringify(obj));

export const base64MimeType = (encoded) => {
    let result = null;
    if (typeof encoded !== "string") {
        return result;
    }

    const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (mime && mime.length) {
        result = mime[1];
    }

    return result;
};

export const useAvatarSettings = (
    default_isAvatarOn,
    default_isAvatarAnimationOn_,
    default_avatarQuality,
    dbField,
    tabIndex,
    settingsRef,
    tabRef,
    isModalOpen
) => {
    const dispatch = useDispatch();
    const [isAvatarOn, setIsAvatarOn] = useState(default_isAvatarOn);
    const [isAvatarAnimationOn, setIsAvatarAnimationOn] = useState(
        default_isAvatarAnimationOn_
    );
    const [avatarQuality, setAvatarQuality] = useState(default_avatarQuality);

    const [receptionistAnimations, setReceptionistAnimations] = useState([]);

    const [
        receptionistSelectedAnimationsImg,
        setReceptionistSelectedAnimationsImg,
    ] = useState([]);

    const [
        receptionistSelectedAnimationsObj,
        setReceptionistSelectedAnimationsObj,
    ] = useState(null);

    const [selectedAnimation, setSelectedAnimation] = useState(null);
    const selectedAnimationRef = useRef(selectedAnimation);
    selectedAnimationRef.current = selectedAnimation;

    const [animationName, setAnimationName] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isAnimationUpdated, setIsAnimationUpdated] = useState(false);

    const idleAvatarStyles = useAvatarStyleSettings();
    const talkAvatarStyles = useAvatarStyleSettings();
    const bowAvatarStyles = useAvatarStyleSettings();

    const [firstLoad, setFirstLoad] = useState(true);

    const updateSettings = useSelector(
        (state) => state.settings.updateSettings
    );

    const [removeDeletion, setRemoveDeletion] = useState(-1);

    const onLoad = () => {
        let timer = null;
        // update field from db
        if (settingsRef.current !== null) {
            const current = settingsRef.current;
            current[dbField].status !== undefined &&
                setIsAvatarOn(current[dbField].status);
            current[dbField].quality !== undefined &&
                setAvatarQuality(current[dbField].quality);
            current[dbField].animation !== undefined &&
                setIsAvatarAnimationOn(current[dbField].animation);
            current[dbField].selectedAnimation !== undefined &&
                setSelectedAnimation(current[dbField].selectedAnimation);

            if (
                current[dbField].receptionistAnimations !== undefined &&
                current[dbField].receptionistAnimations.length > 0
            ) {
                setReceptionistAnimations(
                    current[dbField].receptionistAnimations
                );
                // console.log(current[dbField].receptionistAnimations);
                const selectedAnimation_ = current[
                    dbField
                ].receptionistAnimations.find(
                    (animation) =>
                        animation.name === current[dbField].selectedAnimation
                );
                setReceptionistSelectedAnimationsObj(selectedAnimation_);
                idleAvatarStyles.setDefault(
                    selectedAnimation_.selectedIdleAnimationStyles
                );
                talkAvatarStyles.setDefault(
                    selectedAnimation_.selectedTalkAnimationStyles
                );
                bowAvatarStyles.setDefault(
                    selectedAnimation_.selectedBowAnimationStyles
                );
                setReceptionistSelectedAnimationsImg(
                    selectedAnimation_.animation.map((picture) =>
                        picture.fileId
                            ? `${uploadURL}${picture.fileId}`
                            : picture.file
                    )
                );
                setIsAnimationUpdated(false);
            }
        } else {
            timer = setTimeout(onLoad, 1000);
        }
        return timer;
    };

    const disableDeletionOnDefaultImages = () => {
        if (selectedAnimationRef.current !== null) {
            if (selectedAnimationRef.current === "defaultAnimation") {
                setRemoveDeletion(3);
            } else {
                setRemoveDeletion(-1);
            }
        } else setTimeout(disableDeletionOnDefaultImages, 1000);
    };
    useEffect(() => {
        if (firstLoad && isModalOpen) {
            const timer = onLoad();
            setFirstLoad(false);
            return () => clearTimeout(timer);
        }

        if (tabRef.current === tabIndex && isModalOpen) {
            const timer = onLoad();
            disableDeletionOnDefaultImages();
            return () => clearTimeout(timer);
        }
    }, [tabRef.current, isModalOpen]);
    useEffect(() => {
        if (tabRef.current === tabIndex && isModalOpen)
            disableDeletionOnDefaultImages();
    }, [selectedAnimation]);

    useEffect(() => {
        if (updateSettings) {
            const timer = onLoad();
            return () => clearTimeout(timer);
        }
    }, [updateSettings]);

    const getPhotoName = useCallback(
        (photo, newAnimations) => {
            let name = new RegExp("(?<=name=)(.*)(?=;)", "g").exec(photo);

            // name already exists
            if (name !== null)
                if (
                    newAnimations.find(
                        (animation) => animation.fileName === name[0]
                    ) !== undefined
                )
                    name = `${uuid().slice(0, 5)}_${name[0]}`;

            if (name === null) {
                const photo_ = photo.split("/").slice(-1)[0];
                const img = receptionistSelectedAnimationsObj.animation.find(
                    (animation) => animation.fileId === photo_
                );
                if (img !== undefined) name = img.fileName;
            }
            return Array.isArray(name) ? name[0] : name;
        },
        [receptionistSelectedAnimationsObj]
    );

    const getPhotoId = useCallback(
        (photo) => {
            const photo_ =
                photo.split("/").length > 0
                    ? photo.split("/").slice(-1)[0]
                    : "";
            let id = null;
            const img = receptionistSelectedAnimationsObj.animation.find(
                (animation) => animation.fileId === photo_
            );
            if (img !== undefined) id = img.fileId;
            return id;
        },
        [receptionistSelectedAnimationsObj]
    );

    const getMimeType = useCallback(
        (photo) => {
            const photo_ =
                photo.split("/").length > 0
                    ? photo.split("/").slice(-1)[0]
                    : "";
            let mimeType = null;
            const mimeType_ = receptionistSelectedAnimationsObj.animation.find(
                (animation) => animation.fileId === photo_
            );
            if (mimeType_ !== undefined) mimeType = mimeType_.mimeType;
            else mimeType = base64MimeType(photo);
            return mimeType;
        },
        [receptionistSelectedAnimationsObj]
    );

    const onDrop = useCallback(
        (pictures_, imgUrls, forceUpdate) => {
            const receptionistSelectedAnimationsObj_ = unFreezeObjOrArray(
                receptionistSelectedAnimationsObj
            );
            const receptionistAnimations_ = unFreezeObjOrArray(
                receptionistAnimations
            );

            let newAnimations = [];
            for (let i = 0; i < imgUrls.length; i++) {
                const name = getPhotoName(imgUrls[i], newAnimations);
                const id = getPhotoId(imgUrls[i]);
                const newFile = {
                    file: imgUrls[i],
                    mimeType: getMimeType(imgUrls[i]),
                };
                if (name !== null) newFile.fileName = name;
                if (id !== null) {
                    newFile.fileId = id;
                    newFile.save = false;
                } else newFile.save = true;

                newAnimations.push(newFile);
            }
            receptionistSelectedAnimationsObj_.animation = newAnimations;

            const receptionistAnimationIndex_ =
                receptionistAnimations_.findIndex(
                    (animation) =>
                        animation.name ===
                        receptionistSelectedAnimationsObj_.name
                );
            const formattedNewAnimations = newAnimations.map((obj) =>
                formatAnimationObj(obj)
            );

            if (receptionistAnimationIndex_ !== -1) {
                // if index is found
                receptionistAnimations_[receptionistAnimationIndex_].animation =
                    formattedNewAnimations;
            }

            if (
                newAnimations.find(
                    (animation) =>
                        animation.fileName ===
                        receptionistSelectedAnimationsObj_.selectedBowAnimation
                ) === undefined
            )
                if (
                    newAnimations.length > 0 &&
                    receptionistAnimationIndex_ !== -1
                ) {
                    // selected background is not in newPicture
                    receptionistSelectedAnimationsObj_.selectedBowAnimation =
                        newAnimations[0].fileName;
                    receptionistAnimations_[
                        receptionistAnimationIndex_
                    ].selectedBowAnimation = newAnimations[0].fileName;
                }
            if (
                newAnimations.find(
                    (animation) =>
                        animation.fileName ===
                        receptionistSelectedAnimationsObj_.selectedIdleAnimation
                ) === undefined
            )
                if (
                    newAnimations.length > 0 &&
                    receptionistAnimationIndex_ !== -1
                ) {
                    // selected background is not in newPicture
                    receptionistSelectedAnimationsObj_.selectedIdleAnimation =
                        newAnimations[0].fileName;
                    receptionistAnimations_[
                        receptionistAnimationIndex_
                    ].selectedIdleAnimation = newAnimations[0].fileName;
                }

            if (
                newAnimations.find(
                    (animation) =>
                        animation.fileName ===
                        receptionistSelectedAnimationsObj_.selectedTalkAnimation
                ) === undefined
            )
                if (
                    newAnimations.length > 0 &&
                    receptionistAnimationIndex_ !== -1
                ) {
                    // selected background is not in newPicture
                    receptionistSelectedAnimationsObj_.selectedTalkAnimation =
                        newAnimations[0].fileName;
                    receptionistAnimations_[
                        receptionistAnimationIndex_
                    ].selectedTalkAnimation = newAnimations[0].fileName;
                }

            setReceptionistSelectedAnimationsObj(
                receptionistSelectedAnimationsObj_
            );
            // setReceptionistSelectedAnimationsObjRefined({...receptionistSelectedAnimationsObj_, animation: formatAnimationObj(receptionistSelectedAnimationsObj_.animation)})
            setReceptionistAnimations(receptionistAnimations_);
            setIsAnimationUpdated(true);

            // console.log(receptionistSelectedAnimationsObj_);
            // console.log({...receptionistSelectedAnimationsObj_, animation: formatAnimationObj(receptionistSelectedAnimationsObj_.animation)});
            // console.log(receptionistAnimations_);
            forceUpdate();
        },
        [
            getPhotoName,
            getPhotoId,
            selectedAnimation,
            receptionistSelectedAnimationsObj,
            receptionistAnimations,
        ]
    );

    const changeSelectedBowAnimation = (name, forceUpdate) => {
        const receptionistSelectedAnimationsObj_ = unFreezeObjOrArray(
            receptionistSelectedAnimationsObj
        );
        receptionistSelectedAnimationsObj_.selectedBowAnimation = name;
        setReceptionistSelectedAnimationsObj(
            receptionistSelectedAnimationsObj_
        );
        const receptionistAnimations_ = unFreezeObjOrArray(
            receptionistAnimations
        );
        const receptionistAnimationIndex_ = receptionistAnimations_.findIndex(
            (animation) =>
                animation.name === receptionistSelectedAnimationsObj_.name
        );
        if (receptionistAnimationIndex_ !== -1) {
            // if index is found
            receptionistAnimations_[
                receptionistAnimationIndex_
            ].selectedBowAnimation = name;
            setReceptionistAnimations(receptionistAnimations_);
        }
        setIsAnimationUpdated(true);
        forceUpdate();
    };
    const changeSelectedBowAnimationStyles = useCallback(
        (field, value, forceUpdate) => {
            if (field === "top") bowAvatarStyles.setTop(value);
            else if (field === "width") bowAvatarStyles.setWidth(value);
            else if (field === "left") bowAvatarStyles.setLeft(value);
            const receptionistSelectedAnimationsObj_ = unFreezeObjOrArray(
                receptionistSelectedAnimationsObj
            );
            receptionistSelectedAnimationsObj_.selectedBowAnimationStyles[
                field
            ] = value;
            setReceptionistSelectedAnimationsObj(
                receptionistSelectedAnimationsObj_
            );
            const receptionistAnimations_ = unFreezeObjOrArray(
                receptionistAnimations
            );
            const receptionistAnimationIndex_ =
                receptionistAnimations_.findIndex(
                    (animation) =>
                        animation.name ===
                        receptionistSelectedAnimationsObj_.name
                );
            if (receptionistAnimationIndex_ !== -1) {
                // if index is found
                receptionistAnimations_[
                    receptionistAnimationIndex_
                ].selectedBowAnimationStyles[field] = value;
                setReceptionistAnimations(receptionistAnimations_);
            }
            setIsAnimationUpdated(true);
            forceUpdate();
        },
        [receptionistSelectedAnimationsObj, receptionistAnimations]
    );

    const changeSelectedIdleAnimation = (name, forceUpdate) => {
        const receptionistSelectedAnimationsObj_ = unFreezeObjOrArray(
            receptionistSelectedAnimationsObj
        );
        receptionistSelectedAnimationsObj_.selectedIdleAnimation = name;

        setReceptionistSelectedAnimationsObj(
            receptionistSelectedAnimationsObj_
        );
        const receptionistAnimations_ = unFreezeObjOrArray(
            receptionistAnimations
        );
        const receptionistAnimationIndex_ = receptionistAnimations_.findIndex(
            (animation) =>
                animation.name === receptionistSelectedAnimationsObj_.name
        );
        if (receptionistAnimationIndex_ !== -1) {
            // if index is found
            receptionistAnimations_[
                receptionistAnimationIndex_
            ].selectedIdleAnimation = name;
            setReceptionistAnimations(receptionistAnimations_);
        }
        setIsAnimationUpdated(true);
        forceUpdate();
    };
    const changeSelectedIdleAnimationStyles = useCallback(
        (field, value, forceUpdate) => {
            if (field === "top") idleAvatarStyles.setTop(value);
            else if (field === "width") idleAvatarStyles.setWidth(value);
            else if (field === "left") idleAvatarStyles.setLeft(value);

            const receptionistSelectedAnimationsObj_ = unFreezeObjOrArray(
                receptionistSelectedAnimationsObj
            );
            receptionistSelectedAnimationsObj_.selectedIdleAnimationStyles[
                field
            ] = value;

            setReceptionistSelectedAnimationsObj(
                receptionistSelectedAnimationsObj_
            );
            const receptionistAnimations_ = unFreezeObjOrArray(
                receptionistAnimations
            );
            const receptionistAnimationIndex_ =
                receptionistAnimations_.findIndex(
                    (animation) =>
                        animation.name ===
                        receptionistSelectedAnimationsObj_.name
                );
            if (receptionistAnimationIndex_ !== -1) {
                // if index is found
                receptionistAnimations_[
                    receptionistAnimationIndex_
                ].selectedIdleAnimationStyles[field] = value;
                setReceptionistAnimations(receptionistAnimations_);
            }
            setIsAnimationUpdated(true);
            forceUpdate();
        },
        [receptionistSelectedAnimationsObj, receptionistAnimations]
    );

    const changeSelectedTalkAnimation = (name, forceUpdate) => {
        const receptionistSelectedAnimationsObj_ = unFreezeObjOrArray(
            receptionistSelectedAnimationsObj
        );
        receptionistSelectedAnimationsObj_.selectedTalkAnimation = name;
        setReceptionistSelectedAnimationsObj(
            receptionistSelectedAnimationsObj_
        );
        const receptionistAnimations_ = unFreezeObjOrArray(
            receptionistAnimations
        );
        const receptionistAnimationIndex_ = receptionistAnimations_.findIndex(
            (animation) =>
                animation.name === receptionistSelectedAnimationsObj_.name
        );
        if (receptionistAnimationIndex_ !== -1) {
            // if index is found
            receptionistAnimations_[
                receptionistAnimationIndex_
            ].selectedTalkAnimation = name;
            setReceptionistAnimations(receptionistAnimations_);
        }
        setIsAnimationUpdated(true);
        forceUpdate();
    };

    const changeSelectedTalkAnimationStyles = useCallback(
        (field, value, forceUpdate) => {
            if (field === "top") talkAvatarStyles.setTop(value);
            else if (field === "width") talkAvatarStyles.setWidth(value);
            else if (field === "left") talkAvatarStyles.setLeft(value);

            const receptionistSelectedAnimationsObj_ = unFreezeObjOrArray(
                receptionistSelectedAnimationsObj
            );
            receptionistSelectedAnimationsObj_.selectedTalkAnimationStyles[
                field
            ] = value;
            setReceptionistSelectedAnimationsObj(
                receptionistSelectedAnimationsObj_
            );
            const receptionistAnimations_ = unFreezeObjOrArray(
                receptionistAnimations
            );
            const receptionistAnimationIndex_ =
                receptionistAnimations_.findIndex(
                    (animation) =>
                        animation.name ===
                        receptionistSelectedAnimationsObj_.name
                );
            if (receptionistAnimationIndex_ !== -1) {
                // if index is found
                receptionistAnimations_[
                    receptionistAnimationIndex_
                ].selectedTalkAnimationStyles[field] = value;
                setReceptionistAnimations(receptionistAnimations_);
            }
            setIsAnimationUpdated(true);
            forceUpdate();
        },
        [receptionistSelectedAnimationsObj, receptionistAnimations]
    );

    const handleCreateAnimation = () => {
        const receptionistAnimations_ = unFreezeObjOrArray(
            receptionistAnimations
        );
        if (
            receptionistAnimations_.find(
                (animation) => animation.name === animationName
            ) !== undefined
        )
            return dispatch(
                addToNotifications({
                    message: translate("Animation name already exists"),
                    type: "ERROR",
                    size: "md",
                    duration: 2,
                })
            );
        const animation = {
            name: animationName,
            selectedBowAnimation: "",
            selectedTalkAnimation: "",
            selectedIdleAnimation: "",
            selectedBowAnimationStyles: {},
            selectedTalkAnimationStyles: {},
            selectedIdleAnimationStyles: {},
            animation: [],
            avatar: settingsRef.current[dbField]._id,
            appearance: settingsRef.current._id,
        };
        setReceptionistAnimations([...receptionistAnimations_, animation]);
        setReceptionistSelectedAnimationsImg([]);
        setSelectedAnimation(animation.name);
        setReceptionistSelectedAnimationsObj(animation);
        setDialogOpen(false);
        setAnimationName("");
        idleAvatarStyles.setDefault({});
        talkAvatarStyles.setDefault({});
        bowAvatarStyles.setDefault({});
    };

    const handleDeleteCurrentAnimation = () => {
        const receptionistAnimations_ = unFreezeObjOrArray(
            receptionistAnimations
        );
        const newReceptionistAnimations_ = receptionistAnimations_.filter(
            (animation) =>
                animation.name !== receptionistSelectedAnimationsObj.name
        );
        setReceptionistAnimations(newReceptionistAnimations_);
        setReceptionistSelectedAnimationsObj(newReceptionistAnimations_[0]);
        setSelectedAnimation(newReceptionistAnimations_[0].name);
        setReceptionistSelectedAnimationsImg(
            newReceptionistAnimations_[0].animation.map((animation) =>
                animation.fileId
                    ? `${uploadURL}${animation.fileId}`
                    : animation.file
            )
        );
        idleAvatarStyles.setDefault(
            newReceptionistAnimations_[0].selectedIdleAnimationStyles
        );
        talkAvatarStyles.setDefault(
            newReceptionistAnimations_[0].selectedTalkAnimationStyles
        );
        bowAvatarStyles.setDefault(
            newReceptionistAnimations_[0].selectedBowAnimationStyles
        );
    };

    const handleChangeAnimation = (name) => {
        setSelectedAnimation(name);
        const receptionistAnimations_ = unFreezeObjOrArray(
            receptionistAnimations
        );
        const newAnimationObj = receptionistAnimations_.find(
            (animation) => animation.name === name
        );
        if (newAnimationObj !== undefined) {
            setReceptionistSelectedAnimationsObj(newAnimationObj);
            setSelectedAnimation(newAnimationObj.name);
            const newAnimationImg = newAnimationObj.animation.map((animation) =>
                animation.fileId
                    ? `${uploadURL}${animation.fileId}`
                    : animation.file
            );
            setReceptionistSelectedAnimationsImg(newAnimationImg);
            idleAvatarStyles.setDefault(
                newAnimationObj.selectedIdleAnimationStyles
            );
            talkAvatarStyles.setDefault(
                newAnimationObj.selectedTalkAnimationStyles
            );
            bowAvatarStyles.setDefault(
                newAnimationObj.selectedBowAnimationStyles
            );
        }
    };

    const formatAnimationObj = useCallback((obj) => {
        const { save, file, ...others } = obj;
        const file_ = file
            .replace(/(?<=name=)(.*)(?=;)/g, "")
            .replace("name=;", "");
        return save ? { file: file_, ...others } : others;
    }, []);

    return useMemo(
        () => ({
            isAvatarOn,
            setIsAvatarOn,
            isAvatarAnimationOn,
            setIsAvatarAnimationOn,
            avatarQuality,
            setAvatarQuality,
            receptionistAnimations,
            selectedAnimation,
            receptionistSelectedAnimationsImg,
            receptionistSelectedAnimationsObj,
            setSelectedAnimation,
            onDrop,
            changeSelectedBowAnimation,
            changeSelectedIdleAnimation,
            changeSelectedTalkAnimation,
            animationName,
            setAnimationName,
            dialogOpen,
            setDialogOpen,
            handleCreateAnimation,
            handleChangeAnimation,
            handleDeleteCurrentAnimation,
            removeDeletion,
            isAnimationUpdated,
            idleAnimationStyles: {
                text: translate("Change Idle Animation Styles"),
                top: idleAvatarStyles.top,
                left: idleAvatarStyles.left,
                width: idleAvatarStyles.width,
                setTop: changeSelectedIdleAnimationStyles,
                setLeft: changeSelectedIdleAnimationStyles,
                setWidth: changeSelectedIdleAnimationStyles,
            },
            bowAnimationStyles: {
                text: translate("Change Bow Animation Styles"),
                top: bowAvatarStyles.top,
                left: bowAvatarStyles.left,
                width: bowAvatarStyles.width,
                setTop: changeSelectedBowAnimationStyles,
                setLeft: changeSelectedBowAnimationStyles,
                setWidth: changeSelectedBowAnimationStyles,
            },
            talkAnimationStyles: {
                text: translate("Change Talk Animation Styles"),
                top: talkAvatarStyles.top,
                left: talkAvatarStyles.left,
                width: talkAvatarStyles.width,
                setTop: changeSelectedTalkAnimationStyles,
                setLeft: changeSelectedTalkAnimationStyles,
                setWidth: changeSelectedTalkAnimationStyles,
            },
        }),
        [
            isAvatarOn,
            isAvatarAnimationOn,
            avatarQuality,
            receptionistAnimations,
            selectedAnimation,
            receptionistSelectedAnimationsImg,
            receptionistSelectedAnimationsObj,
            animationName,
            dialogOpen,
            removeDeletion,
            isAnimationUpdated,
            idleAvatarStyles,
            bowAvatarStyles,
            talkAvatarStyles,
        ]
    );
};

const useAvatarStyleSettings = () => {
    const [top, setTop] = useState(0);
    const [left, setLeft] = useState(-10);
    const [width, setWidth] = useState(50);

    const setDefault = (defaultValues) => {
        defaultValues.top !== undefined ? setTop(defaultValues.top) : setTop(0);
        defaultValues.left !== undefined
            ? setLeft(defaultValues.left)
            : setLeft(-10);
        defaultValues.width !== undefined
            ? setWidth(defaultValues.width)
            : setWidth(50);
    };

    return useMemo(
        () => ({
            setDefault,
            top,
            setTop,
            left,
            setLeft,
            width,
            setWidth,
        }),
        [top, left, width]
    );
};
