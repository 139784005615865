import { makeStyles } from "@material-ui/core/styles";
import { hexToRgb } from "../../material-dashboard-react";

const useStyles = makeStyles(({ primaryColor, secondaryColor }) => ({
    container: {
        width: "80%",
        marginLeft: "2em",
        marginBottom: "0.5em",
    },
    expressionSubContainer: {
        marginBottom: "0.5em",
    },
    image: {
        width: "2em",
        marginRight: "1em",
    },
    title: {
        fontSize: "1rem",
        fontWeight: "bold",
        color: primaryColor,
    },
    age: {
        marginTop: "0.5em",
        fontSize: "1rem",
        fontWeight: "bold",
        color: primaryColor,
    },
    text: {
        color: `rgba(${hexToRgb(secondaryColor)}, 0.54) !important`,
        fontWeight: "bold",
    },
    ageText: {
        color: `rgba(${hexToRgb(secondaryColor)}, 0.54) !important`,
        marginLeft: "2.25em",
    },
    progress: {
        width: "80%",
        marginLeft: "2em",
    },
}));

export default useStyles;
