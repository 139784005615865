import { CLEAR_NOTIFICATIONS, ADD_NOTIFICATION } from "./types";
import { message, notification } from "antd";
import { translate } from "../../utils/i18n";

String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

export const clearNotifications = () => {
    return {
        type: CLEAR_NOTIFICATIONS,
    };
};

export const displayNotifications = (notifications) => (dispatch) => {
    message.destroy();
    notifications.forEach((notification_) => {
        const { type, size, position, duration, ...other } = notification_;
        const message_ = notification_.message;
        switch (size) {
            case "sm":
                return message[type.toLowerCase()]({
                    content: message_,
                    ...other,
                });
            case "md":
                return notification[type.toLowerCase()]({
                    message: translate(type.toLowerCase().capitalize()),
                    description: message_,
                    placement: "bottomLeft",
                    duration,
                });
            default:
                return message[type.toLowerCase()](message_);
        }
    });
    dispatch(clearNotifications());
};

export const addToNotifications = (notification_) => (dispatch) => {
    dispatch({
        type: ADD_NOTIFICATION,
        payload: notification_,
    });
};
