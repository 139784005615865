import { Row } from "antd";
import Button from "../../../Customized Libraries/CustomButtons/Button";
import React from "react";
import { useSelector } from "react-redux";
import HelpSelection from "../../HelpSelection";

const Horizontal = ({ buttonList, onClick, botLang }) => {
    const language = useSelector((state) =>
        state.language.currentLanguage.split("-").shift()
    );

    const _clickHandle = (value, display) => {
        if (typeof onClick === "function") {
            onClick(value, display);
        }
    };

    return (
        <HelpSelection
            options={buttonList.map((button) => ({
                text: button?.label[language],
                onClick: () =>
                    _clickHandle(
                        button?.value[language],
                        button?.label[language]
                    ),
            }))}
        />
    );
};

Horizontal.defaultProps = {
    buttonList: [],
};

export default Horizontal;
