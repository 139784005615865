import React, { useEffect, useState } from "react";
import userStyles from "../../../styles/UI/modals/ChatBotPluginModalStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { Spin } from "antd";
import { getContentBot } from "../../../store/actions/pluginActions";
import PropTypes from "prop-types";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ChatBotPluginModal = (props) => {
    const classes = userStyles();
    const { language, url, open, handleClose } = props;
    const [content, setContent] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const getBot = async () => {
        getContentBot(url, setIsLoading).then((bot) =>
            bot ? setContent(bot) : handleClose()
        );
    };

    useEffect(() => {
        getBot();
    }, [language]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogContent className={classes.pluginContainer}>
                {isLoading ? (
                    <Spin size={"large"} />
                ) : (
                    <div
                        className={classes.frameContainer}
                        id="JKChatView_place_holder"
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};
ChatBotPluginModal.propTypes = {
    language: PropTypes.string,
    url: PropTypes.string,
    open: PropTypes.bool,
    handleClose: PropTypes.func,
};

export default ChatBotPluginModal;
