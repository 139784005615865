import React, { useCallback, useMemo, useState } from "react";
import { translate } from "../../../../../utils/i18n";
import { Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { StyledTableCell } from "../user/UserTable";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import TrackerGroupPopper from "./TrackerGroupPopper";
import Button from "@material-ui/core/Button";
import { StyledTableRow } from "../user/UserCard";

const useStyles = makeStyles((theme) => ({
    button: {
        textTransform: "none",
        "&:hover": {
            cursor: "pointer",
            background: "rgba(0, 0, 0, 0.08)",
        },
    },
}));

const TrackerCard = ({
    onTrackerUpdate,
    trackers,
    isItemSelected,
    labelId,
    handleClick,
}) => {
    const classes = useStyles();
    const { trackers: trackerGroup, day: date } = trackers;
    const [isCheckInPopperOpen, setIsCheckInPopperOpen] = useState(null);
    const checkInPopperOpen = Boolean(isCheckInPopperOpen);
    const idCheckInPopper = checkInPopperOpen
        ? `simple-popover19928`
        : undefined;

    const [isCheckOutPopperOpen, setIsCheckOutPopperOpen] = useState(null);
    const checkOutPopperOpen = Boolean(isCheckOutPopperOpen);
    const idCheckOutPopper = checkOutPopperOpen
        ? `simple-popover1893`
        : undefined;

    const checkInGroup = useMemo(
        () => trackerGroup.filter((tracker) => tracker.type === "checkIn"),
        [trackerGroup]
    );
    const checkOutGroup = useMemo(
        () => trackerGroup.filter((tracker) => tracker.type === "checkOut"),
        [trackerGroup]
    );

    const getDate = useCallback(
        (trackers, type, textOnInvalidTime) => {
            const trackersFlattened = trackers.map((tracker) =>
                moment(tracker.time)
            );
            switch (type) {
                case "min":
                    const min =
                        trackersFlattened.length > 0
                            ? moment.min(trackersFlattened)
                            : [];
                    return (
                        trackers.find((tracker) =>
                            moment(tracker.time).isSame(min)
                        ) || translate(`No ${textOnInvalidTime}`)
                    );
                case "max":
                    const max =
                        trackersFlattened.length > 0
                            ? moment.max(trackersFlattened)
                            : [];
                    return (
                        trackers.find((tracker) =>
                            moment(tracker.time).isSame(max)
                        ) || translate(`No ${textOnInvalidTime}`)
                    );
                default:
                    return "";
            }
        },
        [trackerGroup]
    );

    const firstCheckIn = useMemo(
        () => getDate(checkInGroup, "min", "Check In"),
        [checkInGroup]
    );
    const lastCheckOut = useMemo(
        () => getDate(checkOutGroup, "max", "Check Out"),
        [checkOutGroup]
    );
    const totalWorkHours = useMemo(() => {
        if (firstCheckIn.time && lastCheckOut.time) {
            const _firstCheckIn = moment(firstCheckIn.time);
            const _lastCheckOut = moment(lastCheckOut.time);
            return _lastCheckOut.diff(_firstCheckIn, "hours", true).toFixed(2);
        } else return translate("No total work hours");
    }, [firstCheckIn, lastCheckOut]);

    const handleCheckInPopperOpen = useCallback(
        (event) => {
            if (checkInGroup.length > 0)
                setIsCheckInPopperOpen(
                    isCheckInPopperOpen ? null : event.currentTarget
                );
        },
        [checkInGroup, isCheckInPopperOpen]
    );

    const handleCheckOutPopperOpen = useCallback(
        (event) => {
            if (checkOutGroup.length > 0)
                setIsCheckOutPopperOpen(
                    isCheckOutPopperOpen ? null : event.currentTarget
                );
        },
        [checkOutGroup, isCheckOutPopperOpen]
    );

    return (
        <StyledTableRow
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            selected={isItemSelected}
        >
            <StyledTableCell padding="checkbox">
                <Checkbox
                    onClick={handleClick}
                    checked={isItemSelected}
                    inputProps={{ "aria-labelledby": labelId }}
                />
            </StyledTableCell>
            <StyledTableCell component="th" scope="row">
                {moment(date).format("LL")}
            </StyledTableCell>
            <StyledTableCell align="right">
                <Button
                    onClick={handleCheckInPopperOpen}
                    aria-describedby={idCheckInPopper}
                    className={classes.button}
                >
                    {firstCheckIn.time
                        ? moment(firstCheckIn.time).format("LT")
                        : firstCheckIn}
                </Button>
            </StyledTableCell>
            <StyledTableCell align="right">
                <Button
                    onClick={handleCheckOutPopperOpen}
                    aria-describedby={idCheckOutPopper}
                    className={classes.button}
                >
                    {lastCheckOut.time
                        ? moment(lastCheckOut.time).format("LT")
                        : lastCheckOut}
                </Button>
            </StyledTableCell>
            <StyledTableCell align="right">{totalWorkHours}</StyledTableCell>
            <TrackerGroupPopper
                onTrackerUpdate={onTrackerUpdate}
                trackerGroup={checkInGroup}
                setIsPopperOpen={setIsCheckInPopperOpen}
                type={"Check In"}
                isPopperOpen={isCheckInPopperOpen}
                popperOpen={checkInPopperOpen}
                idPopper={idCheckInPopper}
            />
            <TrackerGroupPopper
                onTrackerUpdate={onTrackerUpdate}
                trackerGroup={checkOutGroup}
                setIsPopperOpen={setIsCheckOutPopperOpen}
                type={"Check Out"}
                isPopperOpen={isCheckOutPopperOpen}
                popperOpen={checkOutPopperOpen}
                idPopper={idCheckOutPopper}
            />
        </StyledTableRow>
    );
};

TrackerCard.propTypes = {
    onTrackerUpdate: PropTypes.func,
    trackers: PropTypes.object.isRequired,
    isItemSelected: PropTypes.bool.isRequired,
    labelId: PropTypes.string.isRequired,
};

TrackerCard.defaultProps = {
    onTrackerUpdate: () => {},
};
export default TrackerCard;
