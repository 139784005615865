import { useEffect, useRef } from "react";

/**
 * This hook returns the previous value of a variable.
 *
 * HOOK INITIALIZATION:
 * @param {*} value - Any variable can be passed
 * @param {*=} init - Optional Initialization variable, Any variable can be passed
 * @returns {ref.current} ref.current - return previous value
 *
 *
 * Usage:
 *      const [num, setNum] = useState(0)
 *      const previousNum = usePrevious(num, -1)
 *
 * */

export const usePrevious = (value, init = null) => {
    const ref = useRef(init);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};
