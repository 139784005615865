import { makeStyles } from "@material-ui/core/styles";
import { hexToRgb } from "../../../../../styles/material-dashboard-react";
import React, { useCallback, useMemo, useState } from "react";
import {
    Checkbox,
    IconButton,
    Toolbar,
    Tooltip,
    Typography,
} from "@material-ui/core";
import classnames from "classnames";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { StyledTableCell } from "../user/UserTable";
import { translate } from "../../../../../utils/i18n";
import TableBody from "@material-ui/core/TableBody";
import UserCard from "../user/UserCard";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ConfirmationModal from "../../../modals/ConfirmationModal";
import { useDispatch } from "react-redux";
import {
    deleteTracker,
    exportTrackersToExcel,
} from "../../../../../store/actions/checkInCheckOutActions";
import { ADD_NOTIFICATION } from "../../../../../store/actions/types";
import { Spin } from "antd";
import TrackerCard from "./TrackerCard";

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        padding: 0,
    },
    highlight: {
        color: `white`,
        backgroundColor: `rgba(${hexToRgb(theme.palette.common.black)}, 0.5)`,
        height: "8em",
    },
    userInfoTitle: {
        flex: "1 1 100%",
        marginBottom: "2em",
    },
    icon: {
        color: "white",
        marginRight: "1em",
    },
    actionsContainer: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
    },
    selectedText: {
        fontWeight: "bold",
        marginLeft: "1em",
    },
}));

const TableToolbar = ({
    numSelected,
    currentUser,
    handleDeleteSelected,
    handleExportToExcel,
    isLoading,
}) => {
    const classes = useToolbarStyles();
    const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
        useState(false);
    const [isExportConfirmationDialogOpen, setIsExportConfirmationDialogOpen] =
        useState(false);

    const onDeleteSelected = async () => {
        await handleDeleteSelected();
        setIsDeleteConfirmationDialogOpen(false);
    };

    const onExportSelected = async () => {
        await handleExportToExcel();
        setIsExportConfirmationDialogOpen(false);
    };

    return (
        <Toolbar
            className={classnames(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected === 0 && (
                <Typography
                    className={classes.userInfoTitle}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    <TableContainer className={classes.userInfoTable}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>
                                        {translate("Display Name")}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {translate("Email")}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {translate("Slack Id")}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {translate("Date Of Birth")}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {""}
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <UserCard user={currentUser} noButtons />
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Typography>
            )}

            {numSelected > 0 && (
                <div className={classes.actionsContainer}>
                    <Typography
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                        className={classes.selectedText}
                    >
                        {numSelected} selected
                    </Typography>
                    <div className={classes.buttonContainer}>
                        <Tooltip title={translate("Delete")}>
                            <IconButton
                                aria-label="delete"
                                onClick={() =>
                                    setIsDeleteConfirmationDialogOpen(true)
                                }
                                className={classes.icon}
                                disabled={isLoading}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={translate("Export to excel")}>
                            <IconButton
                                aria-label="export to excel"
                                onClick={() =>
                                    setIsExportConfirmationDialogOpen(true)
                                }
                                className={classes.icon}
                                disabled={isLoading}
                            >
                                <FileCopyIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            )}
            <ConfirmationModal
                open={isDeleteConfirmationDialogOpen}
                handleClose={() => setIsDeleteConfirmationDialogOpen(false)}
                handleSuccess={onDeleteSelected}
                title={translate(`Delete Trackers`)}
                message={translate(
                    `Are you sure you want to delete the selected tracker?`
                )}
                abortText={translate("No")}
                successText={translate("Yes")}
                isLoading={isLoading}
            />
            <ConfirmationModal
                open={isExportConfirmationDialogOpen}
                handleClose={() => setIsExportConfirmationDialogOpen(false)}
                handleSuccess={onExportSelected}
                title={translate(`Export selected Trackers`)}
                message={translate(
                    `Are you sure you want to export the selected trackers?`
                )}
                abortText={translate("No")}
                successText={translate("Yes")}
                isLoading={isLoading}
            />
        </Toolbar>
    );
};

const TrackerTable = ({
    classes,
    trackers,
    onTrackerUpdate,
    currentUser,
    isLoading,
}) => {
    const dispatch = useDispatch();
    const [selected, setSelected] = useState([]);
    const [_isLoading, _setIsLoading] = useState(false);

    const rowCount = useMemo(() => trackers.length, [trackers]);
    const numSelected = useMemo(() => selected.length, [selected]);

    const isSelected = (trackerDay) => selected.indexOf(trackerDay) !== -1;

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = trackers.map(
                (trackerGroup) => trackerGroup.day
            );
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const onDeleteSelected = useCallback(async () => {
        _setIsLoading(true);
        const results = [];
        const trackerGroupsToDelete = selected.map((trackerDay) =>
            trackers.find((trackerGroup) => trackerGroup.day === trackerDay)
        );
        const trackersToDeleteFlattened = [];
        trackerGroupsToDelete.forEach((trackerGroup) =>
            trackersToDeleteFlattened.push(
                ...trackerGroup.trackers.map((tracker) => tracker._id)
            )
        );
        trackersToDeleteFlattened.forEach((trackerId) => {
            results.push(dispatch(deleteTracker(trackerId)));
        });
        await Promise.all(results);
        if (results.filter(Boolean)) {
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "SUCCESS",
                    message: translate("Delete selected trackers successfully"),
                    size: "md",
                },
            });
            setSelected([]);

            onTrackerUpdate();
        }
        _setIsLoading(false);
    }, [trackers, selected]);

    const handleCheckBoxClick = (event, trackerDay) => {
        const selectedIndex = selected.indexOf(trackerDay);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, trackerDay);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const onExportToExcel = useCallback(async () => {
        _setIsLoading(true);
        const trackerGroupsToExport = selected.map((trackerDay) =>
            trackers.find((trackerGroup) => trackerGroup.day === trackerDay)
        );
        exportTrackersToExcel(trackerGroupsToExport, currentUser);
        setSelected([]);
        _setIsLoading(false);
    }, [selected, trackers, currentUser]);

    return (
        <div>
            <TableToolbar
                numSelected={numSelected}
                currentUser={currentUser}
                handleDeleteSelected={onDeleteSelected}
                handleExportToExcel={onExportToExcel}
                isLoading={_isLoading}
            />
            {isLoading && (
                <div className={classes.skeleton}>
                    <Spin />
                </div>
            )}
            {!isLoading && trackers.length === 0 && (
                <div className={classes.skeleton}>
                    {translate("No trackers found")}
                </div>
            )}
            {!isLoading && trackers.length !== 0 && (
                <TableContainer id={"trackerContent"} className={classes.table}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell padding="checkbox">
                                    <Checkbox
                                        indeterminate={
                                            numSelected > 0 &&
                                            numSelected < rowCount
                                        }
                                        checked={
                                            rowCount > 0 &&
                                            numSelected === rowCount
                                        }
                                        onChange={handleSelectAllClick}
                                        inputProps={{
                                            "aria-label": "select all trackers",
                                        }}
                                    />
                                </StyledTableCell>
                                <StyledTableCell>
                                    {translate("Date")}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    {translate("First Check In")}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    {translate("Last Check Out")}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    {translate("Total Work Hours")}
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trackers.length > 0 &&
                                trackers.map((trackerGroup, index) => {
                                    const isItemSelected = isSelected(
                                        trackerGroup.day
                                    );
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TrackerCard
                                            key={index}
                                            onTrackerUpdate={onTrackerUpdate}
                                            trackers={trackerGroup}
                                            isItemSelected={isItemSelected}
                                            labelId={labelId}
                                            handleClick={(event) =>
                                                handleCheckBoxClick(
                                                    event,
                                                    trackerGroup.day
                                                )
                                            }
                                        />
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
};

export default TrackerTable;
