import axios from "axios";
import { ADD_NOTIFICATION } from "./types";
import { translate } from "../../utils/i18n";
import store from "../../store";

const url = process.env.REACT_APP_DOOR_API;

export const openDoor = (language) => async (dispatch) => {
    let newURL = null;
    try {
        newURL = store.getState().settings.applicationSettings.door.server;
    } catch (e) {
        setTimeout(() => openDoor(language), 1000);
    }
    await axios
        .get(newURL || url)
        .then((res) => {
            // console.log(res);
            if (res.data.status === "ok")
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "INFO",
                        message: translate("Opening Door!"),
                        size: "md",
                    },
                });
            else if (res.data.status === "error") {
                // debug
                console.log(res.data);
                const message =
                    res.data.info[language.split("-").shift()] ||
                    translate("Something went wrong!");
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: { type: "ERROR", message, size: "sm" },
                });
            }
        })
        .catch((err) => {
            console.log("Error");
            console.log(err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
        });
};

export const canDoorOpen = () => {
    let canDoorOpen = false;
    // return Moment().hours() < 18 && Moment().hours() >= 8;
    try {
        canDoorOpen = store.getState().settings.applicationSettings.door.on;
    } catch (e) {
        return canDoorOpen;
    }
    return canDoorOpen;
};
