import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Pagination } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import { translate } from "../../../../../utils/i18n";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Button, Fab, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import {
    downloadFileWithDepositionAttachment,
    getTrackersByUserId,
    getTrackersByUsersExcel,
} from "../../../../../store/actions/checkInCheckOutActions";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { DateTimePicker } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import reactStringReplace from "react-string-replace";
import moment from "moment-timezone";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { hexToRgb } from "../../../../../styles/material-dashboard-react";
import { addToNotifications } from "../../../../../store/actions/notificationActions";
import TrackerTable from "./TrackerTable";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    skeleton: {
        height: "68vh",
        overflowY: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    container: {
        height: "68vh",
    },
    limitField: {
        width: "14em",
        marginLeft: "1em",
    },
    fab: {
        position: "absolute",
        left: "18%",
        bottom: "4%",
        boxShadow: "none",
        background: "rgba(0, 0, 0, 0.5)",
        color: "white",
        "&:hover": {
            background: "rgba(0, 0, 0, 0.5)",
        },
    },
    titleText: {
        fontSize: "1.5rem",
        margin: "0 0.75em",
    },
    button: {
        color: "rgba(0, 0, 0, 0.65)",
        marginRight: "1em",
    },
    title: {
        margin: "3em 0",
    },
    userInfoTable: {
        marginBottom: "3em",
    },
    table: {
        marginBottom: "1em",
        height: "66vh",
    },
    "@global #trackerRoot .MuiTableCell-root": {
        padding: "0.5em 1em !important",
        borderBottom: "none !important",
    },
    "@global #trackerContent::-webkit-scrollbar-track": {
        webkitBoxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
        backgroundColor: "#f5f5f5",
    },

    "@global #trackerContent::-webkit-scrollbar": {
        width: "4px",
        height: "4px",
        backgroundColor: "#f5f5f5",
    },

    "@global #trackerContent::-webkit-scrollbar-thumb": {
        border: `2px solid rgba(${hexToRgb(theme.palette.common.black)}, 0.5)`,
    },
    "@global #trackerRoot .MuiCheckbox-colorSecondary.Mui-checked": {
        color: `rgba(0, 0, 0, 0.54) !important`,
    },
}));

const INITIAL_SETTINGS = {
    limit: 18,
    limitFieldFocused: false,
    timeMin: moment().startOf("day"),
    timeMax: moment().endOf("day"),
};

const Tracker = ({ currentUser, setSelectedIndex }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [trackers, setTrackers] = useState([]);

    const [pagination, setPagination] = useState({});
    const [page, setPage] = useState(1);

    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const [settingsForm, setSettingsForm] = useState(INITIAL_SETTINGS);

    const setSettingsFormValue =
        (field, value = null) =>
        (event) => {
            setSettingsForm({
                ...settingsForm,
                [field]: value !== null ? value : event.target.value,
            });
        };

    const setSettingsFormDateValue = (field, date) => {
        setSettingsForm({
            ...settingsForm,
            [field]: date,
        });
    };

    const buildQuery = useCallback(
        (page, timeMin, timeMax) => {
            const _timeMin = new Date(timeMin.toISOString());
            const _timeMax = new Date(timeMax.toISOString());
            return `groupBy=day&gte=${_timeMin}&lte=${_timeMax}&page=${page}&limit=${settingsForm.limit}`;
        },
        [settingsForm]
    );

    const getTrackers = useCallback(
        async (
            page,
            _timeMin = settingsForm.timeMin,
            _timeMax = settingsForm.timeMax
        ) => {
            setIsLoading(true);
            const queryString = buildQuery(page, _timeMin, _timeMax);
            const data = await dispatch(
                getTrackersByUserId(currentUser._id, queryString)
            );
            if (data.success) {
                setPagination(data.pagination);
                setTrackers(data.data);
                setPage(data.pagination.page);
            }
            setIsLoading(false);
        },
        [currentUser, buildQuery, settingsForm.timeMin, settingsForm.timeMax]
    );

    const handlePageChange = async (event, value) => {
        setPage(value);
        await getTrackers(value);
    };

    const renderTitle = useCallback(() => {
        const title = translate("Trackers from <start> to <end>");
        const getJSXElement = (type, index) => {
            switch (type) {
                case "<start>":
                    return (
                        <DateTimePicker
                            key={index}
                            autoOk
                            ampm={false}
                            cancelLabel={translate("Cancel")}
                            todayLabel={translate("Today")}
                            okLabel={translate("Ok")}
                            value={settingsForm.timeMin}
                            disabled={isLoading}
                            onChange={(date, _) =>
                                setSettingsFormDateValue("timeMin", date)
                            }
                            onAccept={(date) =>
                                getTrackers(page, date, settingsForm.timeMax)
                            }
                            format="yyyy/MM/dd HH:mm"
                            allowKeyboardControl={false}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton disabled={isLoading}>
                                            <EventIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            showTodayButton
                        />
                    );
                case "<end>":
                    return (
                        <DateTimePicker
                            key={index}
                            autoOk
                            ampm={false}
                            cancelLabel={translate("Cancel")}
                            todayLabel={translate("Today")}
                            okLabel={translate("Ok")}
                            value={settingsForm.timeMax}
                            disabled={isLoading}
                            onChange={(date, _) =>
                                setSettingsFormDateValue("timeMax", date)
                            }
                            onAccept={(date) =>
                                getTrackers(page, settingsForm.timeMin, date)
                            }
                            format="yyyy/MM/dd HH:mm"
                            allowKeyboardControl={false}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton disabled={isLoading}>
                                            <EventIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            showTodayButton
                        />
                    );
                default:
                    return <></>;
            }
        };

        const _title = reactStringReplace(
            title,
            /(<start>|<end>)/g,
            (match, i) => getJSXElement(match, i)
        );
        return _title.map((element, index) => {
            if (typeof element === "string")
                return (
                    <span className={classes.titleText} key={index}>
                        {element}
                    </span>
                );
            else return element;
        });
    }, [
        settingsForm.timeMin,
        settingsForm.timeMax,
        isLoading,
        page,
        getTrackers,
    ]);

    const onExportToExcel = useCallback(async () => {
        setIsDownloading(true);
        const _timeMin = new Date(settingsForm.timeMin.toISOString());
        const _timeMax = new Date(settingsForm.timeMax.toISOString());
        const fileName = `${currentUser.displayName}.xlsx`;
        const query = `groupBy=day&gte=${_timeMin}&lte=${_timeMax}&pagination=false&fileName=${fileName}&userIds=${currentUser._id}`;
        const res = await dispatch(getTrackersByUsersExcel(query));
        if (res) {
            downloadFileWithDepositionAttachment(res);
            dispatch(
                addToNotifications({
                    type: "SUCCESS",
                    message: translate("Downloading File!"),
                    size: "md",
                })
            );
        }
        setIsDownloading(false);
    }, [settingsForm, currentUser]);

    useEffect(() => {
        getTrackers(page);
    }, []);

    useEffect(() => {
        if (!settingsForm.limitFieldFocused) getTrackers(page);
    }, [settingsForm.limitFieldFocused]);

    return (
        <div className={classes.root} id={"trackerRoot"}>
            <Grid
                container
                justify={"space-between"}
                alignItems={"center"}
                className={classes.title}
            >
                <div>{renderTitle()}</div>
                <Button
                    className={classes.button}
                    onClick={onExportToExcel}
                    size="large"
                    variant={"outlined"}
                    disabled={isDownloading}
                >
                    <FileCopyIcon />
                    <div style={{ marginLeft: "0.5em" }}>
                        {translate("Export all to excel")}
                    </div>
                </Button>
            </Grid>

            <TrackerTable
                onTrackerUpdate={() => getTrackers(page)}
                trackers={trackers}
                classes={classes}
                isLoading={isLoading}
                currentUser={currentUser}
            />

            <Grid container justify="center" alignItems={"center"}>
                <Pagination
                    count={pagination.totalPages}
                    page={page}
                    onChange={handlePageChange}
                    disabled={isLoading}
                />
                <TextField
                    label={translate("No of trackers per page")}
                    value={settingsForm.limit}
                    className={classes.limitField}
                    onChange={setSettingsFormValue("limit")}
                    onFocus={setSettingsFormValue("limitFieldFocused", true)}
                    onBlur={setSettingsFormValue("limitFieldFocused", false)}
                />
            </Grid>
            <Fab
                aria-label="back"
                onClick={() => setSelectedIndex(1)}
                size={"small"}
                className={classes.fab}
            >
                <ArrowBackIcon />
            </Fab>
        </div>
    );
};

Tracker.propTypes = {
    currentUser: PropTypes.object.isRequired,
    setSelectedIndex: PropTypes.func.isRequired,
};

export default Tracker;
