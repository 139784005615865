import { makeStyles } from "@material-ui/core/styles";
import sizes from "../sizes";

const useStyles = makeStyles((theme) => ({
    root: {
        // border: '2px solid black',
        width: "40%",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        // [sizes.down('md2')]: {
        //     width: "25%",
        // }
    },
    receptionist: {
        width: "50%",
        position: "absolute",
        left: "-10%",
    },
    receptionistContainer: {
        width: "100%",
        height: "100%",
        display: "none",
        alignItems: "flex-end",
    },
    flex: {
        display: "flex",
    },
    none: {
        display: "none",
    },
    block: {
        display: "block",
    },
}));

export default useStyles;
