import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./store/reducers";

const initialState = {};

const middleware = [thunk];

const stateSanitizer = (state) =>
    state.settings ? { ...state, settings: "<<LONG_BLOB>>" } : state;
// const stateSanitizer = state => state.settings.appearanceSettings ? {...state, settings: {appearanceSettings: '<<LONG_BLOB>>', applicationSettings: state.settings.applicationSettings}} : state

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          stateSanitizer,
      })
    : compose;

const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
);

export default store;
