const sizes = {
    xs: "575.98px",
    sm: "767.98px",
    md: "991.98px",
    md1: "1200.98px",
    md2: "1400.98px",
    lg: "1499.98px",
    xl: "1690px",
    //we dont use xxl cause all the code weve written are for xl devices
};
const sizesNoPx = {
    xs: "575.98",
    sm: "767.98",
    md: "991.98",
    md1: "1200.98",
    md2: "1400.98",
    lg: "1499.98",
    xl: "1690",
    //we dont use xxl cause all the code weve written are for xl devices
};

export default {
    up() {},
    down(size) {
        return `@media (max-width: ${sizes[size]})`;
    },
    sizes,
    sizesNoPx,
};
