import { SET_RECEPTIONIST } from "../actions/types";

const initialState = {
    receptionist_state: "IDLE",
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_RECEPTIONIST:
            return {
                receptionist_state: action.receptionist_state,
            };
        default:
            return state;
    }
}
