import React from "react";
import userStyles from "../../../styles/UI/layout/RootLayoutStyles";
import BaseLayout from "./BaseLayout";
import Header from "./Header";
import Footer from "./Footer";
import Receptionist from "../Receptionist";
import { useAppearanceSettings } from "../../../hooks/settings/useAppearanceSettings";
import PropTypes from "prop-types";
import classnames from "classnames";

const RootLayout = (props) => {
    const {
        history,
        Component,
        location,
        addBaseLayout,
        noReceptionist,
        noFooter,
        noHeader,
    } = props;
    const classes = userStyles();
    const { background = {} } = useAppearanceSettings(["background"]);

    return (
        <>
            {addBaseLayout ? (
                <div className={classes.root} style={background}>
                    {!noHeader && <Header />}
                    <BaseLayout
                        Component={Component}
                        history={history}
                        location={location}
                    />
                </div>
            ) : (
                <div className={classes.root} style={background}>
                    {!noHeader && <Header />}

                    <div
                        className={classnames(classes.container, {
                            [classes.containerWithFooter]: !noFooter,
                        })}
                    >
                        {!noReceptionist && <Receptionist history={history} />}
                        <Component history={history} location={location} />
                        {!noFooter && (
                            <Footer history={history} location={location} />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

RootLayout.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    Component: PropTypes.func.isRequired,
    addBaseLayout: PropTypes.bool,
    noReceptionist: PropTypes.bool,
    noFooter: PropTypes.bool,
    noHeader: PropTypes.bool,
};

RootLayout.defaultProps = {
    addBaseLayout: false,
    noReceptionist: false,
    noFooter: false,
    noHeader: false,
};

export default RootLayout;
