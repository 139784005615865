import {
    ADD_TO_CHAT,
    RESET_CHAT,
    SET_SESSION,
    CLEAR_SESSION,
    SET_LANGUAGE,
    SET_IS_THINKING,
    REMOVE_LAST_ITEM_FROM_CHAT,
    SET_IS_GAPI_READY,
    ADD_AUDIO,
    STOP_ALL_AUDIO,
    SET_IS_PERFORMING_ACTION,
    SET_IS_ON_SCREEN_KEYBOARD,
    SET_BOT_ID,
} from "../actions/types";
import { bots } from "../../config.json";

const initialState = {
    messages: [],
    session: -1,
    isThinking: false,
    isGapiReady: false,
    audio: [],
    isPerformingAction: false,
    isOnScreenKeyboard: false,
    botId: bots[0].bot_id,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_TO_CHAT:
            return {
                ...state,
                messages: [
                    ...state.messages,
                    { message: action.message, isReceiver: action.isReceiver },
                ],
            };
        case RESET_CHAT:
            return {
                ...state,
                messages: [],
                session: -1,
            };
        case SET_SESSION:
            return {
                ...state,
                session: action.session,
            };
        case CLEAR_SESSION:
            return {
                ...state,
                session: -1,
            };
        case SET_IS_THINKING:
            return {
                ...state,
                isThinking: action.isThinking,
            };
        case REMOVE_LAST_ITEM_FROM_CHAT:
            return {
                ...state,
                messages: [...state.messages].slice(0, -1),
            };
        case SET_IS_GAPI_READY:
            return {
                ...state,
                isGapiReady: action.isGapiReady,
            };
        case ADD_AUDIO:
            return {
                ...state,
                audio: [...state.audio, action.audio],
            };
        case STOP_ALL_AUDIO:
            // stop current playing audio
            state.audio.forEach((audio) => {
                audio.pause();
                // audio.currentTime = 0;
            });
            return {
                ...state,
                audio: [],
            };
        case SET_IS_PERFORMING_ACTION:
            return {
                ...state,
                isPerformingAction: action.isPerformingAction,
            };
        case SET_IS_ON_SCREEN_KEYBOARD:
            return {
                ...state,
                isOnScreenKeyboard: action.isOnScreenKeyboard,
            };
        case SET_BOT_ID:
            return {
                ...state,
                botId: action.botId,
            };
        default:
            return state;
    }
}
