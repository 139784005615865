import { Row } from "antd";
import Button from "../../../Customized Libraries/CustomButtons/Button";
import React from "react";
import userStyles from "../../../../styles/UI/HelpSelectionStyles";
import { useSelector } from "react-redux";

const Vertical = ({ buttonList, onClick, botLang }) => {
    const language = useSelector((state) =>
        state.language.currentLanguage.split("-").shift()
    );
    const classes = userStyles();

    const _clickHandle = (value, display) => {
        if (typeof onClick === "function") {
            onClick(value, display);
        }
    };

    return (
        <div style={{ maxHeight: "50vh", overflowY: "auto", width: "100%" }}>
            {buttonList.map((button, index) => (
                <Row style={{ margin: "16px 0" }} key={index}>
                    <Button
                        color={"secondaryButtonTheme"}
                        size={"lg"}
                        className={classes.help}
                        onClick={() =>
                            _clickHandle(
                                button?.value[language],
                                button?.label[language]
                            )
                        }
                    >
                        {button?.label[language]}
                    </Button>
                </Row>
            ))}
        </div>
    );
};

Vertical.defaultProps = {
    buttonList: [],
};

export default Vertical;
