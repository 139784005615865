import { SET_LANGUAGE } from "./types";
import moment from "moment-timezone";

// set language
export const setLanguage = (language) => (dispatch) => {
    moment.locale(language);
    dispatch({
        type: SET_LANGUAGE,
        language,
    });
};
