import React, { useCallback, useState } from "react";
import userStyles from "../../../styles/UI/settings/TextSettingsStyles";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CloseIcon from "@material-ui/icons/Close";
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import Slider from "@material-ui/core/Slider";
import { ChromePicker } from "react-color";
import Divider from "@material-ui/core/Divider";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { translate } from "../../../utils/i18n";
import PropTypes from "prop-types";
import classnames from "classnames";

const FONT_FAMILY = [
    "Arial",
    "Times New Roman",
    "Helvetica",
    "Times",
    "Courier New",
    "Verdana",
    "Courier",
    "Arial Narrow",
    "Candara",
    "Calibri",
    "Optima",
    "Cambria",
    "Garamond",
    "Perpetua",
    "Monaco",
    "Didot",
    "Brush Script MT",
    "Lucida Bright",
    "Copperplate",
    "sans-serif",
    "cursive",
    "fantasy",
    "monospace",
];

const StylePopperSettings = (props) => {
    const classes = userStyles();
    const {
        text,
        setText,
        fontSize,
        setFontSize,
        currentColor,
        setCurrentColor,
        fontFamily,
        setFontFamily,
        top,
        setTop,
        left,
        setLeft,
        width,
        setWidth,
    } = props.settings;
    const [toggleList, setToggleList] = useState(false);
    const [toggleColorPicker, setToggleColorPicker] = useState(false);
    const open = Boolean(toggleList);
    const openColorPicker = Boolean(toggleColorPicker);
    const id = open ? "simple-popover" : undefined;
    const idColorPicker = openColorPicker
        ? "simple-popover-color-picker"
        : undefined;

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const propIsValid = (prop) => typeof prop != "undefined";
    const handleClose = () => setToggleList(null);

    const handleFontSizeChange = (event, newValue) => {
        setFontSize(newValue);
    };

    return (
        <>
            <div id={"overrideButtonText"} className={classes.buttonContainer}>
                <Button
                    aria-describedby={id}
                    onClick={(e) => setToggleList(e.currentTarget)}
                    style={{ fontFamily: fontFamily }}
                    className={classnames(classes.text, props.buttonStyle)}
                >
                    {text}
                </Button>
                {fontSize && (
                    <p className={classes.subTitle}>
                        {translate("Font Size")}:{" "}
                        <span className={classes.subTitle2}>{fontSize}rem</span>
                    </p>
                )}
                {currentColor && (
                    <>
                        <p className={classes.subTitle}>
                            {translate("Color")}:
                        </p>
                        <div
                            className={classes.colorPreview2}
                            style={{ backgroundColor: currentColor }}
                        />
                    </>
                )}
            </div>
            <Popover
                id={id}
                className={"overrideButtonText"}
                open={open}
                anchorEl={toggleList}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader
                            component="div"
                            id="nested-list-subheader"
                            className={classes.listSubHeader}
                        >
                            {propIsValid(props.header)
                                ? props.header
                                : translate("Text Settings")}
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </ListSubheader>
                    }
                    className={classes.list}
                >
                    {propIsValid(setText) && (
                        <>
                            <ListItem>
                                <ListItemText primary={translate("Text")} />
                                <TextField
                                    className={classes.currentText}
                                    id="standard-textarea"
                                    label="Text"
                                    value={text}
                                    multiline
                                    onChange={(e) => setText(e.target.value)}
                                />
                            </ListItem>
                            <Divider
                                variant="middle"
                                className={classes.divider}
                            />
                        </>
                    )}
                    {propIsValid(fontSize) && (
                        <>
                            <ListItem>
                                <ListItemText
                                    primary={translate("Font Size")}
                                />
                                <Slider
                                    // defaultValue={30}
                                    className={classes.slider}
                                    value={fontSize}
                                    getAriaValueText={(value) => `${value}rem`}
                                    valueLabelFormat={(value) => `${value}`}
                                    onChange={handleFontSizeChange}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    step={0.1}
                                    marks={[
                                        { value: 0.1, label: "0.1rem" },
                                        { value: 10, label: "10rem" },
                                    ]}
                                    min={0.1}
                                    max={10}
                                />
                            </ListItem>
                            <Divider
                                variant="middle"
                                className={classes.divider}
                            />
                        </>
                    )}
                    {propIsValid(currentColor) && (
                        <>
                            <ListItem>
                                <ListItemText primary={translate("Color")} />
                                <Button
                                    className={classes.listItem}
                                    aria-describedby={idColorPicker}
                                    onClick={(e) =>
                                        setToggleColorPicker(e.currentTarget)
                                    }
                                >
                                    <div
                                        className={classes.colorPreview}
                                        style={{
                                            backgroundColor:
                                                currentColor || "#000000",
                                        }}
                                    />
                                    <p className={classes.colorCode}>
                                        {currentColor}
                                    </p>
                                </Button>
                                <Popover
                                    id={idColorPicker}
                                    open={openColorPicker}
                                    anchorEl={toggleColorPicker}
                                    onClose={() => setToggleColorPicker(null)}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                >
                                    <ChromePicker
                                        color={currentColor || "#000000"}
                                        onChangeComplete={(newColor) =>
                                            setCurrentColor(newColor.hex)
                                        }
                                        className={classes.picker}
                                    />
                                </Popover>
                            </ListItem>
                            <Divider
                                variant="middle"
                                className={classes.divider}
                            />
                        </>
                    )}
                    {propIsValid(fontFamily) && (
                        <>
                            <ListItem>
                                <ListItemText primary={translate("Font")} />
                                <Autocomplete
                                    className={classes.fontSelector}
                                    id="combo-box-demo"
                                    options={FONT_FAMILY}
                                    getOptionLabel={(option) => option}
                                    style={{ width: 300 }}
                                    value={fontFamily}
                                    onChange={(event, newValue) => {
                                        setFontFamily(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate("Font Family")}
                                            placeholder={translate(
                                                "Select a font family"
                                            )}
                                        />
                                    )}
                                />
                            </ListItem>
                            <Divider
                                variant="middle"
                                className={classes.divider}
                            />
                        </>
                    )}

                    {propIsValid(width) && (
                        <>
                            <ListItem>
                                <ListItemText primary={translate("Width")} />
                                <Slider
                                    className={classes.slider}
                                    value={width}
                                    getAriaValueText={(value) => `${value}%`}
                                    valueLabelFormat={(value) => `${value}%`}
                                    onChange={(e, value) =>
                                        setWidth("width", value, forceUpdate)
                                    }
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    step={1}
                                    marks={[
                                        { value: 0, label: "0%" },
                                        { value: 50, label: "50%" },
                                        { value: 100, label: "100%" },
                                    ]}
                                    min={0}
                                    max={100}
                                />
                            </ListItem>
                            <Divider
                                variant="middle"
                                className={classes.divider}
                            />
                        </>
                    )}
                    {propIsValid(top) && (
                        <>
                            <ListItem>
                                <ListItemText primary={translate("Top")} />
                                <Slider
                                    className={classes.slider}
                                    value={top}
                                    getAriaValueText={(value) => `${value}%`}
                                    valueLabelFormat={(value) => `${value}%`}
                                    onChange={(e, value) =>
                                        setTop("top", value, forceUpdate)
                                    }
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    step={1}
                                    marks={[
                                        { value: -50, label: "-50%" },
                                        { value: 0, label: "0%" },
                                        { value: 50, label: "50%" },
                                    ]}
                                    min={-50}
                                    max={50}
                                />
                            </ListItem>
                            <Divider
                                variant="middle"
                                className={classes.divider}
                            />
                        </>
                    )}
                    {propIsValid(left) && (
                        <>
                            <ListItem>
                                <ListItemText primary={translate("Left")} />
                                <Slider
                                    className={classes.slider}
                                    value={left}
                                    getAriaValueText={(value) => `${value}%`}
                                    valueLabelFormat={(value) => `${value}%`}
                                    onChange={(e, value) =>
                                        setLeft("left", value, forceUpdate)
                                    }
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    step={1}
                                    marks={[
                                        { value: -50, label: "-50%" },
                                        { value: 0, label: "0%" },
                                        { value: 50, label: "50%" },
                                    ]}
                                    min={-50}
                                    max={50}
                                />
                            </ListItem>
                            <Divider
                                variant="middle"
                                className={classes.divider}
                            />
                        </>
                    )}
                </List>
            </Popover>
        </>
    );
};

StylePopperSettings.propTypes = {
    settings: PropTypes.object.isRequired,
};

export default StylePopperSettings;
