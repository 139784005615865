import PropTypes from "prop-types";
import moment from "moment-timezone";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useApplicationSettings } from "./settings/useApplicationSettings";
import { isVariableValid } from "../store/actions/chatActions";
import { pruneCache } from "../store/actions/localStorageActions";

/**
 * This hook is a timer, the sole purpose of this hook is to prevent memory leaks when app is run for a long period of time.
 * It an optimized version of "react-moment" component.
 *
 * HOOK INITIALIZATION:
 * @param {string} timeformat - Takes a valid moment time format e.g "HH:mm:ss"
 * @param {string} dateformat - Takes a valid moment date format e.g "ddd DD MMM YYYY"
 * @returns {object} {currentDate, currentTime] - return currentDate and currentTime variables
 *
 *
 * Usage:
 *      const {currentDate, currentTime} = useTimer("HH:mm:ss","ddd DD MMM YYYY")
 * */

const useTimer = (timeformat, dateformat) => {
    /**
     * Here we tapped into the state to access the current Language and timezone because that might change on runtime.
     * */
    const language = useSelector((state) => state.language.currentLanguage);
    const { timezone } = useApplicationSettings(["timezone"]);

    const getTimezone = useMemo(
        () => (isVariableValid(timezone) ? timezone.value : moment.tz.guess()),
        [timezone]
    );

    // reuseable function to return current time
    const getCurrentTime = useCallback(
        (format) => moment().tz(getTimezone).format(format),
        [timezone, language]
    );

    // utilize a ref to set and clear an interval
    const intervalRef = useRef();
    const intervalDateRef = useRef();

    // utilize state to initialize and update "currentTime"
    const [currentTime, setTime] = useState(getCurrentTime(timeformat));
    const [currentDate, setCurrentDate] = useState(getCurrentTime(dateformat));

    // create a 1000ms and 3600000ms setInterval timer to update "currentTime" and "currentDate".
    const startTimer = useCallback(() => {
        intervalRef.current = setInterval(() => {
            setTime(getCurrentTime(timeformat));
        }, 1000); // every second
        intervalDateRef.current = setInterval(() => {
            pruneCache();
            setCurrentDate(getCurrentTime(dateformat));
        }, 3600000); // every hour
    }, [getCurrentTime, intervalRef, intervalDateRef, timeformat, dateformat]);

    // during initial load start the timer
    // during unmount remove the interval
    useEffect(() => {
        if (!intervalRef.current && !intervalDateRef.current) {
            startTimer();
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = undefined;
            }
            if (intervalDateRef.current) {
                clearInterval(intervalDateRef.current);
                intervalDateRef.current = undefined;
            }
        };
    }, [intervalRef, startTimer]);

    /* if language or timezone changes then force update current date, don't wait default 1hr interval*/
    useEffect(() => {
        setCurrentDate(getCurrentTime(dateformat));
    }, [timezone, language]);

    return {
        currentDate,
        currentTime,
    };
};

useTimer.propTypes = {
    dateformat: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
};

export default useTimer;
