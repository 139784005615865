import { makeStyles } from "@material-ui/core/styles";
import { hexToRgb } from "../../material-dashboard-react.js";

const useStyles = makeStyles(({ primaryColor, secondaryColor }) => ({
    uploadContainer: {
        background: `rgba(${hexToRgb(secondaryColor)}, 0.25)`,
        padding: "1em",
        borderRadius: "10px",
    },
    dropzone: {
        height: "4em",
        background: primaryColor,
    },
    title: {
        fontSize: "1rem",
        fontWeight: "bold",
        width: "10%",
        color: primaryColor,
    },
    container: {
        marginTop: "1em",
        display: "flex",
        alignItems: "center",
        marginBottom: "1em",
    },
    container2: {
        marginTop: "1em",
        display: "flex",
        alignItems: "center",
        marginBottom: "1em",
        fontSize: "1.15rem",
    },
    upload: {
        width: "85%",
        marginLeft: "2em",
    },
    subContainer: {
        width: "85%",
    },
    divider: {
        marginTop: "3em !important",
        marginBottom: "3em !important",
    },
    avatarFlex: {
        display: "flex",
        alignItems: "center",
        marginLeft: "2.5em",
        width: "85%",
        flexDirection: "column",
    },
    avatarSubContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginTop: "1em",
    },
    subTitle: {
        fontWeight: "bold",
        color: `rgba(${hexToRgb(secondaryColor)}, 0.5)`,
        fontSize: "1.15rem",
    },
    subTitle3: {
        fontWeight: "bold",
        color: `rgba(${hexToRgb(secondaryColor)}, 0.5)`,
        fontSize: "1.15rem",
        width: "30%",
    },
    qualitySelect: {
        width: "25%",
        marginLeft: "1em",
    },
    qualitySelect2: {
        width: "35%",
        marginLeft: "1em",
    },
    backgroundSelectorContainer: {
        width: "50%",
        display: "flex",
        alignItems: "center",
        marginTop: "1em",
        marginLeft: "2.5em",
    },
    backgroundSelect: {
        width: "30em",
        marginLeft: "1em",
    },
    photoDelete: {
        marginLeft: "2em",
    },
    tabPanel: {
        height: "45em",
        overflowY: "auto",
    },
    backgroundMenuItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
    },
    menuItemImg: {
        width: "3.5em",
        borderRadius: "5px",
        marginLeft: "1em",
    },
    animationSelectionTypeContainer: {
        marginTop: "2em",
        width: "100%",
    },
    animationSelectionContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
    },
    subTitle2: {
        fontWeight: "bold",
        color: `rgba(${hexToRgb(secondaryColor)}, 0.5)`,
        marginLeft: "2em",
    },
    deleteAnimation: {
        marginLeft: "2em",
    },
    buttonContainer: {
        width: "100%",
        marginTop: "1.5em",
        marginBottom: "1.5em",
    },
    lastUpdatedContainer: {
        color: primaryColor,
        fontSize: "0.75rem",
        fontWeight: "bold",
        display: "flex",
        justifyContent: "flex-end",
        marginRight: "1em",
    },
    lastUpdated: {
        marginLeft: "0.25em",
    },
    themeContainer: {
        display: "flex",
        width: "80%",
        alignItems: "center",
        marginLeft: "2em",
    },
    stylePopperButton: {
        marginTop: "3em",
    },
    colorPreview: {
        marginLeft: "0.5em",
        borderRadius: "50%",
        width: "1.25em",
        height: "1.25em",
        "&:hover": {
            boxShadow:
                "0 7px 13px -6px rgba(153, 153, 153, 0.2), 0 2px 11.5px 0px rgba(0, 0, 0, 0.12), 0 4px 5px -2.5px rgba(153, 153, 153, 0.2)",
        },
    },
    welcomeText: {
        marginTop: "1.5em",
        textTransform: "capitalize",
        fontWeight: "bold",
        color: `rgba(${hexToRgb(secondaryColor)}, 0.5)`,
    },
    welcomeTextRoot: {
        width: "80%",
        // marginLeft: "1em",
    },
    welcomeTextSubContainer: {
        marginLeft: "2em",
        width: "15em",
        display: "inline-block",
    },
    welcomeTextContainer: {
        width: "87.5%",
    },
    "@global .uploadPictureContainer": {
        width: "15% !important",
    },
    // textParagraph:{
    //   display: "inline-block"
    // },
}));

export default useStyles;
