import React from "react";
import userStyles from "../../../styles/UI/layout/HeaderStyles";
import aimesoft_logo from "../../../images/aimesoft_logo.png";
import Select from "@material-ui/core/Select";
import { setLanguage } from "../../../store/actions/languageActions";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import {
    languageCodes,
    languageDisplay,
    languageIcons,
} from "../../../styles/constants";
import { Link } from "react-router-dom";
import "moment-timezone";
import { useAppearanceSettings } from "../../../hooks/settings/useAppearanceSettings";
import { useApplicationSettings } from "../../../hooks/settings/useApplicationSettings";
import { isVariableValid } from "../../../store/actions/chatActions";
import useTimer from "../../../hooks/useTimer";

// import aimesoft_logo from "../images/aimesoft_logo_resized.png"
const DEFAULT_TEXT_SETTINGS = { text: null, styles: {} };

const Header = () => {
    // return null
    const classes = userStyles();
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.currentLanguage);
    const {
        logo,
        title = DEFAULT_TEXT_SETTINGS,
        companyName = DEFAULT_TEXT_SETTINGS,
    } = useAppearanceSettings(["logo", "title", "companyName"]);
    const { supportedLanguages } = useApplicationSettings([
        "supportedLanguages",
    ]);
    const { currentDate, currentTime } = useTimer(
        "HH:mm:ss",
        "ddd DD MMM YYYY"
    );

    const renderSupportedLanguages = () => {
        if (
            isVariableValid(supportedLanguages) &&
            supportedLanguages.length > 0
        ) {
            return supportedLanguages.map((lang, index) => (
                <MenuItem value={languageCodes[lang]} key={index}>
                    {languageDisplay[lang]}{" "}
                    <img
                        src={languageIcons[lang]}
                        alt={`${lang} flag`}
                        className={classes.flag}
                    />
                </MenuItem>
            ));
        } else {
            return Object.keys(languageCodes).map((lang, index) => (
                <MenuItem value={languageCodes[lang]} key={index}>
                    {languageDisplay[lang]}{" "}
                    <img
                        src={languageIcons[lang]}
                        alt={`${lang} flag`}
                        className={classes.flag}
                    />
                </MenuItem>
            ));
        }
    };

    return (
        <div className={classes.root}>
            <Link to={"/"} className={classes.removeDefaultLink}>
                <img
                    src={logo || aimesoft_logo}
                    alt={"aimesoft logo"}
                    className={classes.logo}
                />
            </Link>
            <div className={classes.header}>
                <div className={classes.title} style={title.styles}>
                    {title.text || "AimeReception"}
                </div>
                <div className={classes.subTitle} style={companyName.styles}>
                    {companyName.text || "Aimesoft JSC"}
                </div>
            </div>
            <div className={classes.langSelect}>
                <div>
                    <Select
                        value={language}
                        onChange={(e) => dispatch(setLanguage(e.target.value))}
                        inputProps={{ "aria-label": "Without label" }}
                    >
                        {renderSupportedLanguages()}
                    </Select>
                </div>
                <div className={classes.timer}>{currentDate}</div>
                <div className={classes.timer2}>{currentTime}</div>
            </div>
        </div>
    );
};

export default Header;
