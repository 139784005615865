// @ts-check
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { env } from './env'
export function setupClientSentry() {
    Sentry.init({
        dsn: "https://ebba4fc60ff24e2eb13f5dffa8ad778e@o1267331.ingest.sentry.io/6453543",
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        environment: `${env.REACT_APP_CLIENT_NAME}_${env.NODE_ENV === "production" ? 'production' : 'local'}`
    });
}
