import { makeStyles } from "@material-ui/core/styles";
import sizes from "../sizes";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "50%",
        padding: "1em",
        paddingRight: "3%",
        [sizes.down("md2")]: {
            padding: "0",
            paddingRight: "2%",
        },
    },
    keyboard: {
        width: "8em",
        borderRadius: "7%",
        cursor: "pointer",
        "&:hover": {
            boxShadow:
                "0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
        },
        [sizes.down("lg")]: {
            width: "7em",
        },
        [sizes.down("md2")]: {
            width: "6em",
        },
    },
    micAndKeyboardContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        height: "fit-content",
        width: "100%",
        float: "right",
    },
    container: {
        height: "100%",
        width: "100%",
    },
    microphone: {
        // border: '2px solid black',
        // position: "absolute",
        zIndex: "1000",
        alignItems: "center",
        width: "5em",
        "&:hover": {
            cursor: "pointer",
        },
        [sizes.down("md2")]: {
            width: "3.5em",
        },
        // [sizes.down('md')]: {
        //     justifyContent: 'flex-end',
        // },
    },
    microphoneForInput: {
        display: "inline-block",
        marginRight: "1em",
        "&:hover": {
            cursor: "pointer",
        },
    },
    imgForInput: {
        width: "3em !important",
    },
    microphoneBlue: {
        width: "5em",
        borderRadius: "50%",
        transition:
            "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
        "&:hover": {
            boxShadow:
                "0 14px 26px -12px rgba(0, 142, 218, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 142, 218, 0.2)",
        },
        [sizes.down("lg")]: {
            width: "3.5em",
        },
        [sizes.down("md")]: {
            width: "4em",
        },
    },
    microphoneRed: {
        width: "5em",
        "&:hover": {
            boxShadow:
                "0 14px 26px -12px rgba(244, 67, 54, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 67, 54, 0.2)",
        },
        [sizes.down("md")]: {
            width: "4em",
        },
        [sizes.down("md2")]: {
            width: "3.5em",
        },
    },
    messages: {
        display: "block",
        marginTop: "0.5em",
        width: "90%",
        height: "60%",
        float: "right",
        // border: '2px solid black',
        overflowY: "auto",
    },

    chatMessageRight: {
        maxWidth: "85%",
        fontSize: "0.85rem !important",
        whiteSpace: "normal !important",
        textAlign: "start !important",
        textTransform: "none !important",
        marginRight: "1em !important",
        padding: ".93333rem 2rem !important",
        borderTopLeftRadius: "1.5rem  !important",
        borderTopRightRadius: ".29rem !important",
        borderRadius: "1.5rem !important",
        userSelect: "text !important",
        [sizes.down("sm")]: {
            fontSize: "0.75em !important",
            marginRight: "0 !important",
            maxWidth: "95%",
            padding: ".93333rem 1.2rem !important",
        },
    },
    chatMessageLeft: {
        maxWidth: "85%",
        fontSize: "0.85rem !important",
        textAlign: "start !important",
        whiteSpace: "normal !important",
        textTransform: "none !important",
        marginLeft: "1em !important",
        padding: ".93333rem 2rem !important",
        borderRadius: "1.5rem !important",
        borderTopLeftRadius: ".29rem !important",
        userSelect: "text !important",
        [sizes.down("sm")]: {
            fontSize: "0.75em !important",
            marginLeft: "0 !important",
            maxWidth: "95%",
            padding: ".93333rem 1.2rem !important",
        },
    },
    messageContainerRight: {
        marginTop: "1em",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
    },
    messageContainerLeft: {
        marginTop: "1em",
        width: "100%",
        display: "flex",
        justifyContent: "start",
    },
    inputContainer: {
        display: "flex",
        alignItems: "center",
    },
    loading: {
        marginRight: "0.5em",
    },
}));

export default useStyles;
