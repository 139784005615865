import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(({ primaryColor, secondaryColor }) => ({
    formControl: {
        marginBottom: "1em !important",
        width: "70%",
    },
    formContainer: {
        display: "flex",
        width: "100%",
        alignItems: "center",
    },
    dialogAction: {
        marginTop: "1em",
    },
    microphoneRed: {
        color: "red !important",
    },
    loading: {
        fill: `${primaryColor} !important`,
    },
    "@global #mic.MuiIconButton-root": {
        color: primaryColor,
    },
}));

export default useStyles;
