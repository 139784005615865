const { NODE_ENV, REACT_APP_CLIENT_NAME, REACT_APP_FALSE_COUNT, REACT_APP_REGISTRATION_DETECTION_INTERVAL, REACT_APP_DETECTION_INTERVAL } = process.env

const env = {
    NODE_ENV,
    REACT_APP_CLIENT_NAME,
    REACT_APP_FALSE_COUNT: parseInt(REACT_APP_FALSE_COUNT, 10),
    REACT_APP_REGISTRATION_DETECTION_INTERVAL: parseInt(REACT_APP_REGISTRATION_DETECTION_INTERVAL, 10),
    REACT_APP_DETECTION_INTERVAL: parseInt(REACT_APP_DETECTION_INTERVAL, 10),
}

module.exports = { env }
