import React, { useCallback, useEffect, useMemo, useState } from "react";
import HelpSelection from "../UI/HelpSelection";
import { translate } from "../../utils/i18n";
import MeetStaffModal from "../UI/modals/MeetStaffModal";
import ChatBotPluginModal from "../UI/modals/ChatBotPluginModal";
import { useDispatch, useSelector } from "react-redux";
import { speakNoChunk } from "../../store/actions/chatActions";
import {
    defaultVoiceResponses,
    defaultReceptionScreenVoice,
    MESSAGE_PROVIDER_FIELD_NAMES,
} from "../../styles/constants";
import { useApplicationSettings } from "../../hooks/settings/useApplicationSettings";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useLastLocation } from "react-router-last-location";
import FindMeetingModal from "../UI/modals/FindMeetingModal";
import { useAppearanceSettings } from "../../hooks/settings/useAppearanceSettings";
import { sendMessageWithProvider } from "../../store/actions/teamsActions";

const AimeReceptionPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const lastLocation = useLastLocation();
    const { isUserRecognized, userInfo } = useSelector((state) => state.user);
    const language = useSelector((state) => state.language.currentLanguage);
    const isGapiReady = useSelector((state) => state.chat.isGapiReady);
    const kbotButtonConfigs = useSelector(
        (state) => state.settings.kbotButtonConfigs
    );
    const {
        receptionScreenVoice = defaultReceptionScreenVoice,
        messageProviderConfig,
        messageProvider = "slack",
        voiceResponses = defaultVoiceResponses,
    } = useApplicationSettings([
        "receptionScreenVoice",
        "messageProviderConfig",
        "messageProvider",
        "voiceResponses",
    ]);
    const { map } = useAppearanceSettings(["map"]);

    const [isMeetStaffModalOpen, setIsMeetStaffModalOpen] = useState(false);
    const [isFloorGuidanceModalOpen, setIsFloorGuidanceModalOpen] =
        useState(false);
    const [isCompanyInformationModalOpen, setIsCompanyInformationModalOpen] =
        useState(false);
    const [isInterviewModalOpen, setIsInterviewModalOpen] = useState(false);
    const [isFindMeetingModalOpen, setIsFindMeetingModalOpen] = useState(false);
    const BOT_PLUGIN_URL = process.env.REACT_APP_BOT_PLUGIN;
    const messageProviderFieldNames = useMemo(
        () => MESSAGE_PROVIDER_FIELD_NAMES[messageProvider],
        [messageProvider]
    );

    const getVoiceResponse = useCallback(
        (response) => {
            if (isUserRecognized)
                return response?.known[language]?.replace(
                    "<name>",
                    userInfo.displayName
                );
            else return response?.unknown?.[language];
        },
        [userInfo, isUserRecognized, language]
    );

    useEffect(() => {
        if (
            lastLocation &&
            (lastLocation.pathname.includes("/reception/ask-bot/") ||
                lastLocation.pathname.includes("/reception/map"))
        )
            return; // do not speak if coming from ask-bot route
        dispatch(
            speakNoChunk(
                getVoiceResponse(receptionScreenVoice),
                language,
                isGapiReady
            )
        );
    }, []);

    const handlePackageDelivery = useCallback(() => {
        console.log(messageProviderConfig);
        dispatch(
            sendMessageWithProvider({
                message: messageProviderConfig.delivery.message,
                staffIdMessageProvider:
                    messageProviderConfig.delivery.staffs.map(
                        (mention) => mention[messageProviderFieldNames.staffId]
                    ),
                channel:
                    messageProviderConfig.delivery[
                        messageProviderFieldNames.channels
                    ],
                speakOnSuccess: getVoiceResponse(voiceResponses.delivery.after),
                language,
                isGapiReady,
                messageProvider,
            })
        );
    }, [
        messageProviderConfig,
        language,
        voiceResponses,
        messageProvider,
        getVoiceResponse,
        messageProviderFieldNames,
    ]);

    const handleMeetingStaff = useCallback(
        () => setIsMeetStaffModalOpen(true),
        []
    );
    const handleFindMeeting = useCallback(
        () => setIsFindMeetingModalOpen(true),
        []
    );
    const handleFloorGuidance = useCallback(
        () => setIsFloorGuidanceModalOpen(true),
        []
    );
    const handleCompanyInformation = useCallback(
        () => setIsCompanyInformationModalOpen(true),
        []
    );
    const handleInterview = useCallback(
        () => setIsInterviewModalOpen(true),
        []
    );

    const handleFindMeetingModalClose = useCallback(
        () => setIsFindMeetingModalOpen(false),
        []
    );
    const handleMeetStaffModalClose = useCallback(
        () => setIsMeetStaffModalOpen(false),
        []
    );
    const handleInterviewModalClose = useCallback(
        () => setIsInterviewModalOpen(false),
        []
    );
    const handleFloorGuidanceModalClose = useCallback(
        () => setIsFloorGuidanceModalOpen(false),
        []
    );
    const handleCompanyInformationModalClose = useCallback(
        () => setIsCompanyInformationModalOpen(false),
        []
    );

    const options = useMemo(() => {
        return [
            {
                text: translate("I have a meeting"),
                onClick: handleFindMeeting,
                speakOnClick: getVoiceResponse(
                    voiceResponses.meeting?.meeting?.before
                ),
            },
            {
                text: translate("I want to see someone"),
                onClick: handleMeetingStaff,
                speakOnClick: getVoiceResponse(
                    voiceResponses.meeting?.seeingSomeone?.before
                ),
            },
            // {
            //     text: translate("Floor guidance"),
            //     onClick: handleFloorGuidance
            // },
            // {
            //     text: translate("Company Information"),
            //     onClick: handleCompanyInformation
            // },
            {
                text: translate("I have an interview"),
                onClick: handleInterview,
                speakOnClick: getVoiceResponse(
                    voiceResponses?.interview?.before
                ),
            },
            {
                text: translate("Mail/package delivery"),
                onClick: handlePackageDelivery,
            },
            map?.maps?.length > 0
                ? {
                      text: translate("Map"),
                      onClick: () => {
                          history.push("/reception/map");
                      },
                  }
                : null,
            ...(!(kbotButtonConfigs instanceof Array)
                ? []
                : kbotButtonConfigs.map((button) => {
                      return {
                          text: button.label[language.split("-").shift()],
                          onClick: () => {
                              history.push("/reception/ask-bot/test", {
                                  ...button,
                              });
                          },
                      };
                  })),
        ].filter((obj) => !!obj);
    }, [
        language,
        messageProviderConfig,
        kbotButtonConfigs,
        voiceResponses,
        getVoiceResponse,
        map,
    ]);

    return (
        <>
            {/* {console.log("============================>", options)} */}
            <HelpSelection
                title={translate("How can I help you?")}
                info={translate(
                    "Not in the above list? Press the microphone button or keyboard button on the right hand side and tell us your enquiry."
                )}
                options={options}
            />
            <FindMeetingModal
                handleClose={handleFindMeetingModalClose}
                open={isFindMeetingModalOpen}
            />
            <MeetStaffModal
                open={isMeetStaffModalOpen}
                showStaffList
                handleClose={handleMeetStaffModalClose}
            />
            <MeetStaffModal
                showStaffList={false}
                type={"interview"}
                open={isInterviewModalOpen}
                handleClose={handleInterviewModalClose}
            />
            <ChatBotPluginModal
                open={isFloorGuidanceModalOpen}
                handleClose={handleFloorGuidanceModalClose}
                url={`${BOT_PLUGIN_URL}/chat_plugin_v6?lang=ja&command=floor_guidance_${language
                    .split("-")
                    .shift()}`}
                language={language}
            />
            <ChatBotPluginModal
                open={isCompanyInformationModalOpen}
                handleClose={handleCompanyInformationModalClose}
                url={`${BOT_PLUGIN_URL}/chat_plugin_v6?lang=ja&command=company_info_${language
                    .split("-")
                    .shift()}`}
                language={language}
            />
        </>
    );
};

AimeReceptionPage.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
};

export default AimeReceptionPage;
