import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(({ primaryColor, secondaryColor }) => ({
    icon: {
        width: "2em",
    },
    loading: {
        fill: `${primaryColor} !important`,
    },
}));

export default useStyles;
