import React, { useCallback, useEffect, useMemo, useState } from "react";
import userStyles from "../../../styles/UI/modals/MeetStaffModalStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
    Button,
    FormControl,
    InputLabel,
    FormHelperText,
    Select,
    MenuItem,
    Input,
} from "@material-ui/core";
import { translate } from "../../../utils/i18n";
import { useDispatch, useSelector } from "react-redux";
import FormGroup from "@material-ui/core/FormGroup";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import MicIcon from "@material-ui/icons/Mic";
import classnames from "classnames";
import { addToMessages } from "../../../store/actions/chatActions";
import { useSpeechRecognitionManager } from "../../../hooks/useSpeechRecognitionManager";
import { addToNotifications } from "../../../store/actions/notificationActions";
import {
    defaultVoiceResponses,
    MESSAGE_PROVIDER_FIELD_NAMES,
    slack,
    teams,
} from "../../../styles/constants";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useApplicationSettings } from "../../../hooks/settings/useApplicationSettings";
import { Transition } from "../settings/Settings";
import { deepValue } from "../settings/VoiceSettingsPopper";
import { sendMessageWithProvider } from "../../../store/actions/teamsActions";
import ReactLoading from "react-loading";

const DEFAULT_MENTIONS = {
    slack: slack.DEFAULT_MENTIONS,
    teams: teams.DEFAULT_MENTIONS,
};

const MeetStaffModal = (props) => {
    const classes = userStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        open,
        handleClose,
        type,
        addSuccessMessageToChat,
        showStaffList,
        addToDescription,
        initialForm = {},
    } = props;
    const {
        messageProvider = "slack",
        messageProviderConfig: config,
        voiceResponses = defaultVoiceResponses,
    } = useApplicationSettings([
        "messageProviderConfig",
        "messageProvider",
        "voiceResponses",
    ]);
    const { isUserRecognized, userInfo } = useSelector((state) => state.user);
    const [defaultMentions, setDefaultMentions] = useState(
        DEFAULT_MENTIONS[messageProvider]
    );
    const isGapiReady = useSelector((state) => state.chat.isGapiReady);
    const language = useSelector((state) => state.language.currentLanguage);

    const [form, setForm] = useState({});
    const [transcript, isRecording, handleToggle, resetTranscript] =
        useSpeechRecognitionManager(null, false, true);
    const typeStripped = useMemo(() => type.split(".")[0], [type]);
    const messageProviderFieldNames = useMemo(
        () => MESSAGE_PROVIDER_FIELD_NAMES[messageProvider],
        [messageProvider]
    );
    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const propIsValid = (prop) => (typeof prop != "undefined" ? prop : false);

    useEffect(() => {
        if (transcript.length > 0)
            setForm({
                ...form,
                customerName: transcript,
            });
    }, [transcript]);

    useEffect(() => {
        let staffIdMessageProvider = "";
        if (open) {
            if (
                propIsValid(props.defaultMentions) &&
                props.defaultMentions.length > 0
            ) {
                // if default list is overidden
                if (!!isIdInDefaultMention(props.defaultMentions)) {
                    // name in default list
                    staffIdMessageProvider =
                        props.defaultMentions[0]?.[
                            messageProviderFieldNames.staffId
                        ];
                    setDefaultMentions(config[typeStripped].staffs);
                } else {
                    setDefaultMentions(props.defaultMentions);
                }
            } else setDefaultMentions(config[typeStripped].staffs);
            setForm({
                staffIdMessageProvider: staffIdMessageProvider,
                customerName: userInfo?.displayName || initialForm.name || "",
            });
        }
    }, [open]);

    const isIdInDefaultMention = (mentions) =>
        config[typeStripped].staffs.find(
            (mention) =>
                mention[messageProviderFieldNames.staffId] ===
                mentions[0].slack_id
        );

    const handleSlackMessage = (normalSlackMessage) => {
        if (propIsValid(props.customSlackMessage))
            return props.customSlackMessage.replace(
                "<name>",
                form["customerName"]
            );
        else return normalSlackMessage.replace("<name>", form["customerName"]);
    };

    const handleSpeakOnSuccess = (normalSuccessMessage) => {
        if (
            propIsValid(props.speakOnUnMount) &&
            props.speakOnUnMount.length > 0
        ) {
            if (addSuccessMessageToChat)
                dispatch(addToMessages(getVoiceResponse(props.speakOnUnMount)));
            return props.speakOnUnMount;
        } else return normalSuccessMessage;
    };

    const setFormValue = (field) => (event) => {
        setForm({
            ...form,
            [field]: event.target.value,
        });
    };

    const getVoiceResponse = useCallback(
        (response) => {
            if (isUserRecognized) {
                const res =
                    typeof response === "string"
                        ? response
                        : response?.known?.[language];
                return res?.replace("<name>", form["customerName"] || "");
            } else {
                const res =
                    typeof response === "string"
                        ? response
                        : response?.unknown?.[language];
                return res?.replace("<name>", form["customerName"] || "");
            }
        },
        [language, form, isUserRecognized]
    );

    const handleMeetingStaff = async (clearForm) => {
        if (!form["customerName"] || !form["staffIdMessageProvider"])
            return dispatch(
                addToNotifications({
                    type: "ERROR",
                    message: translate(
                        "Please fill in the form to request a meeting!"
                    ),
                    size: "sm",
                })
            );
        await dispatch(
            sendMessageWithProvider({
                message: handleSlackMessage(config[typeStripped].message),
                staffIdMessageProvider: form["staffIdMessageProvider"],
                channel:
                    config[typeStripped][messageProviderFieldNames.channels],
                speakOnSuccess: getVoiceResponse(
                    handleSpeakOnSuccess(deepValue(voiceResponses, type)?.after)
                ),
                language,
                isGapiReady,
                messageProvider,
            })
        );
        handleClose();
        clearForm && setForm({});
    };

    const handleMeetingFixedMentions = async (clearForm) => {
        if (!form["customerName"])
            return dispatch(
                addToNotifications({
                    type: "ERROR",
                    message: translate(
                        "Please fill in the form to request a meeting!"
                    ),
                    size: "sm",
                })
            );
        await dispatch(
            sendMessageWithProvider({
                message: handleSlackMessage(config[typeStripped].message),
                staffIdMessageProvider: getStaffsForFixedMention(),
                channel:
                    config[typeStripped][messageProviderFieldNames.channels],
                speakOnSuccess: getVoiceResponse(
                    handleSpeakOnSuccess(deepValue(voiceResponses, type)?.after)
                ),
                language,
                isGapiReady,
                messageProvider,
            })
        );
        handleClose();
        clearForm && setForm({});
    };
    const getStaffsForFixedMention = () => {
        if (
            propIsValid(props.defaultMentions) &&
            props.defaultMentions.length > 0
        )
            return props.defaultMentions.map(
                (mention) =>
                    mention[MESSAGE_PROVIDER_FIELD_NAMES.slack.staffId] ||
                    mention[MESSAGE_PROVIDER_FIELD_NAMES.teams.staffId]
            );
        else
            return defaultMentions.map(
                (mention) =>
                    mention[MESSAGE_PROVIDER_FIELD_NAMES.slack.staffId] ||
                    mention[MESSAGE_PROVIDER_FIELD_NAMES.teams.staffId]
            );
    };

    const handleSubmit = async (clearForm = true) => {
        setIsSendingMessage(true);
        if (showStaffList) await handleMeetingStaff(clearForm);
        else await handleMeetingFixedMentions(clearForm);
        setIsSendingMessage(false);
    };

    const handleSubmitAndRegister = async () => {
        await handleSubmit(false);
        if (!isUserRecognized) {
            // if user is not recognized
            if (showStaffList)
                if (!form["customerName"]) return;
                else if (
                    !form["customerName"] ||
                    !form["staffIdMessageProvider"]
                )
                    return;

            const state = {
                firstName: form["customerName"],
                lastName: "",
                displayName: form["customerName"],
                role: "guest",
                redirectTo: "/reception",
                showRegistrationModal: false,
            };
            setForm({});
            history.push({
                pathname: "/face-registration",
                state,
            });
        } else {
            setForm({});
            dispatch(
                addToNotifications({
                    type: "INFO",
                    message: translate("You are already registered!"),
                    size: "md",
                })
            );
        }
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            fullWidth={true}
            maxWidth={"sm"}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">
                {translate("Contact a staff")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {addToDescription.length > 0 && addToDescription}
                </DialogContentText>
                <DialogContentText id="alert-dialog-slide-description">
                    {translate("Enter your name to notify the staff")}
                </DialogContentText>
                <FormGroup className={classes.formContainer}>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="customerName">
                            {translate("Your name *")}
                        </InputLabel>
                        <Input
                            id="customerName"
                            onChange={setFormValue("customerName")}
                            value={form["customerName"] || ""}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        id={"mic"}
                                        aria-label="toggle mic"
                                        onClick={handleToggle}
                                        className={classnames({
                                            [classes.microphoneRed]:
                                                isRecording,
                                        })}
                                        edge="end"
                                    >
                                        <MicIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <FormHelperText />
                    </FormControl>
                    {showStaffList && (
                        <FormControl className={classes.formControl}>
                            <InputLabel
                            // htmlFor="staff"
                            >
                                {translate("Staff *")}
                            </InputLabel>
                            <Select
                                id="staff"
                                onChange={setFormValue(
                                    "staffIdMessageProvider"
                                )}
                                value={form["staffIdMessageProvider"] || ""}
                            >
                                {!!defaultMentions &&
                                    defaultMentions.map((mention, index) => (
                                        <MenuItem
                                            value={
                                                messageProvider === "slack"
                                                    ? mention[
                                                          MESSAGE_PROVIDER_FIELD_NAMES
                                                              .slack.staffId
                                                      ]
                                                    : mention[
                                                          MESSAGE_PROVIDER_FIELD_NAMES
                                                              .teams.staffId
                                                      ]
                                            }
                                            key={index}
                                        >
                                            {mention.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                            <FormHelperText />
                        </FormControl>
                    )}
                </FormGroup>
                <DialogActions className={classes.dialogAction}>
                    <Button onClick={handleClose} color="primary">
                        {translate("Cancel")}
                    </Button>
                    {isSendingMessage ? (
                        <Button onClick={() => {}} color="primary" disabled>
                            <ReactLoading
                                type={"balls"}
                                width={"40%"}
                                height={"40%"}
                                className={classes.loading}
                            />
                        </Button>
                    ) : (
                        <>
                            <Button
                                onClick={handleSubmitAndRegister}
                                color="primary"
                            >
                                {translate("Submit and remember my face")}
                            </Button>
                            <Button
                                onClick={handleSubmit}
                                color="primary"
                                variant={"contained"}
                            >
                                {translate("Submit")}
                            </Button>
                        </>
                    )}
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

MeetStaffModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    defaultMentions: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.oneOf(["meeting", "interview"]),
    showStaffList: PropTypes.bool,
    customSlackMessage: PropTypes.string,
    speakOnUnMount: PropTypes.string,
    addSuccessMessageToChat: PropTypes.bool,
    addToDescription: PropTypes.string,
};

MeetStaffModal.defaultProps = {
    type: "meeting.seeingSomeone",
    showStaffList: true,
    addSuccessMessageToChat: false,
    addToDescription: "",
};
export default MeetStaffModal;
