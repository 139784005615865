import React, { memo, Suspense, useEffect, useRef, useState } from "react";
import userStyles from "../../styles/UI/ReceptionistStyles";
import classnames from "classnames";
// import aime_idle from "../Videos/idle/idle4.gif"
// import aime_idle from "../../Videos/aime-idle.gif"
import aime_idle from "../../Videos/idle/idle_minimized.gif";
// import aime_idle from "../Videos/idle/idle_minimized2.webm"
// import aime_idle from "../Videos/idle/idle_minimized.mp4"
// import aime_idle from "../Videos/idle/idle_minimized.gif"
// import aime_talk from "../Videos/talk/talk_minimized.webm"
import aime_talk from "../../Videos/talk/talk_minimized2.gif";
// import aime_bow from "../../Videos/aime-bow.gif"
import aime_bow from "../../Videos/bow/bow_minimized_loop.gif";
// import aime_bow from "../Videos/bow/bow_minimized.webm"
import { useDispatch, useSelector } from "react-redux";
import {
    freezeGif,
    setReceptionist,
} from "../../store/actions/receptionistActions";
// import {freezeGif, setReceptionist} from "../../store/actions/receptionistActions";
import { isVariableValid, resetChat } from "../../store/actions/chatActions";
import {
    setHasBowed,
    setIsUserFalseCount,
} from "../../store/actions/faceCamActions";
import { clearUser } from "../../store/actions/userActions";
import { SuspenseImg } from "./SuspenseImg";
import { Spin } from "antd";
import { useAppearanceSettings } from "../../hooks/settings/useAppearanceSettings";
import PropTypes from "prop-types";
import { selectUserFalseCount } from "../../store/selectors/faceCamSelectors";

const uploadURL = "/api/v1/uploads/";

const buildStyles = (obj) => {
    const newObj = { ...obj };
    const objKeys = Object.keys(obj);
    Object.values(obj).forEach(
        (val, index) => (newObj[objKeys[index]] = `${val}%`)
    );
    if (newObj["top"] === 0)
        // delete top if = 0. because to is not used in default styles
        delete newObj["top"];
    return newObj;
};

const Receptionist = ({ history }) => {
    // return null
    const classes = userStyles();
    const dispatch = useDispatch();
    const { avatar = null } = useAppearanceSettings(["avatar"]);
    const receptionist_state = useSelector(
        (state) => state.receptionist.receptionist_state
    );
    const isUser = useSelector((state) => state.faceCam.is_user);
    const isUserFalseCount = useSelector(selectUserFalseCount);
    const hasBowed = useSelector((state) => state.faceCam.hasBowed);
    const messages = useSelector((state) => state.chat.messages);
    const bowImg = useRef(null);
    const idleImg = useRef(null);
    const talkImg = useRef(null);

    const FALSE_COUNT = parseInt(process.env.REACT_APP_FALSE_COUNT);
    // const DETECTION_INTERVAL = parseInt(process.env.REACT_APP_DETECTION_INTERVAL)
    const RESET_COUNT = parseInt(process.env.REACT_APP_RESET_COUNT);

    const [animation, setAnimation] = useState({
        bow: aime_bow,
        idle: aime_idle,
        talk: aime_talk,
        bowStyles: {},
        idleStyles: {},
        talkStyles: {},
    });

    useEffect(() => {
        if (messages.length > 0 && messages[messages.length - 1].isReceiver) {
            dispatch(setIsUserFalseCount(0, false));
        }
    }, [messages]);

    useEffect(() => {
        if (isUser && !hasBowed) {
            dispatch(setReceptionist("BOW"));
            dispatch(setHasBowed(true));
            setTimeout(() => dispatch(setReceptionist("IDLE")), 3000);
        } else if (!isUser && isUserFalseCount >= FALSE_COUNT) {
            if (isUserFalseCount >= RESET_COUNT) {
                dispatch(setHasBowed(false));
                dispatch(resetChat());
                history.location.pathname !== "/" && history.push("/");
            }
            dispatch(clearUser());
        }
    }, [isUserFalseCount, dispatch, isUser, hasBowed]);

    useEffect(() => {
        const timer = setAnimations();
        return () => clearTimeout(timer);
    }, [avatar]);

    const showAvatar = () => (isVariableValid(avatar) ? avatar.status : false);
    const setAnimations = () => {
        let timer = null;
        if (avatar !== null) {
            const {
                bow: bow_,
                idle: idle_,
                talk: talk_,
                bowStyles,
                idleStyles,
                talkStyles,
            } = getSelectedAnimation();
            const bow = bow_ || aime_bow;

            const idle = idle_ || aime_idle;

            const talk = talk_ || aime_talk;

            setAnimation({
                bow,
                idle,
                talk,
                bowStyles,
                idleStyles,
                talkStyles,
            });
            setTimeout(() => {
                if (!avatar.animation) {
                    freezeGif(bowImg.current);
                    freezeGif(idleImg.current);
                    freezeGif(talkImg.current);
                }
            }, 500);
        } else timer = setTimeout(setAnimations, 1000);
        return timer;
    };

    const getSelectedAnimation = () => {
        if (avatar !== null && avatar.receptionistAnimations.length > 0) {
            const selectedAnimation = avatar.selectedAnimation;
            const animation = avatar.receptionistAnimations.find(
                (animation) => animation.name === selectedAnimation
            );
            if (animation !== undefined) {
                const bow_ = animation.animation.find(
                    (animation_) =>
                        animation_.fileName === animation.selectedBowAnimation
                );
                const idle_ = animation.animation.find(
                    (animation_) =>
                        animation_.fileName === animation.selectedIdleAnimation
                );
                const talk_ = animation.animation.find(
                    (animation_) =>
                        animation_.fileName === animation.selectedTalkAnimation
                );
                return {
                    bow:
                        bow_ !== undefined
                            ? `${uploadURL}${bow_.fileId}`
                            : null,
                    idle:
                        idle_ !== undefined
                            ? `${uploadURL}${idle_.fileId}`
                            : null,
                    talk:
                        talk_ !== undefined
                            ? `${uploadURL}${talk_.fileId}`
                            : null,
                    bowStyles: buildStyles(
                        animation.selectedBowAnimationStyles
                    ),
                    idleStyles: buildStyles(
                        animation.selectedIdleAnimationStyles
                    ),
                    talkStyles: buildStyles(
                        animation.selectedTalkAnimationStyles
                    ),
                };
            }
        }
        return null;
    };

    return (
        <Suspense fallback={<Spin />}>
            <div className={classes.root}>
                <div style={{ display: showAvatar() ? "block" : "none" }}>
                    <div
                        className={classnames(classes.receptionistContainer, {
                            [classes.flex]: receptionist_state === "TALK",
                        })}
                    >
                        <SuspenseImg
                            childRef={talkImg}
                            src={animation.talk}
                            alt="Receptionist"
                            className={classes.receptionist}
                            style={animation.talkStyles}
                        />
                        {/*<FastImageImage  ref={talkImg} src={animation.talk} alt="Receptionist" className={classes.receptionist}/>*/}
                    </div>
                    <div
                        className={classnames(classes.receptionistContainer, {
                            [classes.flex]: receptionist_state === "BOW",
                        })}
                    >
                        <SuspenseImg
                            src={animation.bow}
                            childRef={bowImg}
                            alt="Receptionist"
                            className={classes.receptionist}
                            style={animation.bowStyles}
                        />
                    </div>
                    <div
                        className={classnames(classes.receptionistContainer, {
                            [classes.flex]: receptionist_state === "IDLE",
                        })}
                    >
                        <SuspenseImg
                            childRef={idleImg}
                            src={animation.idle}
                            alt="Receptionist"
                            className={classes.receptionist}
                            style={animation.idleStyles}
                        />
                        {/*<FastImageImage ref={idleImg} src={animation.idle} alt="Receptionist" className={classes.receptionist}/>*/}
                    </div>
                </div>
            </div>
        </Suspense>
    );
};

Receptionist.propTypes = {
    history: PropTypes.object.isRequired,
};

export default memo(Receptionist);
