import { useLayoutEffect, useState } from "react";

/**
 * This hook returns the current window height and width.
 *
 * HOOK INITIALIZATION:
 * @returns {array} [width, height] - return width and height
 *
 *
 * Usage:
 *      const [width, height] = useWindowSize()
 *
 * */

export const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        const updateSize = () => {
            setSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
};
