import React, { useCallback } from "react";
import userStyles from "../../../styles/UI/modals/DetectionInfoModalStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Progress } from "antd";
import happy from "../../../images/happy.svg";
import sad from "../../../images/sad.svg";
import angry from "../../../images/angry.svg";
import fearful from "../../../images/fearful.svg";
import disgusted from "../../../images/disgust.svg";
import surprised from "../../../images/surprised.svg";
import neutral from "../../../images/neutral.svg";
import male from "../../../images/male.svg";
import female from "../../../images/female.svg";
import { translate } from "../../../utils/i18n";
import PropTypes from "prop-types";
import { Transition } from "../settings/Settings";

const propIsValid = (prop) => (typeof prop != "undefined" ? prop : false);

const DetectionInfoModal = (props) => {
    const classes = userStyles();
    const { open, handleClose, detection } = props;

    const getImage = (expression) => {
        switch (expression) {
            case "neutral":
                return neutral;
            case "happy":
                return happy;
            case "sad":
                return sad;
            case "angry":
                return angry;
            case "fearful":
                return fearful;
            case "disgusted":
                return disgusted;
            case "surprised":
                return surprised;
            default:
                return neutral;
        }
    };

    const calculateGenderPercentage = useCallback(
        (type) => {
            return detection.gender === type
                ? parseFloat((detection.genderProbability * 100).toFixed(2))
                : parseFloat(
                      (100 - detection.genderProbability * 100).toFixed(2)
                  );
        },
        [detection]
    );

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted={false}
            onClose={handleClose}
            fullWidth={true}
            // maxWidth={'sm'}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">
                {translate("Detection Details")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {translate("Additional information on detection")}
                </DialogContentText>
                {propIsValid(detection) && (
                    <>
                        <p className={classes.title}>
                            {translate("Detection score")}
                        </p>
                        <Progress
                            percent={parseFloat(
                                (detection.detection._score * 100).toFixed(2)
                            )}
                            className={classes.progress}
                        />
                        <p className={classes.age}>
                            {translate("Age")}{" "}
                            <span className={classes.ageText}>
                                {parseFloat(detection.age.toFixed(2))}
                            </span>{" "}
                        </p>

                        <div>
                            <p className={classes.title}>
                                {translate("Gender")}
                            </p>
                            <div className={classes.container}>
                                <p>
                                    <img
                                        src={male}
                                        alt={"Male"}
                                        className={classes.image}
                                    />
                                    <span className={classes.text}>
                                        {translate("Male")}
                                    </span>
                                </p>
                                <Progress
                                    percent={calculateGenderPercentage("male")}
                                />
                                <p>
                                    <img
                                        src={female}
                                        alt={"Female"}
                                        className={classes.image}
                                    />
                                    <span className={classes.text}>
                                        {translate("Female")}{" "}
                                    </span>
                                </p>
                                <Progress
                                    percent={calculateGenderPercentage(
                                        "female"
                                    )}
                                />
                            </div>
                        </div>
                        <div>
                            <p className={classes.title}>
                                {translate("Emotions")}
                            </p>
                            {Object.entries(detection.expressions).map(
                                (expression, index) => (
                                    <div
                                        key={index}
                                        className={classes.container}
                                    >
                                        <p
                                            className={
                                                classes.expressionSubContainer
                                            }
                                        >
                                            <img
                                                src={getImage(expression[0])}
                                                alt={expression[0]}
                                                className={classes.image}
                                            />
                                            <span className={classes.text}>
                                                {translate(
                                                    expression[0].capitalize()
                                                )}
                                            </span>
                                        </p>
                                        <Progress
                                            percent={parseFloat(
                                                (expression[1] * 100).toFixed(2)
                                            )}
                                            status="active"
                                        />
                                    </div>
                                )
                            )}
                        </div>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};

DetectionInfoModal.propTypes = {
    detection: PropTypes.object,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default DetectionInfoModal;
