import React from "react";
import userStyles from "../../styles/UI/LangSelectionStyles";
import Button from "../Customized Libraries/CustomButtons/Button";
import { useDispatch } from "react-redux";
import { setLanguage } from "../../store/actions/languageActions";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const LangSelection = (props) => {
    const classes = userStyles();
    const dispatch = useDispatch();
    const { titles, languages, style, ...other } = props;
    const propIsValid = (prop) => (typeof prop != "undefined" ? prop : false);

    return (
        <div className={classes.root} {...other}>
            <div className={classes.langTitleContainer}>
                {propIsValid(titles) &&
                    titles.map((title, index) => (
                        <div
                            className={classes.langTitle}
                            key={`${index}222q`}
                            style={style}
                        >
                            {title}
                        </div>
                    ))}
            </div>

            <Grid
                container
                spacing={2}
                alignItems={"center"}
                justifyContent={"flex-start"}
                className={classes.grid}
                id={"langContent"}
            >
                {propIsValid(languages) &&
                    languages.map((language, index) => (
                        <Grid
                            item
                            lg={2}
                            className={classes.gridItem}
                            key={index}
                        >
                            <Link
                                to={"/reception"}
                                className={classes.removeDefaultLink}
                                onClick={() =>
                                    dispatch(setLanguage(language.code))
                                }
                            >
                                <Button
                                    color={"secondaryButtonTheme"}
                                    size={"lg"}
                                    className={classes.language}
                                >
                                    {language.text}
                                </Button>
                            </Link>
                        </Grid>
                    ))}
            </Grid>
        </div>
    );
};
LangSelection.propTypes = {
    titles: PropTypes.array.isRequired,
    languages: PropTypes.array.isRequired,
    style: PropTypes.object,
};

export default LangSelection;
