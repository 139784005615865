(function () {
    const indexedDB =
        window.indexedDB ||
        window.mozIndexedDB ||
        window.webkitIndexedDB ||
        window.msIndexedDB;
    let db,
        keyValue = {
            k: "",
            v: "",
        },
        request;
    const startUpDB = () =>
        new Promise((resolve, reject) => {
            if (!indexedDB) {
                console.error("indexDB not supported");
                return;
            }

            request = indexedDB.open("d2", 1);
            request.onsuccess = function (evt) {
                console.log(`success`);
                db = this.result;
                db.onversionchange = function (event) {
                    event.target.close();
                };
                resolve();
            };
            request.onerror = function (event) {
                console.error("indexedDB request error");
                console.log(event);
            };

            request.onupgradeneeded = function (event) {
                db = null;
                let store = event.target.result.createObjectStore("str", {
                    keyPath: "k",
                });

                store.transaction.oncomplete = function (e) {
                    db = e.target.db;
                };
            };
        });
    startUpDB();

    const deleteDB = () =>
        new Promise((resolve, reject) => {
            const DBDeleteRequest = window.indexedDB.deleteDatabase("d2");

            DBDeleteRequest.onerror = function (event) {
                console.log("Error deleting database.");
                resolve(false);
            };

            DBDeleteRequest.onsuccess = async (event) => {
                console.log("Database deleted successfully");
                // event.result should be undefined
                await startUpDB();
                resolve(event.result === undefined);
            };

            DBDeleteRequest.onblocked = (event) => {
                db.close();
                const DBDeleteRequest = window.indexedDB.deleteDatabase("d2");
                DBDeleteRequest.onsuccess = async (event) => {
                    console.log("Database deleted successfully");
                    // event.result should be undefined
                    await startUpDB();
                    resolve(event.result === undefined);
                };
            };
        });

    const getValue = (key) =>
        new Promise((resolve, reject) => {
            try {
                const request = db
                    .transaction("str")
                    .objectStore("str")
                    .get(key);
                request.onsuccess = function (event) {
                    const result =
                        (event.target.result && event.target.result.v) || null;
                    resolve(result);
                };
                request.onerror = (e) => resolve(null);
            } catch (e) {
                // console.log(e);
                if (e.name === "InvalidStateError") {
                    console.log(`DB connection lost, try reloading the page`);
                    resolve(null);
                } else resolve(null);
            }
        });

    const setValue = (key, value) =>
        new Promise(async (resolve, reject) => {
            try {
                // no callback for set needed because every next transaction will be anyway executed after this one
                keyValue.k = key;
                keyValue.v = value;
                const tx = db
                    .transaction("str", "readwrite")
                    .objectStore("str")
                    .put(keyValue);
                tx.onsuccess = function () {
                    resolve();
                };
                tx.onerror = (e) => resolve(null);
            } catch (e) {
                console.log(e);
                if (e.name === "InvalidStateError") {
                    console.log(`DB connection lost, starting up db again`);
                    resolve();
                } else resolve(null);
            }
        });

    const deleteValue = (key) =>
        new Promise(async (resolve, reject) => {
            try {
                // no callback for set needed because every next transaction will be anyway executed after this one
                const tx = db
                    .transaction("str", "readwrite")
                    .objectStore("str");
                const objRequest = tx.delete(key);
                objRequest.onsuccess = (e) => resolve();
                objRequest.onerror = (e) => resolve();
            } catch (e) {
                // console.log(e);
                if (e.name === "InvalidStateError") {
                    console.log(`DB connection lost, starting up db again`);
                    resolve();
                } else resolve(null);
            }
        });

    // if using proxy mode comment this

    window["ldb"] = {
        get: getValue,
        set: setValue,
        delete: deleteValue,
        clear: deleteDB,
        getDBs: () => indexedDB.databases(),
    };

    // Use only for apps that will only work on latest devices only

    // window.ldb = new Proxy({}, {
    //     get: (func, key, callback) => {
    //         return (key === 'get') ? getValue : function (callback) {
    //             return getValue(key, callback)
    //         };
    //     },
    //     set: (func, key, value) => {
    //         keyValue.k = key;
    //         keyValue.v = value;
    //         db.transaction('str', 'readwrite').objectStore('str').put(keyValue);
    //     },
    //     clear: deleteDB,
    //     getDBs: () => indexedDB.databases()
    // });
})();
