import { useEffect, useMemo, useState } from "react";
import { colors } from "../../../styles/constants";
import { useSelector } from "react-redux";

const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
};

export const useThemeSettings = (
    dbField,
    tabIndex,
    settingsRef,
    tabRef,
    isModalOpen
) => {
    const [primaryColor, setPrimaryColor] = useState(colors.primaryColor);
    const [togglePrimaryColorPicker, setTogglePrimaryColorPicker] =
        useState(false);
    const openPrimaryColorPicker = Boolean(togglePrimaryColorPicker);
    const idPrimaryColorPicker = openPrimaryColorPicker
        ? "simple-popover-primary-color-picker"
        : undefined;

    const [secondaryColor, setSecondaryColor] = useState(colors.secondaryColor);
    const [toggleSecondaryColorPicker, setToggleSecondaryColorPicker] =
        useState(false);
    const openSecondaryColorPicker = Boolean(toggleSecondaryColorPicker);
    const idSecondaryColorPicker = openSecondaryColorPicker
        ? "simple-popover-Secondary-color-picker"
        : undefined;

    const [primaryButtonTheme, setPrimaryButtonTheme] = useState(
        colors.primaryButtonTheme
    );
    const [togglePrimaryButtonThemePicker, setTogglePrimaryButtonThemePicker] =
        useState(false);
    const openPrimaryButtonThemePicker = Boolean(
        togglePrimaryButtonThemePicker
    );
    const idPrimaryButtonThemePicker = openPrimaryButtonThemePicker
        ? "simple-popover-PrimaryButtonTheme-picker"
        : undefined;

    const [secondaryButtonTheme, setSecondaryButtonTheme] = useState(
        colors.secondaryButtonTheme
    );
    const [
        toggleSecondaryButtonThemePicker,
        setToggleSecondaryButtonThemePicker,
    ] = useState(false);
    const openSecondaryButtonThemePicker = Boolean(
        toggleSecondaryButtonThemePicker
    );
    const idSecondaryButtonThemePicker = openSecondaryButtonThemePicker
        ? "simple-popover-SecondaryButtonTheme-picker"
        : undefined;

    const [primaryButtonTextTheme, setPrimaryButtonTextTheme] = useState(
        colors.primaryButtonTextTheme
    );
    const [
        togglePrimaryButtonTextThemePicker,
        setTogglePrimaryButtonTextThemePicker,
    ] = useState(false);
    const openPrimaryButtonTextThemePicker = Boolean(
        togglePrimaryButtonTextThemePicker
    );
    const idPrimaryButtonTextThemePicker = openPrimaryButtonTextThemePicker
        ? "simple-popover-PrimaryButtonTextTheme-picker"
        : undefined;

    const [secondaryButtonTextTheme, setSecondaryButtonTextTheme] = useState(
        colors.secondaryButtonTextTheme
    );
    const [
        toggleSecondaryButtonTextThemePicker,
        setToggleSecondaryButtonTextThemePicker,
    ] = useState(false);
    const openSecondaryButtonTextThemePicker = Boolean(
        toggleSecondaryButtonTextThemePicker
    );
    const idSecondaryButtonTextThemePicker = openSecondaryButtonTextThemePicker
        ? "simple-popover-SecondaryButtonTextTheme-picker"
        : undefined;

    const [firstLoad, setFirstLoad] = useState(true);
    const updateSettings = useSelector(
        (state) => state.settings.updateSettings
    );

    const onLoad = () => {
        let timer = null;
        // update field from db
        if (settingsRef.current !== null) {
            const current = settingsRef.current;
            current[dbField].primaryColor !== null &&
                setPrimaryColor(current[dbField].primaryColor);
            current[dbField].secondaryColor !== null &&
                setSecondaryColor(current[dbField].secondaryColor);
            current[dbField].primaryButtonTheme !== null &&
                setPrimaryButtonTheme(current[dbField].primaryButtonTheme);
            current[dbField].secondaryButtonTheme !== null &&
                setSecondaryButtonTheme(current[dbField].secondaryButtonTheme);
            current[dbField].primaryButtonTextTheme !== null &&
                setPrimaryButtonTextTheme(
                    current[dbField].primaryButtonTextTheme
                );
            current[dbField].secondaryButtonTextTheme !== null &&
                setSecondaryButtonTextTheme(
                    current[dbField].secondaryButtonTextTheme
                );
        } else {
            timer = setTimeout(onLoad, 1000);
        }
        return timer;
    };
    useEffect(() => {
        if (firstLoad && isModalOpen) {
            const timer = onLoad();
            setFirstLoad(false);
            return () => clearTimeout(timer);
        }
    }, [tabRef.current, isModalOpen]);

    useEffect(() => {
        if (updateSettings) {
            const timer = onLoad();
            return () => clearTimeout(timer);
        }
    }, [updateSettings]);

    return useMemo(
        () => ({
            primaryColor,
            setPrimaryColor,
            togglePrimaryColorPicker,
            setTogglePrimaryColorPicker,
            idPrimaryColorPicker,
            openPrimaryColorPicker,

            secondaryColor,
            setSecondaryColor,
            toggleSecondaryColorPicker,
            setToggleSecondaryColorPicker,
            idSecondaryColorPicker,
            openSecondaryColorPicker,

            primaryButtonTheme,
            setPrimaryButtonTheme,
            togglePrimaryButtonThemePicker,
            setTogglePrimaryButtonThemePicker,
            idPrimaryButtonThemePicker,
            openPrimaryButtonThemePicker,

            secondaryButtonTheme,
            setSecondaryButtonTheme,
            toggleSecondaryButtonThemePicker,
            setToggleSecondaryButtonThemePicker,
            idSecondaryButtonThemePicker,
            openSecondaryButtonThemePicker,

            primaryButtonTextTheme,
            setPrimaryButtonTextTheme,
            togglePrimaryButtonTextThemePicker,
            setTogglePrimaryButtonTextThemePicker,
            idPrimaryButtonTextThemePicker,
            openPrimaryButtonTextThemePicker,

            secondaryButtonTextTheme,
            setSecondaryButtonTextTheme,
            toggleSecondaryButtonTextThemePicker,
            setToggleSecondaryButtonTextThemePicker,
            idSecondaryButtonTextThemePicker,
            openSecondaryButtonTextThemePicker,
        }),
        [
            secondaryButtonTextTheme,
            toggleSecondaryButtonTextThemePicker,
            idSecondaryButtonTextThemePicker,
            openSecondaryButtonTextThemePicker,
            primaryButtonTextTheme,
            togglePrimaryButtonTextThemePicker,
            idPrimaryButtonTextThemePicker,
            openPrimaryButtonTextThemePicker,
            secondaryButtonTheme,
            toggleSecondaryButtonThemePicker,
            idSecondaryButtonThemePicker,
            openSecondaryButtonThemePicker,
            primaryButtonTheme,
            togglePrimaryButtonThemePicker,
            idPrimaryButtonThemePicker,
            openPrimaryButtonThemePicker,
            secondaryColor,
            toggleSecondaryColorPicker,
            idSecondaryColorPicker,
            openSecondaryColorPicker,
            primaryColor,
            togglePrimaryColorPicker,
            idPrimaryColorPicker,
            openPrimaryColorPicker,
        ]
    );
};
