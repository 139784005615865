import { sha256 } from "js-sha256";
import LRU from "../../utils/lru-cache";
import "../../utils/localStorageDB";

const AUDIO = "AUDIO";

const lengthInUtf8Bytes = (str) => {
    // Matches only the 10.. bytes that are non-initial characters in a multi-byte sequence.
    const m = encodeURIComponent(str).match(/%[89ABab]/g);
    return str.length + (m ? m.length : 0);
};

let options = {
        max: 262144000, // 250 MB
        length: (n, key) => lengthInUtf8Bytes(n + key),
        updateAgeOnGet: false,
        maxAge: 0,
    },
    cache = new LRU(options);

export const getAllAudioFromLocalStorage = () => {
    return new Promise(async (resolve, reject) => {
        const audios = await window.ldb.get(AUDIO);
        if (audios !== null) {
            cache.load(audios);
            resolve();
        } else resolve();
    });
};

export const addAudioToLocalStorage = (text, audio) => {
    try {
        cache.set(sha256(text), audio); // update LRU Cache
        updateDB(); // update local storage
    } catch (e) {
        console.log(e);
        console.log(`storage is full or connection to db lost`);
    }
};

export const getAudioFromTextInLocalStorage = async (text) => {
    const hashedText = sha256(text);
    return cache.get(hashedText); // returns undefined if not found
};

export const clearLocalStorage = async () => {
    const status = await window.ldb.clear();
    cache.reset();
    return status;
};

const getAllDatabases = () => {
    window.ldb.getDBs().then((dbs) => {
        console.log(dbs);
        return dbs;
    });
};

export const getStorageQuota = () =>
    new Promise((resolve, reject) => {
        let quota_ = {};
        navigator.storage
            .estimate()
            .then((quota) => {
                const remaining = quota.quota - quota.usage;
                quota_ = {
                    quota: formatBytes(quota.quota),
                    quotaRaw: quota.quota,
                    used: formatBytes(quota.usage),
                    usedRaw: quota.usage,
                    remaining: formatBytes(remaining),
                    remainingRaw: remaining,
                };
                resolve(quota_);
            })
            .catch((e) => reject(null));
    });

export const updateDB = async () => {
    await window.ldb.set(AUDIO, cache.dump());
};

const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const mbToBytes = (mb) => mb * 1048576;

export const setLRUMaxAgeAndSize = (age, size) => {
    let isUpdated = false;

    if (cache.maxAge !== age) {
        cache.updateAgeOnGet = age !== 0;
        cache.maxAge = age * 1000;
        cache.updateMaxAge();
        isUpdated = true;
    }
    if (cache.max !== size) {
        cache.max = mbToBytes(size);
        isUpdated = true;
    }

    if (isUpdated) pruneCache();
    return isUpdated;
};

export const pruneCache = () => {
    // prune the cache (prune ---> Manually iterates over the entire cache proactively pruning old entries)
    cache.prune();
};
