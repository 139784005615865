import React from "react";
import userStyles from "../../../styles/UI/modals/ConfirmationModalStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import ReactLoading from "react-loading";
import { Transition } from "../settings/Settings";

const ConfirmationModal = (props) => {
    const classes = userStyles();
    const {
        open,
        handleClose,
        handleSuccess,
        title,
        message,
        abortText,
        successText,
        Component,
        isLoading = false,
        useComponent = false,
        width = "sm",
        fullWidth = false,
    } = props;

    const handleClose_ = () => !isLoading && handleClose();

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted={false}
            onClose={handleClose_}
            maxWidth={width}
            fullWidth={fullWidth}
        >
            <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>

            <DialogContent>{useComponent ? Component : message}</DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose_}
                    color="primary"
                    disabled={isLoading}
                >
                    {abortText}
                </Button>
                <Button
                    onClick={handleSuccess}
                    color="primary"
                    variant={"contained"}
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <ReactLoading
                            type={"balls"}
                            width={"40%"}
                            height={"40%"}
                            className={classes.loading}
                        />
                    ) : (
                        successText
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
ConfirmationModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSuccess: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    abortText: PropTypes.string.isRequired,
    successText: PropTypes.string.isRequired,
    message: PropTypes.string,
    Component: PropTypes.element,
    isLoading: PropTypes.bool,
    useComponent: PropTypes.bool,
    fullWidth: PropTypes.bool,
    width: PropTypes.oneOf(["sm", "lg", "md"]),
};

export default ConfirmationModal;
