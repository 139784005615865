import React, { useCallback, useEffect, useRef, useState } from "react";
import { Autocomplete, Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { translate } from "../../../../../utils/i18n";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    IconButton,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import Popover from "@material-ui/core/Popover";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import { getUsers } from "../../../../../store/actions/userActions";
import { useDispatch } from "react-redux";
import { usePrevious } from "../../../../../hooks/usePrevious";
import Grid from "@material-ui/core/Grid";
import { Spin } from "antd";
import PropTypes from "prop-types";
import UserTable from "./UserTable";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import UsersTrackersExportModal from "./UsersTrackersExportModal";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    option: {
        fontSize: 15,
        height: "100%",
        width: 300,
        "& > span": {
            width: 300,
            fontSize: 18,
        },
    },
    popperHeader: {
        fontSize: "1.5em",
        fontWeight: "bold",
    },
    popperFormGroup: {
        maxWidth: "20em",
    },
    filterPopper: {
        padding: "0.75em",
    },
    divider: {
        margin: "0.75em 0",
    },
    skeleton: {
        overflowY: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
        marginBottom: "1em",
    },
    container: {},
    gridContainer: {
        marginTop: "1em",
    },
    table: {
        marginTop: "2em",
        height: "80vh",
        marginBottom: "1em",
    },
    datePicker: {
        width: "8em",
        margin: "0 1em",
    },
    buttonContainer: {
        width: "30%",
        margin: "1.75em 0 0 1em",
    },
    autoCompleteContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginRight: "4em",
        marginTop: "1em",
        width: "70%",
    },
    button: {
        color: "rgba(0,0,0,0.65)",
    },
    "@global #userRoot .MuiTableCell-root": {
        borderBottom: "none !important",
        padding: "0.9em 1em !important",
    },
    "@global #userRoot .MuiCheckbox-colorSecondary.Mui-checked": {
        color: `rgba(0, 0, 0, 0.54) !important`,
    },
}));

const USER_FILTERS = {
    id: true,
    firstName: true,
    lastName: true,
    displayName: true,
    email: true,
    slackId: true,
    regex: true,
};

const User = ({
    handleSetSelectedIndexForTracker,
    inputText,
    setInputText,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [usersAutoComplete, setUsersAutoComplete] = useState([]);
    const [users, setUsers] = useState([]);
    const [pagination, setPagination] = useState({});
    const [page, setPage] = useState(1);

    const [filters, setFilters] = useState(USER_FILTERS);
    const [toggleFilter, setToggleFilter] = useState(false);

    const previousInputText = usePrevious(inputText, "");
    const inputTextRef = useRef(inputText);
    inputTextRef.current = inputText;
    const previousInputTextRef = useRef(previousInputText);
    previousInputTextRef.current = previousInputText;

    const [focused, setFocused] = useState(false);
    const searchTimerRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [isAutoCompleteLoading, setIsAutoCompleteLoading] = useState(false);
    const [usersTrackersExportModalOpen, setUsersTrackersExportModalOpen] =
        useState(false);
    const filterOpen = Boolean(toggleFilter);
    const idFilter = filterOpen ? "simple-popover" : undefined;

    const handleFilterClose = () => setToggleFilter(null);

    const handleFilterChange = (e) =>
        setFilters({ ...filters, [e.target.name]: e.target.checked });

    const buildQuery = useCallback(
        (page, inputTextRef) => {
            const keysToRemove = ["regex"];
            const query = { or: [] };
            const keys = Object.keys(filters).filter(
                (key) => !keysToRemove.includes(key)
            );
            keys.forEach((key) => {
                if (filters[key]) {
                    if (filters.regex)
                        query.or.push({
                            [key]: {
                                ["regex"]: inputTextRef.current.toLowerCase(),
                                ["options"]: "i",
                            },
                        });
                    else
                        query.or.push({
                            [key]: inputTextRef.current.toLowerCase(),
                        });
                }
            });
            return `or=${JSON.stringify(query["or"])}&page=${page}&limit=11`;
        },
        [filters]
    );

    const searchUser = useCallback(
        async (page, autoComplete = true, _inputTextRef = inputTextRef) => {
            if (
                _inputTextRef.current.length === 0 &&
                _inputTextRef.current.length !==
                    previousInputTextRef.current.length
            ) {
                await getAllUsers(page);
                return;
            }
            if (
                autoComplete &&
                (!_inputTextRef.current.length > 0 ||
                    _inputTextRef.current.length ===
                        previousInputTextRef.current.length)
            ) {
                return;
            }
            autoComplete ? setIsAutoCompleteLoading(true) : setIsLoading(true);
            const queryString = buildQuery(page, _inputTextRef);
            const data = await dispatch(getUsers(queryString));
            if (data.success) {
                if (autoComplete) {
                    setUsersAutoComplete(data.data);
                } else {
                    setPagination(data.pagination);
                    setUsers(data.data);
                    setPage(data.pagination.page);
                }
            }
            autoComplete
                ? setIsAutoCompleteLoading(false)
                : setIsLoading(false);
        },
        [inputTextRef, previousInputTextRef, buildQuery]
    );

    const getAllUsers = async (page) => {
        setIsLoading(true);
        const data = await dispatch(getUsers(`page=${page}&limit=9`));
        if (data.success) {
            setPagination(data.pagination);
            setUsers(data.data);
            setPage(data.pagination.page);
        }
        setIsLoading(false);
    };

    const handlePageChange = async (event, value) => {
        setPage(value);
        if (inputText.length === 0) await getAllUsers(value);
        else await searchUser(value, false);
    };

    const handleUsersTrackersExportModalClose = useCallback(
        () => setUsersTrackersExportModalOpen(false),
        []
    );

    useEffect(() => {
        if (focused)
            searchTimerRef.current = setInterval(
                () => searchUser(page, true),
                1250
            );
        else clearInterval(searchTimerRef.current);
        return () => clearInterval(searchTimerRef.current);
    }, [focused]);

    useEffect(() => {
        if (inputText.length === 0) getAllUsers(page);
    }, [inputText]);

    useEffect(() => {
        if (inputText.length !== 0) searchUser(page, false);
    }, []);

    return (
        <div className={classes.root} id={"userRoot"}>
            <div style={{ display: "flex" }}>
                <div className={classes.buttonContainer}>
                    <Button
                        className={classes.button}
                        onClick={() => setUsersTrackersExportModalOpen(true)}
                        size="large"
                        variant={"outlined"}
                    >
                        <FileCopyIcon />
                        <div style={{ marginLeft: "0.5em" }}>
                            {translate("Export all to excel")}
                        </div>
                    </Button>
                </div>
                <section className={classes.autoCompleteContainer}>
                    <Autocomplete
                        freeSolo
                        style={{ width: "25em", marginRight: "1em" }}
                        options={usersAutoComplete}
                        loading={isAutoCompleteLoading}
                        classes={{
                            option: classes.option,
                        }}
                        getOptionLabel={(option) =>
                            option.displayName || option
                        }
                        autoHighlight
                        onChange={(e, value, reason) => {
                            if (reason === "select-option") {
                                setInputText(value.displayName);
                                searchUser(page, false, {
                                    current: value.displayName,
                                });
                            } else if (reason === "clear") setInputText("");
                        }}
                        renderOption={(option, state) => (
                            <p
                                style={{
                                    padding: "0.1em",
                                    margin: "0",
                                    width: "300",
                                    height: "100% !important",
                                    color: "#000",
                                    overflowX: "hidden",
                                }}
                            >
                                {option.displayName}
                            </p>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                required
                                value={inputText}
                                onChange={(e) => setInputText(e.target.value)}
                                style={{ margin: 8, paddingRight: "1em" }}
                                placeholder={translate("Search for user")}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="standard"
                                onFocus={(e) => setFocused(true)}
                                onBlur={(e) => setFocused(false)}
                                onKeyPress={(e) => {
                                    e.charCode === 13 &&
                                        searchUser(page, false);
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    style: { fontSize: "1.3rem" },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="search"
                                                onClick={() => {
                                                    !isAutoCompleteLoading &&
                                                        searchUser(page, false);
                                                }}
                                                onMouseDown={(e) =>
                                                    e.preventDefault()
                                                }
                                                edge="end"
                                            >
                                                {isAutoCompleteLoading ? (
                                                    <CircularProgress
                                                        color="inherit"
                                                        size={20}
                                                    />
                                                ) : (
                                                    <SearchIcon />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    />
                    <div style={{ marginTop: "0.25em" }}>
                        <Tooltip
                            title={translate("Search by")}
                            arrow={true}
                            placement={"bottom"}
                        >
                            <IconButton
                                aria-describedby={idFilter}
                                onClick={(e) =>
                                    setToggleFilter(e.currentTarget)
                                }
                                onMouseDown={(e) => e.preventDefault()}
                                edge="end"
                            >
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                        <Popover
                            id={idFilter}
                            open={filterOpen}
                            anchorEl={toggleFilter}
                            onClose={handleFilterClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                        >
                            <div className={classes.filterPopper}>
                                <div className={classes.popperHeader}>
                                    {translate("Search By")}
                                </div>
                                <Divider
                                    variant="middle"
                                    className={classes.divider}
                                />
                                <FormGroup
                                    row
                                    className={classes.popperFormGroup}
                                >
                                    {Object.keys(filters).map(
                                        (filter, index) => (
                                            <FormControlLabel
                                                label={filter}
                                                key={index}
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            filters[filter]
                                                        }
                                                        onChange={
                                                            handleFilterChange
                                                        }
                                                        name={filter}
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        )
                                    )}
                                </FormGroup>
                            </div>
                        </Popover>
                    </div>
                </section>
            </div>

            {isLoading && (
                <div className={classes.skeleton}>
                    <Spin />
                </div>
            )}
            {!isLoading && users.length > 0 && (
                <UserTable
                    classes={classes}
                    users={users}
                    onUserUpdate={getAllUsers}
                    handleSetSelectedIndexForTracker={
                        handleSetSelectedIndexForTracker
                    }
                />
            )}
            {!isLoading && users.length === 0 && (
                <div className={classes.skeleton}>
                    {translate("No users found")}
                </div>
            )}

            <Grid container justify="center">
                <Pagination
                    count={pagination.totalPages}
                    page={page}
                    onChange={handlePageChange}
                    disabled={isLoading}
                />
            </Grid>
            <UsersTrackersExportModal
                handleClose={handleUsersTrackersExportModalClose}
                open={usersTrackersExportModalOpen}
            />
        </div>
    );
};

User.propTypes = {
    handleSetSelectedIndexForTracker: PropTypes.func.isRequired,
    inputText: PropTypes.string.isRequired,
    setInputText: PropTypes.func.isRequired,
};

export default User;
