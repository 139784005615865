import { makeStyles } from "@material-ui/core/styles";
import background from "../../images/background.jpg";

const useStyles = makeStyles(({ primaryColor, secondaryColor }) => ({
    root: {
        // border: '2px solid black',
        backgroundImage: `url(${background})`,
        width: "100%",
        height: "100%",
    },
    subContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
    },
    subContainer2: {
        display: "flex",
    },
    helpSelection: {
        width: "70%",
        marginTop: "2em",
    },
    itemStyle: {
        height: "7em",
    },
    registrationRoot: {
        width: "60%",
        paddingTop: "2.5em",
    },
    title: {
        color: primaryColor,
        fontSize: "1.6em",
    },
    startButton: {
        width: "100%",
        alignItems: "center",
    },
    faceCam: {
        float: "none",
        display: "flex",
        // marginLeft: "5%"
    },
    registrationImage: {
        width: "5em",
        marginRight: "1em",
        marginTop: "1em",
    },
    grid: {
        height: "65%",
    },
    margin_sm: {
        marginLeft: "0.5em",
    },
}));

export default useStyles;
