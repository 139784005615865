import { makeStyles } from "@material-ui/core/styles";
import { hexToRgb } from "../../material-dashboard-react";
import chroma from "chroma-js";

const useStyles = makeStyles(({ primaryColor, secondaryColor }) => ({
    showList: {
        display: "inline-block !important",
    },
    list: {
        // display: "none",
        width: "35em",
        borderRadius: "10px",
        // position: "absolute",
        // left: "20em"
    },
    listSubHeader: {
        color: "white !important",
        display: "flex",
        justifyContent: "space-between",
        background: `linear-gradient(60deg, ${primaryColor}, ${chroma(
            primaryColor
        ).darken(0.5)})`,
        // background: "linear-gradient(60deg, " + aimeColor[1] + ", " + aimeColor[2] + ")"
    },
    subTitle: {
        fontWeight: "bold",
        color: `rgba(${hexToRgb(secondaryColor)}, 0.5)`,
        margin: 0,
        marginLeft: "1.5em",
    },
    subTitle2: {
        marginLeft: "0.5em",
    },
    slider: {
        width: "70% !important",
        marginRight: "1em",
    },
    colorPreview: {
        borderRadius: "50%",
        width: "1.5em",
        height: "1.5em",
        marginRight: "1em",
        "&:hover": {
            boxShadow:
                "0 7px 13px -6px rgba(153, 153, 153, 0.2), 0 2px 11.5px 0px rgba(0, 0, 0, 0.12), 0 4px 5px -2.5px rgba(153, 153, 153, 0.2)",
        },
    },
    colorPreview2: {
        marginLeft: "0.5em",
        borderRadius: "50%",
        width: "1em",
        height: "1em",
        "&:hover": {
            boxShadow:
                "0 7px 13px -6px rgba(153, 153, 153, 0.2), 0 2px 11.5px 0px rgba(0, 0, 0, 0.12), 0 4px 5px -2.5px rgba(153, 153, 153, 0.2)",
        },
    },
    colorCode: {
        fontWeight: "bold",
        color: `rgba(${hexToRgb(secondaryColor)}, 0.35)`,
        marginBottom: 0,
    },
    listItem: {
        width: "75%",
        display: "flex",
        justifyContent: "start",
        paddingLeft: 0,
        alignItems: "center",
        "&:hover": {
            cursor: "pointer",
        },
    },
    fontSelector: {
        width: "75% !important",
    },
    currentText: {
        width: "75% !important",
    },
    text: {
        marginBottom: 0,
        paddingBottom: "0.5em",
        marginLeft: "2em",
        textTransform: "none",
        "&:hover": {
            cursor: "pointer",
        },
        textAlign: "start",
    },
    buttonContainer: {
        width: "80%",
        display: "flex",
        alignItems: "center",
    },
    divider: {
        marginTop: "0.75em",
    },
    picker: {
        width: "25em !important",
    },
    icon: {
        width: "1.5em",
    },
    divider2: {
        marginTop: "0.75em",
        marginBottom: "0.75em",
    },
    tabPanel: {
        height: "20em",
        overflowY: "auto",
    },
    listItem2: {
        marginBottom: "1.25em",
    },
}));

export default useStyles;
