import React, { useMemo, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { translate } from "../../../../../utils/i18n";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { Checkbox, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import UserUpdateModal from "./UserUpdateModal";
import { deleteUser } from "../../../../../store/actions/userActions";
import ConfirmationModal from "../../../modals/ConfirmationModal";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TableRow from "@material-ui/core/TableRow";
import { StyledTableCell } from "./UserTable";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { hexToRgb } from "../../../../../styles/material-dashboard-react";
import { useApplicationSettings } from "../../../../../hooks/settings/useApplicationSettings";

const useStyles = makeStyles((theme) => ({
    card: {
        height: "4em",
        textAlign: "center",
        margin: "0.5em 0",
        padding: "0 1em",
    },
    marginLeft: {
        marginLeft: "1em",
    },
    nameContainer: {
        display: "flex",
    },
    nameCell: {
        "&:hover": {
            cursor: "pointer",
            background: "rgba(0, 0, 0, 0.08)",
        },
    },

    icon: {
        margin: "0 0.2em",
    },
    combinedCells: {
        display: "flex",
        justifyContent: "flex-end",
    },
    cell: {
        width: "7em",
    },
    staffIcon: {
        color: "rgba(0, 0, 0, 0.54)",
    },
}));

export const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: `rgba(0, 0, 0, 0.08) !important`,
        },
        "&:nth-of-type(even)": {
            backgroundColor: `rgba(256, 256, 256, 0.08) !important`,
        },
    },
    "@global .MuiIconButton-colorSecondary:hover": {
        backgroundColor: `rgba(${hexToRgb(
            theme.palette.common.black
        )}, 0.04) !important`,
    },
}))(TableRow);

const UserCard = ({
    onUserUpdate,
    user,
    handleSetSelectedIndexForTracker,
    noButtons,
    className,
    isItemSelected,
    labelId,
    handleClick,
    usingDatePicker,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { messageProvider = "slack" } = useApplicationSettings([
        "messageProvider",
    ]);
    const userMessageProviderField = useMemo(
        () => (messageProvider === "slack" ? "slackId" : "teamsId"),
        [messageProvider]
    );
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
        useState(false);

    const handleUpdateModalClose = () => setUpdateModalOpen(false);

    const handleDelete = async () => {
        setIsLoading(true);
        await dispatch(deleteUser(user._id, user.faceId));
        setIsLoading(false);
        await onUserUpdate();
    };

    return (
        <StyledTableRow
            className={className || ""}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            selected={isItemSelected}
        >
            {!noButtons && (
                <StyledTableCell padding="checkbox">
                    <Checkbox
                        onClick={handleClick}
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                    />
                </StyledTableCell>
            )}
            <StyledTableCell
                component="th"
                scope="row"
                className={!noButtons ? classes.nameCell : ""}
                onClick={() => !noButtons && setUpdateModalOpen(true)}
            >
                <div className={classes.nameContainer}>
                    {user.displayName}
                    {user.role === "staff" && (
                        <Tooltip
                            title={translate("Staff")}
                            arrow={true}
                            placement={"bottom"}
                            className={classes.staffIcon}
                        >
                            <VerifiedUserIcon className={classes.marginLeft} />
                        </Tooltip>
                    )}
                </div>
            </StyledTableCell>
            {usingDatePicker ? (
                <StyledTableCell align="right">
                    <div className={classes.combinedCells}>
                        <span>{user.email}</span>
                        <span className={classes.cell}>
                            {user[userMessageProviderField]}
                        </span>
                    </div>
                </StyledTableCell>
            ) : (
                <>
                    <StyledTableCell align="right">
                        {user.email}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                        {user.slackId}
                    </StyledTableCell>
                </>
            )}

            <StyledTableCell align="right">
                {user.dob ? moment(user.dob).format("DD/MM/YYYY") : ""}
            </StyledTableCell>
            <StyledTableCell align="right">
                <div>
                    {!noButtons && (
                        <>
                            <Tooltip
                                title={translate("Trackers")}
                                arrow={true}
                                placement={"bottom"}
                            >
                                <IconButton
                                    aria-describedby={"Trackers"}
                                    onClick={() =>
                                        handleSetSelectedIndexForTracker(
                                            2,
                                            user
                                        )
                                    }
                                    onMouseDown={(e) => e.preventDefault()}
                                    edge="end"
                                    className={classes.icon}
                                >
                                    <VisibilityIcon />
                                </IconButton>
                            </Tooltip>

                            <Tooltip
                                title={translate("Edit")}
                                arrow={true}
                                placement={"bottom"}
                            >
                                <IconButton
                                    aria-describedby={"Edit"}
                                    onClick={() => setUpdateModalOpen(true)}
                                    onMouseDown={(e) => e.preventDefault()}
                                    edge="end"
                                    className={classes.icon}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                title={translate("Delete")}
                                arrow={true}
                                placement={"bottom"}
                            >
                                <IconButton
                                    aria-describedby={"Delete"}
                                    onClick={() =>
                                        setIsDeleteConfirmationDialogOpen(true)
                                    }
                                    onMouseDown={(e) => e.preventDefault()}
                                    edge="end"
                                    className={classes.icon}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                </div>
            </StyledTableCell>
            <ConfirmationModal
                open={isDeleteConfirmationDialogOpen}
                handleClose={() => setIsDeleteConfirmationDialogOpen(false)}
                handleSuccess={handleDelete}
                title={translate("Delete User")}
                message={translate(
                    "Are you sure you want to delete this user?"
                )}
                abortText={translate("No")}
                successText={translate("Yes")}
                isLoading={isLoading}
            />
            <UserUpdateModal
                user={user}
                onUserUpdate={onUserUpdate}
                handleClose={handleUpdateModalClose}
                open={updateModalOpen}
            />
        </StyledTableRow>
    );
};

UserCard.propTypes = {
    onUserUpdate: PropTypes.func,
    user: PropTypes.object.isRequired,
    handleSetSelectedItemForTracker: PropTypes.func,
    noButtons: PropTypes.bool,
    className: PropTypes.string,
    noBackgroundColor: PropTypes.bool,
    isItemSelected: PropTypes.bool,
    labelId: PropTypes.string,
    handleClick: PropTypes.func,
    usingDatePicker: PropTypes.bool,
};

UserCard.defaultProps = {
    onUserUpdate: () => {},
    handleSetSelectedItemForTracker: () => {},
    noButtons: false,
    noBackgroundColor: false,
    isItemSelected: false,
    handleClick: () => {},
    labelId: "",
    usingDatePicker: false,
};
export default UserCard;
