import {
    SET_IS_USER_FALSE_COUNT,
    SET_IS_USER,
    SET_DETECTION,
    SET_DETECTIONS,
    SET_DETECTION_INTERVAL,
    SET_DETECTION_IMG,
    ADD_TO_REGISTRATION_IMAGES,
    CLEAR_REGISTRATION_IMAGES,
    SET_IS_REGISTERING,
    SET_HAS_BOWED,
    SET_CAMERA_INFO,
} from "./types";
import {
    clearUserFaceId,
    decreaseRecognizedCount,
    increaseRecognizedCount,
    recognizeUser,
} from "./userActions";
import { env } from "../../config/env";

// set receptionist state
export const setIsUser = (isUser) => (dispatch) => {
    dispatch({
        type: SET_IS_USER,
        is_user: isUser,
    });
};

// set false count
export const setIsUserFalseCount = (count, increase) => (dispatch) => {
    if (increase) {
        // TODO: confirm with Praise that this is ok
        if (count < env.REACT_APP_FALSE_COUNT) {
            dispatch({
                type: SET_IS_USER_FALSE_COUNT,
                count: count,
                increase,
            });
        }
    } else {
        dispatch({
            type: SET_IS_USER_FALSE_COUNT,
            count: count,
            increase,
        });
    }
};

export const detectAllFaces = async (img) => {
    let detections = [];

    if (img) {
        // axios cause memory leak if we use it here
        await fetch('/api/v1/faceapi', {
            method: 'POST',
            body: JSON.stringify({ image: img }),
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then((res) => {
                if (res.success) detections = res.detections;
            })
            .catch((err) => {
                console.log(err);
            });
    }
    return detections;
};

export const detectFace =
    (
        webcamRef,
        faceRecognitionURL,
        canUseRecognitionAPI,
        minDetectionArea,
        save = false
    ) =>
        async (dispatch) => {
            try {
                const img = webcamRef.current.getScreenshot();
                let detections = await detectAllFaces(img);
                detections = detections.filter(
                    (detection) =>
                        calculateArea(
                            detection.detection._box._width,
                            detection.detection._box._width
                        ) >= minDetectionArea
                );

                if (detections.length > 0) {
                    const largestDetectionArea = detections.reduce(
                        (prev, current) =>
                            calculateArea(
                                prev.detection._box._width,
                                prev.detection._box._height
                            ) >
                                calculateArea(
                                    current.detection._box._width,
                                    current.detection._box._height
                                )
                                ? prev
                                : current
                    );
                    // console.log(largestDetectionArea);
                    const croppedImg = await resizeCrop(
                        img,
                        largestDetectionArea.detection._box._x,
                        largestDetectionArea.detection._box._y - 40,
                        largestDetectionArea.detection._box._width + 30,
                        largestDetectionArea.detection._box._height + 60
                    );
                    dispatch(setDetections(detections));
                    dispatch(setDetection(largestDetectionArea));
                    dispatch(setDetectionImg(croppedImg));
                    dispatch(setIsUser(true));
                    dispatch(setIsUserFalseCount(0, false));
                    dispatch(increaseRecognizedCount());

                    if (save) dispatch(addToRegistrationImages(croppedImg));
                    else if (canUseRecognitionAPI) {
                        deboundRecog(async () =>
                            dispatch(
                                recognizeUser(
                                    croppedImg.replace(
                                        "data:image/jpeg;base64,",
                                        ""
                                    ),
                                    faceRecognitionURL
                                )
                            )
                        );
                    }
                } else {
                    dispatch(setDetections([]));
                    dispatch(setDetection(null));
                    dispatch(setDetectionImg(null));
                    dispatch(setIsUser(false));
                    dispatch(setIsUserFalseCount(1, true));
                    dispatch(decreaseRecognizedCount());
                    dispatch(clearUserFaceId());
                }
            } catch (e) {
                // TODO: Duc - show error?
            }
        };

// run recognition (override interval)
export const runRecognition =
    (croppedImg, canUseFaceRecognition, faceRecognitionURL) =>
        async (dispatch) => {
            if (
                croppedImg !== null &&
                faceRecognitionURL !== null &&
                canUseFaceRecognition
            ) {
                await dispatch(
                    recognizeUser(
                        croppedImg.replace("data:image/jpeg;base64,", ""),
                        faceRecognitionURL
                    )
                );
            }
        };

// crop image
export const resizeCrop = (img, x, y, width_, height_) => {
    const image = new Image();
    image.src = img;
    let canvas = document.createElement("canvas"),
        max_size = 1200,
        width = width_,
        height = height_;
    if (width > height) {
        if (width > max_size) {
            height *= max_size / width;
            width = max_size;
        }
    } else {
        if (height > max_size) {
            width *= max_size / height;
            height = max_size;
        }
    }
    canvas.width = width_;
    canvas.height = height_;
    return new Promise((resolve, reject) => {
        image.onload = () => {
            canvas
                .getContext("2d")
                .drawImage(image, x, y, width, height, 0, 0, width, height);
            resolve(canvas.toDataURL("image/jpeg"));
        };
        image.onError = reject;
    });
};

// image to base64
export const toBase64 = (img) => {
    let xhr = new XMLHttpRequest();
    xhr.open("GET", img);
    xhr.responseType = "blob";
    xhr.send();
    return new Promise((resolve, reject) => {
        xhr.onload = function () {
            let reader = new FileReader();
            reader.onloadend = function () {
                resolve(reader.result);
            };
            reader.readAsDataURL(xhr.response);
        };
    });
};

// set detection interval
export const setDetectionInterval = (detectionInterval) => {
    return {
        type: SET_DETECTION_INTERVAL,
        detectionInterval,
    };
};

// set detections
export const setDetections = (detections) => {
    return {
        type: SET_DETECTIONS,
        detections,
    };
};

// set detection
export const setDetection = (detection) => {
    return {
        type: SET_DETECTION,
        detection,
    };
};

// set img
export const setDetectionImg = (detectionImg) => {
    return {
        type: SET_DETECTION_IMG,
        detectionImg,
    };
};
// add registrationImg
export const addToRegistrationImages = (registrationImg) => {
    return {
        type: ADD_TO_REGISTRATION_IMAGES,
        registrationImg,
    };
};

// clear registration images
export const clearRegistrationImages = () => {
    return {
        type: CLEAR_REGISTRATION_IMAGES,
    };
};

// set is registering
export const setIsRegistering = (isRegistering) => {
    return {
        type: SET_IS_REGISTERING,
        isRegistering,
    };
};

// set has bowed(for receptionist to take a bow)
export const setHasBowed = (hasBowed) => {
    return {
        type: SET_HAS_BOWED,
        hasBowed,
    };
};

//  set camera settings info
export const setCameraInfo = (cameraInfo) => (dispatch) => {
    dispatch({
        type: SET_CAMERA_INFO,
        cameraInfo,
    });
};

const calculateArea = (width, height) => {
    return parseFloat(width) * parseFloat(height);
};

const deboundRecogFn = () => {
    let isCalling = false;
    return async (fn) => {
        if (!isCalling) {
            isCalling = true;
            // var t0 = performance.now()
            await fn();

            // var t1 = performance.now()
            // console.log("Recognition took " + (t1 - t0) + " milliseconds.")
            isCalling = false;
        }
    };
};

const deboundRecog = deboundRecogFn();
