import { useEffect, useState } from "react";
import { slack, teams } from "../../../styles/constants";
import { areObjectsSame } from "../../../store/actions/chatActions";
import { useDispatch, useSelector } from "react-redux";
import { unFreezeObjOrArray } from "./useAvatarSettings";

export const useMessageProviderSettings = (
    tabIndex,
    settingsRef,
    tabRef,
    isModalOpen
) => {
    // APPLICATION
    const [selectedMessageProvider, setSelectedMessageProvider] =
        useState("slack");
    const [modalType, setModalType] = useState({ field: "delivery" });
    const [staffSlackName, setStaffSlackName] = useState("");
    const [staffSlackId, setStaffSlackId] = useState("");

    const [slackToken, setSlackToken] = useState(
        process.env.REACT_APP_API_SLACK_TOKEN
    );
    const [slackConfig, setSlackConfig] = useState({
        delivery: {
            staffs: slack.DELIVERY_STAFFS,
            channels: slack.DELIVERY_CHANNELS,
            message: slack.DELIVERY_MESSAGE,
            staffModal: false,
        },
        meeting: {
            staffs: slack.DEFAULT_MENTIONS,
            channels: slack.DEFAULT_CHANNELS,
            message: slack.DEFAULT_MESSAGE,
            staffModal: false,
        },
        interview: {
            staffs: slack.INTERVIEW_STAFFS,
            channels: slack.INTERVIEW_CHANNELS,
            message: slack.INTERVIEW_MESSAGE,
            staffModal: false,
        },
    });

    const [teamsAuth, setTeamsAuth] = useState({
        tenantId: "",
        clientId: "",
        clientSecret: "",
        email: "",
        password: "",
    });

    const [teamsConfig, setTeamsConfig] = useState({
        delivery: {
            staffs: teams.DELIVERY_STAFFS,
            teams: teams.DELIVERY_CHANNELS,
            message: teams.DELIVERY_MESSAGE,
            teamsModal: false,
            staffModal: false,
        },
        meeting: {
            staffs: teams.DEFAULT_MENTIONS,
            teams: teams.DEFAULT_CHANNELS,
            message: teams.DEFAULT_MESSAGE,
            teamsModal: false,
            staffModal: false,
        },
        interview: {
            staffs: teams.INTERVIEW_STAFFS,
            teams: teams.INTERVIEW_CHANNELS,
            message: teams.INTERVIEW_MESSAGE,
            teamsModal: false,
            staffModal: false,
        },
    });
    const [teamId, setTeamId] = useState("");
    const [channelId, setChannelId] = useState("");
    const [teamsStaffName, setTeamsStaffName] = useState("");
    const [teamsStaffId, setTeamsStaffId] = useState("");
    const [firstLoad, setFirstLoad] = useState(true);

    const updateSettings = useSelector(
        (state) => state.settings.updateSettings
    );

    const onLoad = () => {
        let timer = null;
        // update field from db
        if (settingsRef.current !== null) {
            const current = settingsRef.current;
            current.slack?.token != null && setSlackToken(current.slack.token);
            current.slack?.config != null &&
                setSlackConfig(current.slack.config);
            current.teams?.config != null &&
                setTeamsConfig(current.teams.config);
            current.teams?.auth != null && setTeamsAuth(current.teams.auth);
            current.messageProvider != null &&
                setSelectedMessageProvider(current.messageProvider);
        } else {
            timer = setTimeout(onLoad, 1000);
        }
        return timer;
    };

    const setTeamsAuth_ = (field, value) => {
        setTeamsAuth({
            ...teamsAuth,
            [field]: value,
        });
    };

    const setTeamsConfig_ = (field, subfield, value) => {
        setTeamsConfig({
            ...teamsConfig,
            [field]: {
                ...teamsConfig[field],
                [subfield]: value,
            },
        });
    };

    const setSlackConfig_ = (field, subfield, value) => {
        setSlackConfig({
            ...slackConfig,
            [field]: {
                ...slackConfig[field],
                [subfield]: value,
            },
        });
    };

    const getProviderConfig = (type) => {
        // remove unused properties from  obj
        if (type === "slack") {
            const slack_ = unFreezeObjOrArray(slackConfig);
            Object.keys(slack_).map((key) => delete slack_[key].staffModal);
            return slack_;
        } else {
            const teamsConfig_ = unFreezeObjOrArray(teamsConfig);
            Object.keys(teamsConfig_).map(
                (key) => delete teamsConfig_[key].staffModal
            );
            return teamsConfig_;
        }
    };

    const addStaff = () => {
        const { field } = modalType;
        const subField = "staffs";
        const modal_ = "staffModal";
        setSlackConfig({
            ...slackConfig,
            [field]: {
                ...slackConfig[field],
                [subField]: [
                    ...slackConfig[field][subField],
                    { name: staffSlackName, slack_id: staffSlackId },
                ],
                [modal_]: false,
            },
        });
        setStaffSlackName("");
        setStaffSlackId("");
    };

    const addTeamsStaff = () => {
        const { field } = modalType;
        const subField = "staffs";
        const modal_ = "staffModal";
        setTeamsConfig({
            ...teamsConfig,
            [field]: {
                ...teamsConfig[field],
                [subField]: [
                    ...teamsConfig[field][subField],
                    { name: teamsStaffName, teamsStaffId: teamsStaffId },
                ],
                [modal_]: false,
            },
        });
        setTeamsStaffName("");
        setTeamsStaffId("");
    };

    const addTeamsChannel = (subField, modal) => {
        const { field } = modalType;
        setTeamsConfig({
            ...teamsConfig,
            [field]: {
                ...teamsConfig[field],
                [subField]: [
                    ...teamsConfig[field][subField],
                    { channelId: channelId, teamId: teamId },
                ],
                [modal]: false,
            },
        });
        setChannelId("");
        setTeamId("");
    };

    const hasMessageProviderSettingsChanged = () => {
        const current = settingsRef.current;
        return (
            current.selectedMessageProvider !== selectedMessageProvider ||
            current.slack?.token !== slackToken ||
            !areObjectsSame(current.slack?.config, slackConfig) ||
            !areObjectsSame(current.teams?.auth, teamsAuth) ||
            !areObjectsSame(current.teams?.config, teamsConfig)
        );
    };

    useEffect(() => {
        if (firstLoad && isModalOpen) {
            const timer = onLoad();
            setFirstLoad(false);
            return () => clearTimeout(timer);
        }
    }, [tabRef.current, isModalOpen]);

    useEffect(() => {
        if (updateSettings) {
            const timer = onLoad();
            return () => clearTimeout(timer);
        }
    }, [updateSettings]);

    return {
        teamId,
        setTeamId,
        channelId,
        setChannelId,
        teamsStaffName,
        setTeamsStaffName,
        teamsStaffId,
        setTeamsStaffId,
        slackToken,
        setSlackToken,
        teamsConfig,
        teamsAuth,
        slackConfig,
        setSlackConfig_,
        setTeamsAuth_,
        setTeamsConfig_,
        getProviderConfig,
        staffSlackName,
        setStaffSlackName,
        staffSlackId,
        setStaffSlackId,
        modalType,
        setModalType,
        addStaff,
        addTeamsStaff,
        selectedMessageProvider,
        setSelectedMessageProvider,
        addTeamsChannel,
        hasMessageProviderSettingsChanged,
    };
};
