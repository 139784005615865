import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "moment/locale/vi";
import "moment/locale/ja";
import "moment/locale/ko";
import App from "./App";
// import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { setupClientSentry } from "./config/clientSentryConfig";

setupClientSentry();

ReactDOM.render(
    <BrowserRouter>
        <React.StrictMode>
            <Provider store={store}>
                <App />
            </Provider>
        </React.StrictMode>
    </BrowserRouter>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
