import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(({ primaryColor }) => ({
    formControl: {
        marginBottom: "16px",
        width: "60%",
    },
    formContainer: {
        display: "flex",
        width: "100%",
        alignItems: "center",
    },
    pluginContainer: {
        height: "34em",
        width: "25em",
        padding: "0 !important",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    frameContainer: {
        height: "100%",
        width: "100%",
    },
}));

export default useStyles;
