import en from "./en.json";
import vi from "./vi.json";
import ja from "./ja.json";
import ko from "./ko.json";

export default {
    en,
    vi,
    ja,
    ko,
};
