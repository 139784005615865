import React from "react";
import { useLocation } from "react-router-dom";
import KbotButtonAction from "../UI/template/KbotButtonAction";

export default () => {
    const location = useLocation();

    return (
        <>
            <KbotButtonAction {...location.state} />
        </>
    );
};
