import { makeStyles } from "@material-ui/core/styles";
import background from "../../../images/background.jpg";
// import {colors} from "../../constants";

const useStyles = makeStyles(({ primaryColor }) => ({
    container: {
        display: "flex",
        width: "100%",
        height: "87%",
        overflowX: "hidden",
    },
    subContainer2: {
        display: "flex",
        width: "100%",
    },
    subContainer3: {
        // marginTop: "5vh",
        width: "100%",
        height: "100%",
        marginLeft: "6em",
        marginRight: "6em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 10,
    },
    subContainer4: {
        width: "35%",
    },
}));

export default useStyles;
