import {
    GET_NOTIFICATIONS,
    CLEAR_NOTIFICATIONS,
    ADD_NOTIFICATION,
} from "../actions/types";

const initialState = [];

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_NOTIFICATIONS:
            return action.payload;
        case ADD_NOTIFICATION:
            return [...state, action.payload];
        case CLEAR_NOTIFICATIONS:
            return [];
        default:
            return state;
    }
}
