import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(({ secondaryColor }) => ({
    logo: {
        height: "2.5em",
        width: "9em",
    },
    logoContainer: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        padding: "0.75em 0",
    },
    removeDefaultLink: {
        textDecoration: "none !important",
        color: "inherit !important",
    },
    items: {
        color: `${secondaryColor} !important`,
    },
    itemText: {
        marginTop: 0,
        marginBottom: 0,
        paddingTop: 0,
        paddingBottom: 0,
        color: `${secondaryColor} !important`,
    },
    gridItem: {
        backgroundColor: "rgba(255, 255, 255, 0.4)",
    },
    "@global .MuiTooltip-tooltip": {
        fontSize: "0.75rem !important",
    },
}));

export default useStyles;
