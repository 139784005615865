import { SET_LANGUAGE } from "../actions/types";
import { setLanguage } from "../../utils/i18n";
import moment from "moment-timezone";

const initialState = {
    currentLanguage: "en-US",
};

moment.locale(initialState.currentLanguage);

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_LANGUAGE:
            setLanguage(action.language.split("-").shift());
            return {
                ...state,
                currentLanguage: action.language,
            };
        default:
            return state;
    }
}
