import { useEffect, useRef, useState } from "react";
import SpeechRecognition, {
    useSpeechRecognition,
} from "react-speech-recognition";
import ding_ from "../images/ding.wav";
import dong_ from "../images/dong.wav";
import {
    sendMessage,
    setIsPerformingAction,
    stopAllActions,
} from "../store/actions/chatActions";
import { useDispatch, useSelector } from "react-redux";
import { setReceptionist } from "../store/actions/receptionistActions";
import { useHistory } from "react-router-dom";
import { ttsPostProcessWords } from "../styles/constants";
import { unFreezeObjOrArray } from "./settings/settingsModal/useAvatarSettings";

/**
 * This hook initializes a speech recognition manager
 *
 * HOOK INITIALIZATION:
 * @param {boolean} [sendMessage_=false] - if true, sends message after listening
 * @param {function} [{showMeetStaffModal: undefined, showFindMeetingModal: undefined}] - required to handle message response (response might need to toggle modal)
 * @param {function} [onEnd=null] - pass in function to run onEnd
 * @param {function} [onStart=null] - pass in function to run onStart
 * @returns {array} [transcript, isRecording, handleToggle, resetTranscript]
 *
 *
 * Usage:
 *     const [transcript, isRecording, handleToggle, resetTranscript] = useSpeechRecognitionManager()
 *
 *  OR
 *
 *     const [transcript, isRecording, handleToggle, resetTranscript] = useSpeechRecognitionManager(true, showMeetStaffModal)
 *
 * */

const recognition = SpeechRecognition.getRecognition();
const ding = new Audio(ding_);
const dong = new Audio(dong_);

let recognitionFnState = { onStart: null, onEnd: null };

recognition.onend = () => {
    if (recognitionFnState.onEnd) recognitionFnState.onEnd();
};
recognition.onstart = () => {
    if (recognitionFnState.onStart) recognitionFnState.onStart();
};

export const useSpeechRecognitionManager = (
    modals,
    sendMessage_ = false,
    getOnlyName = false,
    onEnd = null,
    onStart = null
) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { transcript, resetTranscript } = useSpeechRecognition({
        clearTranscriptOnListen: true,
    });
    const transcriptRef = useRef(transcript);
    transcriptRef.current = transcript;
    const [transcriptProcessed, setTranscriptProcessed] = useState(null);
    const sessionId = useSelector((state) => state.chat.session);
    const isGapiReady = useSelector((state) => state.chat.isGapiReady);
    const [isRecording, setIsRecording] = useState(false);
    const language = useSelector((state) => state.language.currentLanguage);

    const reBuildFunctions = () => {
        const onStart_ = () => {
            dong.play();
            setTranscriptProcessed(null);
            setIsRecording((val) => !val);
            onStart !== null && onStart();
            dispatch(setIsPerformingAction(true));
        };
        const onEnd_ = () => {
            // SpeechRecognition.stopListening()
            setIsRecording((val) => !val);
            onEnd !== null && onEnd();
            const newTranscript = postProcess();
            setTranscriptProcessed(newTranscript);
            sendMessage_ &&
                dispatch(
                    sendMessage(
                        newTranscript,
                        sessionId,
                        modals,
                        history,
                        language,
                        isGapiReady
                    )
                );
            dispatch(setIsPerformingAction(false));
            resetTranscript();
            ding.play();
        };
        recognitionFnState = { onStart: onStart_, onEnd: onEnd_ };
    };
    const handleToggle = async () => {
        reBuildFunctions();
        dispatch(stopAllActions());
        dispatch(setReceptionist("IDLE"));
        await SpeechRecognition.startListening({ language });
    };

    const postProcess = () => {
        let newTranscript = transcriptRef.current;
        ttsPostProcessWords.forEach((wordOption) => {
            const { word, isRegex } = wordOption;
            const wordsToReplace = wordOption[language];
            if (wordsToReplace !== undefined) {
                if (Array.isArray(wordsToReplace)) {
                    wordsToReplace.forEach((word) => {
                        newTranscript = newTranscript.replace(
                            new RegExp(word, "i"),
                            wordOption.word
                        );
                    });
                }
                if (isRegex && getOnlyName) {
                    const { groupToReplace, replaceGroupWith } = wordsToReplace;
                    if (Array.isArray(word)) {
                        word.forEach((word) => {
                            try {
                                const groups = word.exec(newTranscript);
                                const _groupToReplace = groups[groupToReplace];
                                const _replaceGroupWith =
                                    groups[replaceGroupWith];
                                if (_replaceGroupWith)
                                    newTranscript = newTranscript.replace(
                                        _groupToReplace,
                                        _replaceGroupWith
                                    );
                            } catch (_) {}
                        });
                    } else {
                        try {
                            const groups = word.exec(newTranscript);
                            const _groupToReplace = groups[groupToReplace];
                            const _replaceGroupWith = groups[replaceGroupWith];
                            if (_replaceGroupWith)
                                newTranscript = newTranscript.replace(
                                    _groupToReplace,
                                    _replaceGroupWith
                                );
                        } catch (_) {}
                    }
                }
            }
        });
        return unFreezeObjOrArray(newTranscript);
    };

    useEffect(() => {
        reBuildFunctions();
    }, []);

    return [
        transcriptProcessed || transcriptRef.current,
        isRecording,
        handleToggle,
        resetTranscript,
    ];
};
