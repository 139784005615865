import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { translate } from "../../../../../utils/i18n";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import {
    Collapse,
    FormControl,
    IconButton,
    InputAdornment,
    MenuItem,
    Tooltip,
    Typography,
} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { DatePicker } from "@material-ui/pickers";
import { updateUser } from "../../../../../store/actions/userActions";
import { addToNotifications } from "../../../../../store/actions/notificationActions";
import classnames from "classnames";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import ConfirmationModal from "../../../modals/ConfirmationModal";
import EventIcon from "@material-ui/icons/Event";
import { Transition } from "../../../settings/Settings";
import { useApplicationSettings } from "../../../../../hooks/settings/useApplicationSettings";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: "1em 0",
    },
    singleFormItem: {
        width: "48.5%",
    },
    formItemGrid: {
        width: "48.5%",
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
    gridItem: {
        margin: "0.5em",
    },
}));

const UserUpdateModal = ({ open, handleClose, onUserUpdate, user }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { messageProvider = "slack" } = useApplicationSettings([
        "messageProvider",
    ]);
    const userMessageProviderField = useMemo(
        () => (messageProvider === "slack" ? "slackId" : "teamsId"),
        [messageProvider]
    );
    const [isLoading, setIsLoading] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [isUpdateConfirmationDialogOpen, setIsUpdateConfirmationDialogOpen] =
        useState(false);

    const [form, setForm] = useState(user);
    const setFormValue = (field) => (event) => {
        setForm({
            ...form,
            [field]: event.target.value,
        });
    };
    const setFormValueDate = (field) => (date) => {
        setForm({
            ...form,
            [field]: date,
        });
    };

    const handleUpdate = async () => {
        if (validate()) {
            setIsLoading(true);
            const success = await dispatch(updateUser(user._id, form));
            if (success) {
                dispatch(
                    addToNotifications({
                        type: "SUCCESS",
                        message: translate("User updated successfully!"),
                        size: "md",
                    })
                );
                handleClose();
                setIsLoading(false);
                await onUserUpdate();
            } else {
                setIsLoading(false);
                setIsUpdateConfirmationDialogOpen(false);
            }
        } else setIsUpdateConfirmationDialogOpen(false);
    };

    const validate = useCallback(() => {
        const emailError =
            form.email?.length > 0 &&
            !form.email.match("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$");
        const userMessageProviderFieldError =
            form.role === "staff" &&
            form[userMessageProviderField]?.length === 0;
        const errors =
            form.firstName.length === 0 ||
            form.lastName.length === 0 ||
            form.displayName.length === 0 ||
            emailError ||
            userMessageProviderFieldError;
        if (errors) {
            dispatch(
                addToNotifications({
                    type: "ERROR",
                    message: translate("Please fill the required fields!"),
                    size: "md",
                })
            );
            return false;
        } else return true;
    }, [form]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted={false}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={"md"}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">
                {translate("User Information")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {translate("Update user Info")}
                </DialogContentText>
                <FormGroup className={classes.formContainer}>
                    <FormControl className={classes.formControl}>
                        <Grid container justify="space-between">
                            <TextField
                                label={translate("First Name")}
                                placeholder={translate(
                                    "Enter user's first name"
                                )}
                                required
                                value={form.firstName}
                                onChange={setFormValue("firstName")}
                                variant="outlined"
                                className={classes.formItemGrid}
                            />
                            <TextField
                                label={translate("Last Name")}
                                placeholder={translate(
                                    "Enter user's last name"
                                )}
                                required
                                value={form.lastName}
                                onChange={setFormValue("lastName")}
                                variant="outlined"
                                className={classes.formItemGrid}
                            />
                        </Grid>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <Grid container justify="space-between">
                            <TextField
                                label={translate("Display Name")}
                                placeholder={translate(
                                    "Enter user's display name"
                                )}
                                required
                                value={form.displayName}
                                onChange={setFormValue("displayName")}
                                variant="outlined"
                                className={classes.formItemGrid}
                            />
                            <TextField
                                label={translate("Email")}
                                placeholder={translate("Enter user's email")}
                                value={form.email || ""}
                                onChange={setFormValue("email")}
                                variant="outlined"
                                className={classes.formItemGrid}
                            />
                        </Grid>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <Grid container justify="space-between">
                            <TextField
                                id="outlined-select-role"
                                select
                                required
                                className={classes.formItemGrid}
                                label={translate("Role")}
                                value={form.role || ""}
                                onChange={setFormValue("role")}
                                helperText={translate(
                                    "Please select user's role"
                                )}
                                variant="outlined"
                            >
                                <MenuItem value={"guest"}>
                                    {translate("Guest")}
                                </MenuItem>
                                <MenuItem value={"staff"}>
                                    {translate("Staff")}
                                </MenuItem>
                            </TextField>
                            <DatePicker
                                autoOk
                                disableFuture
                                inputVariant="outlined"
                                label={translate("Date of birth")}
                                format="MM/dd/yyyy"
                                value={form.dob}
                                className={classes.formItemGrid}
                                maxDate={new Date("2005-01-01")}
                                allowKeyboardControl={false}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <EventIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={setFormValueDate("dob")}
                            />
                        </Grid>
                    </FormControl>
                    <FormControl className={classnames(classes.formControl)}>
                        <Grid container justify="space-between">
                            <TextField
                                label={translate("Slack Id")}
                                placeholder={translate("Enter user's slack Id")}
                                required={messageProvider === "slack"}
                                disabled={form.role === "guest"}
                                helperText={translate("User must be staff")}
                                value={form.slackId || ""}
                                onChange={setFormValue("slackId")}
                                variant="outlined"
                                className={classes.formItemGrid}
                            />
                            <TextField
                                label={translate("Teams Id")}
                                placeholder={translate("Enter user's teams Id")}
                                required={messageProvider === "teams"}
                                disabled={form.role === "guest"}
                                helperText={translate("User must be staff")}
                                value={form.teamsId || ""}
                                onChange={setFormValue("teamsId")}
                                variant="outlined"
                                className={classes.formItemGrid}
                            />
                        </Grid>
                    </FormControl>
                </FormGroup>
                <div className={classes.expandContainer}>
                    <Tooltip
                        title={translate(expanded ? "Collapse" : "Expand")}
                        arrow={true}
                        placement={"bottom"}
                    >
                        <IconButton
                            className={classnames(classes.expand, {
                                [classes.expandOpen]: expanded,
                            })}
                            onClick={() => setExpanded((val) => !val)}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon fontSize={"large"} />
                        </IconButton>
                    </Tooltip>
                </div>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <FormControl style={{ width: "70%", marginLeft: "1em" }}>
                        <Grid
                            container
                            justify="space-between"
                            className={classes.gridItem}
                        >
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                            >
                                {translate("UserId")}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                            >
                                {form._id}
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            justify="space-between"
                            className={classes.gridItem}
                        >
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                            >
                                {translate("VoiceId")}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                            >
                                {form.voiceId
                                    ? form.voiceId
                                    : translate("No Id")}
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            justify="space-between"
                            className={classes.gridItem}
                        >
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                            >
                                {translate("FaceId")}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                            >
                                {form.faceId ? form.faceId : translate("No Id")}
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            justify="space-between"
                            className={classes.gridItem}
                        >
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                            >
                                {translate("Voice")}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                            >
                                <Tooltip
                                    title={translate(
                                        form.hasUserRegisteredForVoice
                                            ? "Registered"
                                            : "Unregistered"
                                    )}
                                    arrow={true}
                                    placement={"bottom"}
                                >
                                    {form.hasUserRegisteredForVoice ? (
                                        <CheckCircleIcon />
                                    ) : (
                                        <CancelIcon />
                                    )}
                                </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            justify="space-between"
                            className={classes.gridItem}
                        >
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                            >
                                {translate("Face")}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                            >
                                <Tooltip
                                    title={translate(
                                        form.hasUserRegisteredForFace
                                            ? "Registered"
                                            : "Unregistered"
                                    )}
                                    arrow={true}
                                    placement={"bottom"}
                                >
                                    {form.hasUserRegisteredForFace ? (
                                        <CheckCircleIcon />
                                    ) : (
                                        <CancelIcon />
                                    )}
                                </Tooltip>
                            </Typography>
                        </Grid>
                    </FormControl>
                </Collapse>

                <DialogActions className={classes.button}>
                    <Button
                        onClick={handleClose}
                        disabled={isLoading}
                        color="primary"
                    >
                        {translate("Abort")}
                    </Button>
                    <Button
                        onClick={() => setIsUpdateConfirmationDialogOpen(true)}
                        disabled={isLoading}
                        color="primary"
                        variant={"contained"}
                    >
                        {translate("Update")}
                    </Button>
                </DialogActions>
                <ConfirmationModal
                    open={isUpdateConfirmationDialogOpen}
                    handleClose={() => setIsUpdateConfirmationDialogOpen(false)}
                    handleSuccess={handleUpdate}
                    title={translate("Update User")}
                    message={translate(
                        "Are you sure you want to update this user?"
                    )}
                    abortText={translate("No")}
                    successText={translate("Yes")}
                    isLoading={isLoading}
                />
            </DialogContent>
        </Dialog>
    );
};
UserUpdateModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onUserUpdate: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};

export default UserUpdateModal;
