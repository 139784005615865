import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { LastLocationProvider } from "react-router-last-location";
import { useDispatch, useSelector } from "react-redux";
import AimeReceptionPage from "./components/pages/AimeReceptionPage";
import HomePage from "./components/pages/HomePage";
import Error404Page from "./components/pages/Error404Page";
import "antd/dist/antd.css";
import {
    getCurrentBotId,
    loadCalenderApi,
    loadTextToSpeechApi,
    speakNoChunk,
    updateCurrentBotIdFromURL,
} from "./store/actions/chatActions";
import {
    addToNotifications,
    displayNotifications,
} from "./store/actions/notificationActions";
import FaceRegistrationPage from "./components/pages/FaceRegistrationPage";
import { translate } from "./utils/i18n";
import { runRecognition, setCameraInfo } from "./store/actions/faceCamActions";
import {
    getUserByFaceId,
    setHasPreviousCurrentUsersChanged,
    setSpoofingDetection,
} from "./store/actions/userActions";
import {
    getAllAudioFromLocalStorage,
    getStorageQuota,
    setLRUMaxAgeAndSize,
    updateDB,
} from "./store/actions/localStorageActions";
import {
    getAppearanceSettings,
    getApplicationSettings,
    loadKbotButtonConfigs,
} from "./store/actions/settingsActions";
import RootLayout from "./components/UI/layout/RootLayout";
import { usePrevious } from "./hooks/usePrevious";
import { canDoorOpen, openDoor } from "./store/actions/doorActions";
import { languageCodes, languageCodes_ } from "./styles/constants";
import { setLanguage } from "./store/actions/languageActions";
import { useThemeSettings } from "./hooks/settings/useThemeSettings";
import { ThemeProvider } from "@material-ui/core/styles";
import LoadingPage from "./components/pages/LoadingPage";
import KbotActionPage from "./components/pages/KbotActionPage";
import { checkInUser } from "./store/actions/checkInCheckOutActions";
import { initSlackWebClient } from "./store/actions/slackActions";
import AdminPage from "./components/pages/AdminPage";
import MapPage from "./components/pages/MapPage";
import { selectDetectionImg, selectIsUser, selectUserFalseCount } from "./store/selectors/faceCamSelectors";
import { selectCurrentUsers, selectHasPreviousCurrentUsersChanged, selectIsUserRecognized, selectUserFaceId, selectUserInfo } from "./store/selectors/userSelectors";
import { selectCurrentLanguage } from "./store/selectors/languageSelectors";
import { selectIsGapiReady } from "./store/selectors/chatSelectors";
import { selectUpdateSettings } from "./store/selectors/settingSelectors";
import { env } from "./config/env";


const App = () => {
    const dispatch = useDispatch();
    const history = createBrowserHistory();
    const notifications = useSelector((state) => state.notifications);
    const updateSettings = useSelector(
        selectUpdateSettings
    );
    const isUserFalseCount = useSelector(selectUserFalseCount);
    const isUser = useSelector(selectIsUser);
    const language = useSelector(selectCurrentLanguage);
    const isGapiReady = useSelector(selectIsGapiReady);
    const croppedDetectionImg = useSelector(
        selectDetectionImg
    );
    const userFaceId = useSelector(selectUserFaceId);
    const userFaceIdRef = useRef(userFaceId);
    userFaceIdRef.current = userFaceId;
    const userInfo = useSelector(selectUserInfo);
    const userInfoRef = useRef(userInfo);
    userInfoRef.current = userInfo;
    const previousUserFaceId = usePrevious(userFaceId, null);
    const currentUsers = useSelector(selectCurrentUsers);
    const isUserRecognized = useSelector(selectIsUserRecognized)

    const hasPreviousCurrentUsersChanged = useSelector(
        selectHasPreviousCurrentUsersChanged
    );
    const previous_currentUsers = usePrevious(currentUsers, []);
    const previous_currentUsersRef = useRef(previous_currentUsers);
    previous_currentUsersRef.current = previous_currentUsers;

    const [performingAction, setPerformingAction] = useState(false);
    const performingActionRef = useRef(performingAction);
    performingActionRef.current = performingAction;

    const isThemeOverridden = useSelector(
        (state) => state.settings.isThemeOverridden
    );
    const theme = useThemeSettings([
        "primaryColor",
        "secondaryColor",
        "primaryButtonTheme",
        "secondaryButtonTheme",
        "primaryButtonTextTheme",
        "secondaryButtonTextTheme",
    ]);
    const applicationSettings = useSelector(
        (state) => state.settings.applicationSettings
    );
    const applicationSettingsRef = useRef(applicationSettings);
    applicationSettingsRef.current = applicationSettings;


    useEffect(() => {
        if (notifications.length > 0)
            dispatch(displayNotifications(notifications));
    }, [dispatch, notifications]);

    useEffect(() => {
        const handleGreeting = async () => {
            if (
                history.location.pathname === "/" &&
                applicationSettingsRef.current !== null
            ) {
                const audioEnabled = applicationSettingsRef.current.microphone;
                const videoEnabled = applicationSettingsRef.current.camera.on;
                if (videoEnabled && audioEnabled && isUser) {
                    // console.log(isUserRecognized, userFaceIdRef.current, !previous_currentUsersRef.current.includes(userFaceId));

                    // ignore recognition interval and force recognition
                    if (isUserFalseCount >= env.REACT_APP_FALSE_COUNT)
                        await dispatch(
                            runRecognition(
                                croppedDetectionImg,
                                applicationSettingsRef.current.faceRecognition
                                    .on,
                                applicationSettingsRef.current.faceRecognition
                                    .server
                            )
                        );

                    // override default welcome text
                    let welcomeVoiceStaff = null;
                    const { guest, staff } =
                        applicationSettingsRef.current.welcomeText;
                    const welcomeVoiceGuest =
                        guest?.[language] ?? translate("Hello");
                    if (
                        !isUserRecognized &&
                        isUserFalseCount >= env.REACT_APP_FALSE_COUNT &&
                        !performingActionRef.current
                    ) {
                        // if user is not recognized
                        // console.log(!isUserRecognized,  (isUserFalseCount >= FALSE_COUNT || firstLoadRef.current),  !performingActionRef.current);
                        setPerformingAction(true);
                        await dispatch(
                            speakNoChunk(
                                welcomeVoiceGuest,
                                language,
                                isGapiReady
                            )
                        );
                        setPerformingAction(false);
                    } else {
                        // if the user is recognized
                        // console.log(isUserRecognized, userFaceIdRef.current, hasPreviousCurrentUsersChanged, performingActionRef.current);
                        // console.log(isUserRecognized, userFaceIdRef.current, !previous_currentUsersRef.current.includes(userFaceIdRef.current), !previous_currentUsers.includes(userFaceIdRef.current));
                        // console.log(previous_currentUsersRef.current, previous_currentUsers, userFaceIdRef.current);
                        const userFaceIdRef_ = userFaceIdRef.current;
                        if (
                            isUserRecognized &&
                            userFaceIdRef_ !== null &&
                            hasPreviousCurrentUsersChanged
                        ) {
                            if (!performingActionRef.current) {
                                // no action is going on
                                setPerformingAction(true);
                                dispatch(
                                    setHasPreviousCurrentUsersChanged(false)
                                );
                                const userInfo = await dispatch(
                                    getUserByFaceId(userFaceIdRef_)
                                );
                                if (canDoorOpen() && userInfo?.role === "staff")
                                    dispatch(openDoor(language));
                                if (userInfo) {
                                    welcomeVoiceStaff =
                                        staff?.[language]?.replace(
                                            "<name>",
                                            userInfo.displayName
                                        ) ??
                                        `${translate("Hello")} ${userInfo?.displayName || ""
                                        }`;
                                    dispatch(checkInUser(userInfo._id));
                                }
                                await dispatch(
                                    speakNoChunk(
                                        welcomeVoiceStaff,
                                        language,
                                        isGapiReady
                                    )
                                );
                                setPerformingAction(false);
                            }
                        }
                    }
                }
            }
        };
        handleGreeting();
    }, [
        isUser,
        isUserFalseCount,
        hasPreviousCurrentUsersChanged,
        performingAction,
    ]);

    useEffect(() => {
        // if a new user is detected get user info from db
        const userFaceIdRef_ = userFaceIdRef.current;
        if (userFaceIdRef_ !== null && userFaceIdRef_ !== previousUserFaceId)
            dispatch(getUserByFaceId(userFaceIdRef_));
    }, [userFaceId]);

    const requestPermissions = () => {
        let timer;
        if (applicationSettingsRef.current !== null) {
            const audioEnabled = applicationSettingsRef.current.microphone;
            const videoEnabled = applicationSettingsRef.current.camera.on;
            if (videoEnabled || audioEnabled) {
                window.navigator.mediaDevices
                    .getUserMedia({ audio: audioEnabled, video: videoEnabled })
                    .then(async (stream) => {
                        const message = `${audioEnabled && !videoEnabled
                            ? "Microphone is enabled"
                            : !audioEnabled && videoEnabled
                                ? "Camera is enabled"
                                : audioEnabled &&
                                videoEnabled &&
                                "Microphone and camera are enabled"
                            }`;
                        dispatch(
                            addToNotifications({
                                message: translate(message),
                                type: "SUCCESS",
                                size: "md",
                                duration: 1,
                            })
                        );
                        if (videoEnabled)
                            dispatch(
                                setCameraInfo(
                                    stream.getVideoTracks()[0].getSettings()
                                )
                            );
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(
                            addToNotifications({
                                message: translate(
                                    "You need to enable the microphone and camera for this app to work properly."
                                ),
                                type: "WARNING",
                                size: "md",
                                duration: 4,
                            })
                        );
                    });
            }
        } else timer = setTimeout(requestPermissions, 1000);
        return timer;
    };

    const onLoad = () => {
        let timer;
        if (applicationSettingsRef.current !== null) {
            const { mainLanguage, faceRecognition, localStorage, slack } =
                applicationSettingsRef.current;
            getAllAudioFromLocalStorage().then(async () => {
                await updateDB();
                setLRUMaxAgeAndSize(
                    localStorage.maxAudioAge,
                    localStorage.maxCacheSize
                );
            });
            dispatch(loadCalenderApi());
            dispatch(loadTextToSpeechApi());
            if (faceRecognition.on)
                dispatch(setSpoofingDetection(false, faceRecognition.server));
            mainLanguage && dispatch(setLanguage(languageCodes[mainLanguage]));
            initSlackWebClient(slack?.token);
            getStorageQuota().then((quota) => console.log(quota));
        } else timer = setTimeout(onLoad, 1000);
        return timer;
    };

    useEffect(() => {
        dispatch(updateCurrentBotIdFromURL());
        dispatch(getAppearanceSettings());
        dispatch(getApplicationSettings());
        dispatch(loadKbotButtonConfigs());
        const timer1 = requestPermissions();
        const timer2 = onLoad();
        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
        };
    }, []);

    useEffect(() => {
        if (updateSettings && applicationSettingsRef.current !== null) {
            const { localStorage, slack } = applicationSettingsRef.current;
            const isUpdated = setLRUMaxAgeAndSize(
                localStorage.maxAudioAge,
                localStorage.maxCacheSize
            );
            if (isUpdated) updateDB();
            initSlackWebClient(slack?.token);
        }
    }, [updateSettings]);

    const renderApp = () => {
        return (
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <LastLocationProvider>
                        <Switch>
                            <Route
                                exact
                                path={"/"}
                                render={(routeProps) => (
                                    <RootLayout
                                        Component={HomePage}
                                        history={routeProps.history}
                                        location={routeProps.location}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={"/reception"}
                                render={(routeProps) => (
                                    <RootLayout
                                        Component={AimeReceptionPage}
                                        history={routeProps.history}
                                        location={routeProps.location}
                                        addBaseLayout
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={"/reception/ask-bot/:type"}
                                render={(routeProps) => (
                                    <RootLayout
                                        Component={KbotActionPage}
                                        history={routeProps.history}
                                        location={routeProps.location}
                                        addBaseLayout
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={"/reception/map"}
                                render={(routeProps) => (
                                    <RootLayout
                                        Component={MapPage}
                                        history={routeProps.history}
                                        location={routeProps.location}
                                        addBaseLayout
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={"/face-registration"}
                                render={(routeProps) => (
                                    <RootLayout
                                        Component={FaceRegistrationPage}
                                        history={routeProps.history}
                                        location={routeProps.location}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={"/admin"}
                                render={(routeProps) => (
                                    <RootLayout
                                        Component={AdminPage}
                                        history={routeProps.history}
                                        location={routeProps.location}
                                        noFooter
                                        noHeader
                                        noReceptionist
                                    />
                                )}
                            />
                            <Route
                                render={(routeProps) => (
                                    <Error404Page {...routeProps} />
                                )}
                            />
                        </Switch>
                    </LastLocationProvider>
                </BrowserRouter>
            </ThemeProvider>
        );
    }

    const renderLoading = () => <LoadingPage />;

    return isThemeOverridden && applicationSettings !== null ? renderApp() : renderLoading();
};

export default App;
