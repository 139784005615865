import { ADD_NOTIFICATION } from "./types";
import { speakNoChunk } from "./chatActions";
import { translate } from "../../utils/i18n";
import { WebClient } from "@slack/web-api";

let token = process.env.REACT_APP_API_SLACK_TOKEN;
// const SLACK_TOKEN = process.env.REACT_APP_API_SLACK_TOKEN;

let client = new WebClient();
client = slackClientMonkeyPatch(client);

delete client["axios"].defaults.headers["User-Agent"];

/**
 * Send a slack message
 * @param {(string|string[])} channelId - Name of channel(s). Array or string
 * @param {string|string[]} staffSlackName - display name or name of staffs(s). Array or string
 * @param {string} message - message to send
 * @param {Date} [post_at] - schedule message(unix date required)
 * @returns {boolean} return true if successful and vice-versa
 * */
const sendMessage = async (channelId, staffSlackName, message, post_at) => {
    let mentions = "";
    if (typeof staffSlackName === "string") staffSlackName = [staffSlackName];
    if (typeof channelId === "string") channelId = [channelId];
    staffSlackName = staffSlackName.map((slackName) => slackName.toLowerCase());

    let users = await client.users.list({ token });
    if (users.ok) {
        users = users.members.filter((user) => {
            if (!user.deleted && !user.is_bot)
                return (
                    staffSlackName.includes(
                        user?.profile?.display_name?.toLowerCase()
                    ) || staffSlackName.includes(user?.name?.toLowerCase())
                );
            else return false;
        });
        users.map((user) => (mentions += `<@${user.id}> `));

        let responses = [];
        for (const channelId_ of channelId) {
            let result;
            if (post_at) {
                // schedule message
                try {
                    result = await client.chat.scheduleMessage({
                        channel: channelId_,
                        text: `${mentions} ${message}`,
                        post_at: post_at,
                        token,
                    });
                    responses.push(result);
                } catch (error) {
                    console.log(error);
                    responses.push({ ok: false });
                }
            } else {
                // post message
                try {
                    result = await client.chat.postMessage({
                        channel: channelId_,
                        text: `${mentions} ${message}`,
                        token,
                    });
                } catch (error) {
                    console.log(error);
                    responses.push({ ok: false });
                }
            }
        }
        const success = responses.map((res) => res?.ok);
        return { success: success.every(Boolean), res: responses };
    } else return { success: false, res: null };
};

export const sendSlackMessage =
    ({
        message,
        staffSlackId,
        channel,
        speakOnSuccess,
        language,
        isGapiReady,
    }) =>
    async (dispatch) => {
        const { success } = await sendMessage(channel, staffSlackId, message);
        if (success) {
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "SUCCESS",
                    message: translate(
                        "Our staff got the message, please wait a second"
                    ),
                    size: "md",
                },
            });
            dispatch(speakNoChunk(speakOnSuccess, language, isGapiReady));
        } else
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
    };

export const scheduleSlackMessage =
    ({ message, staffSlackId, channel, postAt }) =>
    async (dispatch) => {
        const { success, res } = await sendMessage(
            channel,
            staffSlackId,
            message,
            postAt
        );
        if (success) {
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "SUCCESS",
                    message: translate(
                        "Slack notifications scheduled successfully"
                    ),
                    size: "md",
                },
            });
        } else {
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
        }
        return { success, res };
    };

/**
 * Send a slack message
 * @param {Object[]} messages -
 * @param {string} messages[].channelId - channelId of channel where message was scheduled.
 * @param {string} messages[].id - scheduledMessageId.
 * @returns {boolean} return true if successful and vice-versa
 * */
export const deleteScheduledSlackMessage = async (messages) => {
    let success = [];
    for (const message of messages) {
        let { channelId, id } = message;
        let result;
        try {
            result = await client.chat.deleteScheduledMessage({
                channel: channelId,
                scheduled_message_id: id,
                token,
            });
        } catch (error) {
            console.log(error);
            result = { ok: false };
        }
        success.push(result);
    }
    success = success.map((res) => res?.ok);
    return success.every(Boolean);
};

export const listScheduledMessages = async (timeMin, timeMax, channelId) => {
    if (typeof channelId === "string") channelId = [channelId];
    let responses = [];
    for (const channelId_ of channelId) {
        const { id } = await getChannelId(channelId_);
        if (id) {
            let result;
            try {
                result = await client.chat.scheduledMessages.list({
                    latest: timeMax,
                    oldest: timeMin,
                    channel: id,
                    token,
                });
            } catch (error) {
                console.log(error);
                result = { ok: false };
            }
            responses.push(result);
        }
    }
    const success = responses.map((res) => res?.ok);
    return success.every(Boolean) ? responses : [];
};

const getChannelId = async (channelName) => {
    let response;
    try {
        response = await client.conversations.list({
            types: "public_channel,private_channel",
            token,
        });
    } catch (error) {
        console.log(error);
        response = { ok: false };
    }
    if (response?.ok) {
        return response.channels.find(
            (channel) => channel.name === channelName
        );
    } else return null;
};

export const initSlackWebClient = (_token) => {
    if (_token && _token !== client.token) {
        token = _token;
        // client = new WebClient();
        // client = new WebClient(tdoken);
        delete client["axios"].defaults.headers["User-Agent"];
    }
};

export function slackClientMonkeyPatch(client) {
    const originalMakeRequest = client.makeRequest;
    const originalToken = client.token;

    function tokenInHeader(headers) {
        const bearerToken = headers?.Authorization;
        if (bearerToken && bearerToken.startsWith("Bearer ")) {
            return bearerToken.substring("Bearer ".length);
        }
    }

    client.makeRequest = function (url, body, headers = {}) {
        const token = body?.token ?? tokenInHeader(headers) ?? originalToken;
        if (token) {
            body = body ?? {};
            body.token = token;
        }
        if (headers) {
            delete headers.Authorization;
        }
        return originalMakeRequest.call(client, url, body, headers);
    };
    return client;
}
