import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { translate } from "../../../../../utils/i18n";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { DateTimePicker } from "@material-ui/pickers";
import { addToNotifications } from "../../../../../store/actions/notificationActions";
import { Transition } from "../../../settings/Settings";
import {
    downloadFileWithDepositionAttachment,
    getTrackersByUsersExcel,
} from "../../../../../store/actions/checkInCheckOutActions";
import reactStringReplace from "react-string-replace";
import moment from "moment-timezone";
import ReactLoading from "react-loading";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: "1em 0",
    },
    singleFormItem: {
        width: "48.5%",
    },
    formItemGrid: {
        width: "48.5%",
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
    gridItem: {
        margin: "0.5em",
    },
    titleText: {
        fontSize: "1.1rem",
        margin: "0.5em 0",
    },
    loading: {
        fill: `${theme.primaryColor} !important`,
    },
}));

const UsersTrackersExportModal = ({ open, handleClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [timeMin, setTimeMin] = useState(moment().startOf("day"));
    const [timeMax, setTimeMax] = useState(moment().endOf("day"));

    const handleExport = async () => {
        setIsLoading(true);
        const _timeMin = new Date(timeMin.toISOString());
        const _timeMax = new Date(timeMax.toISOString());
        const fileName = `trackers.xlsx`;
        const query = `groupBy=day&gte=${_timeMin}&lte=${_timeMax}&pagination=false&fileName=${fileName}&userIds=all`;
        const res = await dispatch(getTrackersByUsersExcel(query));
        if (res) {
            downloadFileWithDepositionAttachment(res);
            dispatch(
                addToNotifications({
                    type: "SUCCESS",
                    message: translate("Downloading File!"),
                    size: "md",
                })
            );
        }
        setIsLoading(false);
        handleClose();
    };

    const renderDateSettings = useCallback(() => {
        const title = translate("Trackers from <start> to <end>");
        const getJSXElement = (type, index) => {
            switch (type) {
                case "<start>":
                    return (
                        <DateTimePicker
                            key={index}
                            autoOk
                            ampm={false}
                            className={classes.datePicker}
                            cancelLabel={translate("Cancel")}
                            todayLabel={translate("Today")}
                            okLabel={translate("Ok")}
                            value={timeMin}
                            disabled={isLoading}
                            onChange={(date, _) => setTimeMin(date)}
                            format="yyyy/MM/dd HH:mm"
                            allowKeyboardControl={false}
                            showTodayButton
                        />
                    );
                case "<end>":
                    return (
                        <DateTimePicker
                            key={index}
                            autoOk
                            ampm={false}
                            className={classes.datePicker}
                            cancelLabel={translate("Cancel")}
                            todayLabel={translate("Today")}
                            okLabel={translate("Ok")}
                            value={timeMax}
                            disabled={isLoading}
                            onChange={(date, _) => setTimeMax(date)}
                            format="yyyy/MM/dd HH:mm"
                            allowKeyboardControl={false}
                            showTodayButton
                        />
                    );
                default:
                    return <></>;
            }
        };

        const _title = reactStringReplace(
            title,
            /(<start>|<end>)/g,
            (match, i) => getJSXElement(match, i)
        );
        return _title.map((element, index) => {
            if (typeof element === "string")
                return (
                    <span className={classes.titleText} key={index}>
                        {element}
                    </span>
                );
            else return element;
        });
    }, [timeMin, timeMax, isLoading]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted={false}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={"sm"}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">
                {translate("Download trackers")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {translate("Download all users trackers")}
                </DialogContentText>
                <FormGroup className={classes.formContainer}>
                    {renderDateSettings()}
                </FormGroup>
                <DialogActions className={classes.button}>
                    <Button
                        onClick={handleClose}
                        disabled={isLoading}
                        color="primary"
                    >
                        {translate("Abort")}
                    </Button>
                    <Button
                        onClick={handleExport}
                        disabled={isLoading}
                        color="primary"
                        variant={"contained"}
                    >
                        {isLoading ? (
                            <ReactLoading
                                type={"balls"}
                                width={"40%"}
                                height={"40%"}
                                className={classes.loading}
                            />
                        ) : (
                            translate("Download")
                        )}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};
UsersTrackersExportModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default UsersTrackersExportModal;
