import Axios from "axios";

export const getContentBot = async (url, setIsLoading) => {
    setIsLoading(true);
    let response = null;
    await Axios.get(url)
        .then((res) => {
            response = res.data.content;
        })
        .catch((err) => {
            console.log(err);
            response = false;
        });
    setIsLoading(false);
    return response;
};
