import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import preloader from "../../images/Preloader_2.gif";
import { translate } from "../../utils/i18n";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
    },
    subRoot: {
        width: "100%",
        height: "100%",
        background: `url(${preloader}) center no-repeat #fff`,
        position: "fixed",
        left: "0px",
        top: "0px",
        zIndex: 999,
    },
    textContainer: {
        position: "fixed",
        left: "0px",
        top: "700px",
        fontSize: "1.25em",
        width: "100%",
        zIndex: 9999,
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.65)",
    },
}));

const LoadingPage = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.textContainer}>
                <p>{translate("Overriding Application Settings ...")}</p>
                <p>{translate("Overriding Appearance Settings ...")}</p>
            </div>
            <div className={classes.subRoot} />
        </div>
    );
};

export default LoadingPage;
